import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  criar: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "none",
    },
    [theme.breakpoints.up("md")]: {
      backgroundColor: "none",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto !important",
      width: "33em !important",
      maxWidth: "40em !important",
    },
  },
  Button: {
    padding: "15px !important",
    fontSize: "12px !important",
    borderRadius: "8px !important",
  },
  TipoConta: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "none",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto !important",
    },
  },
  TipoContaBloco: {
    width: "20em",
  },
  Dados: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "0 !important",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "0 !important",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "90px !important",
    },
  },
  Voltar: {
    width: "18px !important",
    cursor: "pointer",
    marginTop: "15px",
    marginLeft: "15px",
  },
  Container: {
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
    [theme.breakpoints.up("lg")]: {
      height: "100vh",
    },


  },
  Link: {
    color: theme.palette.primary.main,
    marginLeft: 3,
    textDecoration:'underline',
    fontWeight:'lighter !important'
  },
  span: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
  },


}));

export default useStyles;
