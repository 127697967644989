import { configurationVariables } from "../../../environment/index";
import api from "../../index";

const idOrigem = 3; //LojaCambio;
const idHotsite = parseInt(configurationVariables.IdHotsite);


//!------------------------------GETS-----------------------------!

export const getVitrine = async () => {
      try {
            const { data: response, status } = await api.get(`/CarrinhoChip/GetVitrine?idOrigem=${idOrigem}`);

            return {
                  data: response.data,
                  status,
                  errorMessage: response.errorMessage
            }

      } catch (error) {
            throw new Error(error + "RP3CI");
      }

};

export const getCarrinho = async (idCliente = 0) => {
      try {

            const { data: response, status } = await api.get(`/CarrinhoChip/GetCarrinho?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`);

            return {
                  data: response.data,
                  status,
                  errorMessage: response.errorMessage
            }

      } catch (error) {
            throw new Error(error + "RP3CI");
      }

};

export const getDispositivosCompotiveis = async () => {
      try {

            const { data: response, status } = await api.get(`/CarrinhoChip/GetDispositivosCompativeis?idOrigem=${idOrigem}`);

            return {
                  data: response.data,
                  status,
                  errorMessage: response.errorMessage
            }

      } catch (error) {
            throw new Error(error + "R0VFU");
      }

};


export const getPedidoByID = async (idCliente = 0, idPedido = 0) => {
      try {

            const { data: response, status } = await api.get(`/PedidoChip/GetByID?idCliente=${idCliente}&idPedido=${idPedido}&idHotsite=${idHotsite}&idOrigem=${idOrigem}`);

            return {
                  data: response.data,
                  status,
                  errorMessage: response.errorMessage
            }

      } catch (error) {
            throw new Error(error + "RHH98");
      }

};



export const getPedidoByIDCliente = async (idCliente = 0, pagina = 1) => {
      try {

            const { data: response, status } = await api.get(`/PedidoChip/GetPedidosChipByIDCliente?idCliente=${idCliente}&pagina=${pagina}&idHotsite=${idHotsite}&idOrigem=${idOrigem}`);

            if (!!response.errorMessage) throw new Error(response.errorMessage);

            return response?.data;

      } catch (error) {
            throw new Error(error + "RHH98");
      }

}

//!------------------------------Posts-----------------------------!


export const addItemVitrine = async (
      request
) => {
      try {

            const obj = {
                  idCliente: request.idCliente,
                  idOrigem: idOrigem,
                  idHotsite: idHotsite,
                  idChip: request.id,
                  idMoedaTaxa: request.idMoedaTaxa,
                  quantidade: request.quantidade,
            };

            const { data: response, status } = await
                  api.post(`/CarrinhoChip/ConfiguraItemCarrinho?idCliente=${obj.idCliente}&idOrigem=${idOrigem}&idHotsite=${obj.idHotsite}`, obj);

            return {
                  status,
                  errorMessage: response.errorMessage,
                  data: response.data,
            };
      } catch (error) {
            throw new Error(error + "RGOP6");
      }
};

export const addPagamento = async (idCiente, formaPagamento) => {
      try {

            const dados = {
                  idOrigem,
                  idCliente: idCiente,
                  idHotsite: idHotsite,
                  formaPagamento: formaPagamento,
            }


            const { data: response, status } = await api.post(
                  `/CarrinhoChip/AddPagamento?idOrigem=${idOrigem}`,
                  dados
            );

            return {
                  status,
                  errorMessage: response.errorMessage,
                  data: response.data,
            };
      } catch (error) {
            throw new Error(error + "RJWRL");
      }
};

export const deletarItemCarrinho = async (request = null) => {
      try {

            const obj = {
                  idItemCarrinho: request.idItemCarrinho,
                  idCliente: request.idCliente
            };

            const { data: response, status } = await
                  api.post(`/CarrinhoChip/DeletaItemCarrinho?idCliente=${obj.idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}&idItemCarrinho=${obj.idItemCarrinho}`);

            return {
                  data: response.data,
                  status,
                  errorMessage: response.errorMessage
            }

      } catch (error) {
            throw new Error(error + "R3BVJ");
      }

};

export const finalizar = async (idCliente = 0) => {
      try {
            const { data: response, status } = await
                  api.post(`/CarrinhoChip/Finalizar?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`);

            return {
                  status,
                  errorMessage: response.errorMessage,
                  data: response.data,
            };
      } catch (error) {
            throw new Error(error + "RN8GD");
      }
};






