import { useState, useEffect } from "react";
import { getParticipacaoExterna } from "../../../api/Cadastro";
import useStyle from "./styles";
import Controle from '../ControleCamposMultiplos'

export default function ParticipacaoExternaItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
  isLoading,
}) {
  const classes = useStyle();
  const [dadosForm, setDadosForm] = useState({});
  const [defaultDadosForm, setDefaultDadosForm] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getParticipacaoExterna(
      idCliente,
      idPerfilLimiteOperacional
    );

    setDefaultDadosForm(() => ({ ...response[0] }))
    setDadosForm(() => ([...response]));
    setDada(() => ([...response]));
  };


  return (
    <>

      <Controle
        identificaItem="Participação"
        dadosForm={dadosForm}
        setDadosForm={setDadosForm}
        setData={setDada}
        defaultDadosForm={defaultDadosForm}
        title="Participação Externa"
        setIdTipoEstadoCivil={null}
        tooltipAdd="Adicione uma nova participação"
        tooltipDelete="Remove a participação selecionada"
        initBuscaCampos={init}
      />


    </>
  );
}
