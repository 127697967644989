import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import useStyles from "./styles";

interface IStep {
  label: string;
  description: string;
  link: string;
}
interface ISteps {
  orientation: "horizontal" | "vertical";
  steps: Array<IStep>;
  activeStep: number;

  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
}

export default function StepLinear({ steps, orientation, activeStep }: ISteps) {
  const classes = useStyles();

  return (
    <Box sx={{ maxWidth: 400 }} className={classes.Box}>
      <Stepper
        activeStep={activeStep}
        orientation={orientation ? orientation : "vertical"}
      >
        {steps.map((step, index) => (
          <Step key={index}>
            {!step.link ? (
              <>
                <StepLabel optional={index === 6 ? <></> : null}>
                  {step.label}
                </StepLabel>
                <>
                  <Typography>{step.description}</Typography>

                  <Box sx={{ mb: 2 }}></Box>
                </>
              </>
            ) : (
              <Link underline="hover" color="inherit" href={step.link}>
                <StepLabel
                  optional={
                    index === 6 ? (
                      <Typography variant="caption"></Typography>
                    ) : null
                  }
                >
                  {step.label}
                </StepLabel>
                <>
                  <Typography>{step.description}</Typography>

                  <Box sx={{ mb: 2 }}></Box>
                </>
              </Link>
            )}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
