import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ? ======================== Chamadas Mui =============================

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Skeleton, Typography } from "@mui/material";
import List from "@mui/material/List";
// import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
// import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
// import FmdGoodIcon from "@mui/icons-material/FmdGood";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

// ? ======================== Chamadas componentes =============================
import {
  CupomDesconto,
  Subtitulo,
  Titulo,
  Timer,
  BadgeItemCarrinho,
} from "../../../../../components";
import RemessaStyle from "./styles";
import { formatMoneyInStringForm } from "../../../../../util/helpers";
import useAlert from "../../../../../hooks/useAlert";
import { useAuth } from "../../../../../hooks/AuthContext";

import { getItemEnvio } from "../../../../../api/Carrinho/TranferenciaInternacional";

import ItemCambioCarrinho from "../../../../../components/ItemCambioCarrinho";
import CarrinhoPage from "../../../../../components/CarrinhoPage";
import { LoadingItensCarrinho } from "../../../../../components";
import { finalizar } from "../../../../../api/Carrinho/TranferenciaInternacional";
import { LimpaCarrinho } from "../../../../../api/PedidoComercial/index";
import BeneficiarioResumoCarrinho from "../../../../../components/BeneficiarioResumoCarrinho";
import { useMutation } from "react-query";
import useLojaFechada from "../../../../../hooks/useLojaFechada";
import {
  saveLocalStorageTimerCarrinho,
  clearLocalStorageTimerCarrinho,
} from "../../../../../util/LocalStorage";
import typesTipoPedido from "../../../../../util/typesTipoPedido";
import { StepEnvio } from "../../../../../util/StepsComercial";
import useSeoConfig from "../../../../../hooks/useSeoConfig";

export default function ItemEnvio() {
  const classes = RemessaStyle();
  const navigation = useNavigate();
  const { user } = useAuth();
  const [loadingConfirmePedido, setLoadingConfirmePedido] = useState(false);
  const { showAlertError, showAlertSuccess } = useAlert();

  const [itemCarrinhoEnvio, setItemCarrinhoEnvio] = useState({});
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();
  const { sendPageAtual } = useSeoConfig();

  useEffect(() => {
    sendPageAtual("Confirmacao_items_envio")
  }, []);

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const init = async (idCliente, idOrigem) => {
    var response = await getItemEnvio(idCliente, idOrigem);

    if (response.data.itens.length == 0)
      navigation("/CarrinhoVazio", {
        replace: false,
      });

    setItemCarrinhoEnvio(response.data);
    saveLocalStorageTimerCarrinho(response.data.dataExpiracao);
  };

  useEffect(() => {
    init(user.id, user.idOrigem ?? 0);
  }, []);

  const mutationRemoveItem = useMutation(LimpaCarrinho, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      setLoadingConfirmePedido(true);
      if (!!data.errorMessage) throw new Error(data.errorMessage, " - RE490");

      showAlertSuccess("Estamos removendo seu item, por favor aguarde");

      setTimeout(() => {
        clearLocalStorageTimerCarrinho();
        navigation("/CarrinhoVazio", {
          replace: false,
        });
      }, 2000);
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - RCL79 `
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      setTimeout(() => {
        setLoadingConfirmePedido(false);
      }, 2000);
    },
  });

  const handlerRemoveItem = (idMoeda) => (event) => {
    mutationRemoveItem.mutate(user.id);
  };

  const handleNext = async (event) => {
    event.preventDefault();
    try {
      setLoadingConfirmePedido(true);
      const responseFinalizacao = await finalizar(user.id);

      if (!!responseFinalizacao.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${responseFinalizacao.errorMessage} - R6674`
        );

      if (!!!responseFinalizacao.data)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi possivel finalizar o pedido - RA942`
        );

      showAlertSuccess(
        "Oba, seu pedido foi registrado, espere um pouco e veja como efetuar o pagamento"
      );

      if (responseFinalizacao && !responseFinalizacao.errorMessage) {
        clearLocalStorageTimerCarrinho();
        setTimeout(() => {
          setLoadingConfirmePedido(false);
          navigation(
            `/Envio/PedidoConcluidoPagamentoEscolhido/${responseFinalizacao.data}`
          );
        }, 2500);
      }
    } catch (error) {
      setLoadingConfirmePedido(false);
      showAlertError(`${error} - RC6A3`);
    }
  };

  const handleBack = () => {
    navigation("/carrinho/transferencia-internacional/envio/pagamento", {
      replace: false,
    });
  };

  return (
    <>


      <CarrinhoPage
        handleBackVoltarComprar={true}
        handleBack={handleBack}
        handleNext={handleNext}
        steps={StepEnvio}
        activeStep={3}
        loadingButton={loadingConfirmePedido}
      >
        {!!itemCarrinhoEnvio?.dataExpiracao && (
          <Timer
            active={!!itemCarrinhoEnvio.dataExpiracao}
            dataExpiracao={itemCarrinhoEnvio?.dataExpiracao}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}

        {/*<BreadCrumbsPaginas list={breadCrumbs} />*/}
        <Titulo className={classes.Titulo}>Confira sua operação</Titulo>
        <List
          dense
          sx={{
            bgcolor: "background.paper",

            position: "relative",
          }}
          className={classes.List}
        >
          {!loadingConfirmePedido && itemCarrinhoEnvio ? (
            <Grid item className={classes.BlocoConferir}>
              {itemCarrinhoEnvio && (
                <>
                  {!!itemCarrinhoEnvio?.pagadorRecebedor && (
                    <BeneficiarioResumoCarrinho
                      beneficiario={itemCarrinhoEnvio.pagadorRecebedor}
                    />
                  )}
                  <Grid
                    container
                    sx={{ justifyContent: "space-between", mb: 1 }}
                    xs={12}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      {itemCarrinhoEnvio?.natureza?.descricao}
                    </Typography>

                    <Typography sx={{ fontWeight: "bold" }}>
                      {itemCarrinhoEnvio?.pais?.descricao}
                    </Typography>
                  </Grid>
                  <Divider sx={{ mt: 1 }} />

                  <ItemCambioCarrinho
                    itens={itemCarrinhoEnvio.itens}
                    impostos={itemCarrinhoEnvio.impostos}
                    handlerRemoveItem={handlerRemoveItem}
                  />
                </>
              )}

              {itemCarrinhoEnvio && itemCarrinhoEnvio?.cupom?.id && (
                <Subtitulo align="right">
                  Desconto de R${" "}
                  {formatMoneyInStringForm(
                    itemCarrinhoEnvio?.cupom?.valorDesconto,
                    2
                  )}
                </Subtitulo>
              )}

              <Divider sx={{ mt: 1 }} />
              {itemCarrinhoEnvio &&
                itemCarrinhoEnvio.cupom &&
                itemCarrinhoEnvio.cupom.valorDesconto ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      mt: 1,
                    }}
                  >
                    {" "}
                    <Subtitulo
                      color="text.secondary"
                      label="Subtotal"
                    ></Subtitulo>
                    <Subtitulo sx={{ fontWeight: "500" }} align="right">
                      R${" "}
                      {formatMoneyInStringForm(
                        itemCarrinhoEnvio.cupom.valorDesconto +
                        itemCarrinhoEnvio.valorTotalMoedaNacional,
                        2
                      )}
                    </Subtitulo>
                  </Box>
                </>
              ) : (
                <Skeleton width={"100%"} sx={{ p: 0, m: 0 }} height={0} />
              )}
            </Grid>
          ) : (
            new Array(6)
              .fill(1)
              .map((i, index) => (
                <Skeleton
                  key={index}
                  sx={{ p: "0 !important", m: "0 !important" }}
                  height={50}
                  width={"100%"}
                  animation="wave"
                />
              ))
          )}
        </List>
        {itemCarrinhoEnvio.itens !== undefined && (
          <>
            <br></br>
            <Box>
              {" "}
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  width: "100%",
                  marginTop: "-1.6rem",
                  marginBottom: "1rem",
                }}
              >
                <CupomDesconto
                  cupom={itemCarrinhoEnvio && itemCarrinhoEnvio?.cupom}
                  idMoeda={itemCarrinhoEnvio?.itens[0]?.moeda?.id}
                  isLoading={loadingConfirmePedido}
                  setIsLoading={setLoadingConfirmePedido}
                  idTipoMercado={1}
                  idTipoPedido={typesTipoPedido.Remessa}
                  onReflesh={() => init(user.id, user.idOrigem ?? 0)}
                />
              </Grid>
            </Box>
          </>
        )}

        {itemCarrinhoEnvio &&
          itemCarrinhoEnvio?.valorTotalMoedaNacional &&
          (!loadingConfirmePedido ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 0,
              }}
            >
              <Titulo
                sx={{ fontSize: 20, fontWeight: "bold" }}
                label="Total"
              ></Titulo>

              <BadgeItemCarrinho carrinho={itemCarrinhoEnvio}>
                <Titulo
                  sx={{
                    fontWeight: "bold",
                    textAlign: "end",
                    fontSize: 20,
                    mt: 0.8,
                    mb: 4,
                  }}
                  color="primary"
                  align="right"
                >
                  R${" "}
                  {formatMoneyInStringForm(
                    itemCarrinhoEnvio?.valorTotalMoedaNacional,
                    2
                  )}
                </Titulo>
              </BadgeItemCarrinho>
            </Box>
          ) : (
            <Skeleton width={"100%"} height={50} animation="wave" />
          ))}
      </CarrinhoPage>
    </>
  );
}
