import React from "react";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import useStyle from "./style";

export default function IconButtonCustom({ onClick, children }) {
  const classes = useStyle();
  return (
    <>
      <IconButton
        className={classes.IconButton}
        onClick={onClick}
        sx={{ p: 0, m: 0, ml: 1 }}
      >
        {children}
      </IconButton>
    </>
  );
}
