
import { Grid } from "@mui/material";
import VerificationInput from "react-verification-input";
import useStyle from './styles.js'
import StepStatus from "../../components/stepsStatus/index.js";

export default function TesteComponente() {
  const style = useStyle();
  return (
    <>
      <h1>
        Pagina de Teste
      </h1>
      <Grid container spacing={2} >
        <StepStatus />
      </Grid>
    </>
  );
}
