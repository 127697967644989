import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Finalidade: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "none",
      margin: "auto !important",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto !important",
    },
  },
  List: {
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.up("lg")]: {
      // maxHeight: "62vh",
      width: "100%",
      paddingRight: "12px !important",
      overflow: "hidden",
      // overflow: "auto",
    },
  },

  BlocoFinalidade: {
    width: "28em",
  },

  Resumo: {
    border: "1px solid #ddd",
    borderRadius: 8,
    padding: 15,
    marginTop: 40,
    marginBottom: 50,
    backgroundColor: "#fcfcfd",
  },
  Titulo: {
    fontWeight: "500",
    paddingBottom: 30,
    color: "#444",
  },

  FormControl: {
    paddingBottom: 60,
    width: "100%",
  },
  Impostos: {
    display: "flex",
    justifyContent: "space-between",
  },
  InputLabel: {
    width: "5em",
  },
  input: {
    width: "100%",
  },
  IconBack: {
    color: "#000",
    opacity: 0.5,
    cursor: "pointer",
  },
  IconNext: {
    opacity: 0.5,
  },

  BeneficiarioCadastrado: {
    display: "flex",
    padding: 20,
    marginBottom: 20,
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
    backgroundColor: "#eee",
    justifyContent: "space-between",
  },
  Limite: {
    display: "flex",
    padding: 10,
    marginBottom: 20,
    alignItems: "center",
    borderRadius: 8,
    cursor: "pointer",
    backgroundColor: "#eee",
    justifyContent: "space-between",
  },

  Bandeira: {
    width: 20,
  },

  optionCustom: {
    padding: theme.spacing(1),
  },

  modalFinalizar: {
    "& button": {
      width: theme.spacing(22) + " !important",
    },
  },

  buttonMore: {
    opacity: .8,
    transition: 'all 300ms 0ms ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      opacity: 1,
    }
  },
}));

export default useStyles;
