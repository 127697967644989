import { makeStyles } from "@mui/styles";
const important = "!important";

const useStyle = makeStyles((theme) => ({
  AlertaLimite: {
    display: "block" + important,
    position: "relative",
    width: "100%",
    alignItems: "center",
    background: theme.palette.primary.lighter + important,
    zIndex: 2,
    padding: 10,
    margin: "0 !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex" + important,
      position: "relative",
      width: "100%",
      alignItems: "center",
      background: theme.palette.primary.lighter + important,
      zIndex: 2,
      padding: 10,
      margin: "0 !important",
    },
  },
}));

export default useStyle;
