import { Skeleton } from "@mui/material";




/**
    @param {string} animation Possui apenas 3 opções pulse(padrão), wave, false(desativa) 
    @return {JSX} retorna uma sequencia de Skeleton do material ui configurado
 */


const MultSkeleton = ({ quantidade, animation, height = 20, width = '100%', }) => {
    var novoArray = new Array(quantidade).fill(quantidade);
    return (
        novoArray.map((a, index) => (
            <Skeleton animation={animation} key={index} height={height} width={width} />
        ))
    )
}

export default MultSkeleton;