import api from "../index";
import { sendDataToAPI } from "../../util/encryptApi";
import { configurationVariables } from "../../environment";
// import CryptoJS from 'crypto-js';

const idOrigem = 3;

const idHotsite = parseInt(configurationVariables.IdHotsite);

//?=================================== forma pagamento ================================

export const desativarConta = async (idCliente) => {
  const data = await api.delete(
    `/Cliente/DeletarCliente?idCliente=${idCliente}&idOrigem=${idOrigem}`
  );

  return {
    data: data?.data,
    errorMessage: data.errorMessage,
  };
};

export const getPendencias = async (idCliente) => {
  const data = await api.get(`/cliente/Pendencias?idCliente=${idCliente}&idOrigem=${idOrigem}`);

  return {
    data: data?.data,
    errorMessage: data.errorMessage,
  };
};

export const isTokenAtivo = async () => {
  const data = await api.get(`/cliente/IsTokenAtivo?idOrigem=${idOrigem}`);

  return {
    data: data?.data?.data ?? false,
    status: data?.data?.status ?? 0,
    errorMessage: data?.errorMessage,
  };
};

export const clienteLogin = async (formData = null) => {
  let data = {};

  if (formData) {
    const payload = {
      usuario: formData.email,
      senha: formData.senha,
      idHotsite: idHotsite,
      codigoAutenticacao: formData.codigoAutenticacao,
      isLoginApp: false,
    };

    data = await sendDataToAPI(`/cliente/v2/Login?idOrigem=${idOrigem}`, payload);
  } else {
    data.data.errorMessage = "Não recebemos nenhum dado de login -  R56EZ";
  }

  return data;
};

export const ClienteSolicitaAutenticacao = async (formData = null) => {
  let data = {};

  if (formData) {
    const payload = {
      usuario: formData.email,
      senha: formData.senha,
      idHotsite: idHotsite,
      isLoginApp: false,
    };

    data = await sendDataToAPI(
      `/cliente/v2/SolicitaCodigoAutenticacaoCliente?idOrigem=${idOrigem}`,
      payload
    );
  } else {
    data.data = { errorMessage: "Não recebemos nenhum dado de login - RMZCC" };
  }

  return data;
};
