import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";

// ? ======================== Chamadas Mui =============================

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { Skeleton, Typography } from "@mui/material";
import List from "@mui/material/List";

// ? ======================== Chamadas componentes =============================
import { Titulo, Subtitulo, SeguroDetalhesPacote, ButtonAcaoGrande } from "../../../components";
import RemessaStyle from "./styles";
import { formatMoneyInStringForm } from "../../../util/helpers";
import useAlert from "../../../hooks/useAlert";
import { useAuth } from "../../../hooks/AuthContext";
import CarrinhoPage from "../../../components/CarrinhoPage";
import { useMutation } from "react-query";
import useLojaFechada from "../../../hooks/useLojaFechada";
import { useSeguro } from "../../../store/seguroViagem";
import { Finalizar, getCoberturas } from "../../../api/Carrinho/SeguroViagem";
import AddIcon from '@mui/icons-material/Add';




const steps = [
  {
    label: "Vitrine",
    description: "",
    link: "/SeguroViagem",
  },
  {
    label: "Passageiros",
    description: "",
    link: "/SeguroViagem/Passageiros",
  },
  {
    label: "Forma de pagamento",
    description: ``,
    link: "/SeguroViagem/Pagamento",
  },
  {
    label: "Finalizar",
    description: ``,
    link: "/SeguroViagem/ResumoSeguro",
  },
];



export default function ItemCarrinhoTurismo() {
  const classes = RemessaStyle();
  const navigation = useNavigate();
  const { user } = useAuth();
  const [loadingConfirmePedido, setLoadingConfirmePedido] = useState(false);
  const { showAlertError } = useAlert();
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();
  const { infoSeguro, restorePacoteDefault } = useSeguro();
  const [urlBack] = useState("/SeguroViagem/Pagamento");
  const [detalhes, setDetalhesSeguros] = useState();
  const [isLoadingDetalhes, setIsLoadingDetalhes] = useState(false);
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const mutationFinalizarSeguro = useMutation(Finalizar, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      if (!!data?.errorMessage) {
        showAlertError("Ops, Algo deu errado: " + data.errorMessage + " - RKE95")
        return;
      }
      restorePacoteDefault();
      navigation(`/SeguroViagem/Confirmacao/${data.data}`);
    },

    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        `Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - RRBOS `
      );
    },
    onSettled: () => {
      setTimeout(() => {
        setLoadingConfirmePedido(false)
      }, 2000);
    }
  });



  const mutationGetCobertura = useMutation(getCoberturas, {
    onSuccess: (response) => {
      if (response?.errorMessage && response?.errorMessage?.length > 0) {
        showAlertError(`Ahh, algo deu Errado: ${response.errorMessage}`);
      }

      setDetalhesSeguros(response.data);

    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {

      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },

    onSettled: () => setIsLoadingDetalhes(false)
  });


  const handleNext = async (event) => {
    event.preventDefault();
    try {
      setLoadingConfirmePedido(true);

      mutationFinalizarSeguro.mutate(user.id);

    } catch (error) {
      setLoadingConfirmePedido(false);
      showAlertError(`${error} - RW6GZ`);

    }
  };

  const handlerComprarMais = () => {
    navigation("/SeguroViagem");
  };

  const handleBack = () => {
    navigation(urlBack, {
      replace: false,
    });
  };


  const handlerModalPacote = (open = false) => () => {

    setShowModal(state => open);

    if (open === true)
      if (!!infoSeguro)
        mutationGetCobertura.mutate(infoSeguro);

  }



  return (
    <>


      <CarrinhoPage
        handleBack={handleBack}
        handleNext={handleNext}
        steps={steps}
        activeStep={3}
        loadingButton={loadingConfirmePedido}
        disabledVoltarCompras={false}
        handleBackVoltarComprar={handlerComprarMais}
      >

        <List dense className={classes.List}>
          <Grid item className={classes.BlocoConferir}>
            {loadingConfirmePedido ? (
              <Skeleton height={50} width={200} animation="wave" />
            ) : (
              <>
                <Titulo sx={{ mb: 1 }}>Confira sua Solicitação</Titulo>
                <Titulo sx={{ fontSize: 16, mb: 3 }} color="text.secondary">
                  Seguro Viagem
                </Titulo>
              </>
            )}

            {
              Object.keys(infoSeguro.pacotes).length > 0 &&
                loadingConfirmePedido ? (
                <>
                  {Array.from({ length: 6 }, (_, index) => (
                    <Skeleton key={index + "ske"} height={50} width={"100%"} animation="wave" />
                  ))}
                </>

              ) :
                <>
                  <Divider />

                  <Titulo sx={{ marginTop: "1rem" }}>Dados do pacote</Titulo>
                  <br />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: ".5rem",
                      alignItems:"center",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Pacote Selecionado:
                    </Typography>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {infoSeguro.pacoteSelecionado.nomeProduto}
                    </Typography>
                    <Typography
                      onClick={handlerModalPacote(true)}
                      className={classes.buttonMore}
                      color="text"

                    >
                      <ButtonAcaoGrande>
                        Ver Mais{" "}
                        <AddIcon sx={{ fontSize: 13 }} />
                      </ButtonAcaoGrande>
                    </Typography>

                  </Box>

                  <Divider />

                  <Titulo sx={{ marginTop: "1rem" }}>Dados do Passageiros</Titulo>
                  <br />

                  {infoSeguro.passageiros.length > 0 &&
                    infoSeguro.passageiros.map((item, index) => (
                      <Fragment key={index}>
                        <Subtitulo sx={{ marginTop: "1rem" }}> {index + 1}º Passageiro </Subtitulo>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: ".5rem",
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            Nome:
                          </Typography>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {`${item.nome}  ${item.sobrenome}`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: ".5rem",
                          }}
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            Idade:
                          </Typography>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {item.idade}
                          </Typography>
                        </Box>

                      </Fragment>
                    ))
                  }
                </>
            }

          </Grid>
        </List>
        <Divider />
        <br></br>
        {infoSeguro &&
          infoSeguro?.pacoteSelecionado?.valorTotal > 0 &&
          loadingConfirmePedido ? (
          <Skeleton height={50} width={"100%"} animation="wave" />

        ) :
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 0,
              }}
            >
              <Titulo
                sx={{ fontSize: 20, fontWeight: "bold" }}
                label="Total"
              ></Titulo>


              <Titulo
                sx={{
                  fontWeight: "bold",
                  textAlign: "end",
                  fontSize: 20,
                }}
                color="primary"
                align="right"
              >
                R${" "}
                {formatMoneyInStringForm(
                  infoSeguro?.pacoteSelecionado?.valorTotal,
                  2
                )}
              </Titulo>

            </Box>
          </>

        }
        <br></br>
        <Box display="flex" sx={{ maxWidth: '600px !important' }} justifyContent="start">

          <SeguroDetalhesPacote
            isLoading={isLoadingDetalhes} showModal={showModal} setShowModal={setShowModal}
            handlerClose={handlerModalPacote} detalhes={detalhes} />
        </Box >
      </CarrinhoPage >
    </>
  );
}
