
import { formatMoneyInStringForm } from '../../../util/helpers';
import { Box } from '@mui/material';
import TiposOperacaoCarrinho from "../../../util/typesTipoPedido";
import { Badge } from 'rsuite';



export default function BadgeItemCarrinho({ carrinho, children }) {
      const valorDesconto = () => {
            let idTipoOperacaoCarrinho = carrinho?.itens[0]?.idTipoOperacaoCarrinho;

            switch (idTipoOperacaoCarrinho) {
                  case TiposOperacaoCarrinho.VendaOuro:
                  case TiposOperacaoCarrinho.VendaEspecie:
                  case TiposOperacaoCarrinho.Remessa:
                        return `- R$ ${formatMoneyInStringForm(carrinho?.cupom?.valorDesconto, 2).replace(/[+-]/g, "")}`
                  default:
                        return `+ R$ ${formatMoneyInStringForm(carrinho?.cupom?.valorDesconto, 2).replace(/[+-]/g, "")}`
            }
      }

      if (carrinho?.cupom?.valorDesconto !== 0 && !!carrinho?.cupom?.valorDesconto) {

            return (

                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', p: 0, mt: '-1rem' }}>
                        <Badge content={valorDesconto()} color={'green'} style={{ width: 'fit-content', marginBottom: '-.2rem' }} >
                        </Badge>

                        {children}

                  </Box>
            )
      }


      return <>{children}</>;
}
