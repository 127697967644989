import { makeStyles } from "@mui/styles";


const useStyles = makeStyles((theme) => ({
  Link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  ContainerVerification: {

  },
  VerificationCaracter: {
    border: 'none',
    fontSize: '20px',
    borderRadius: '12px',
    color: '#f2f2f2',
    backgroundColor: theme?.palette?.text?.primary,
    boxShadow: `0 2px 0  ${theme.palette.primary.main}`,
    transition: 'all 400ms ease-in-out',
  },
  VerificationCaracterInac: {
    opacity: .5,
  },
  VerificationCaracterSelec: {
    position: 'relative',
    opacity: 1,
  },
  VerificationCaracterFil: {

  },

  "@keyframes blink": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0,
    }
  }
}));

export default useStyles;
