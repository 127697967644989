import { List, ListItem, ListItemText, Typography } from "@mui/material";
import { Subtitulo } from "../";
import AlertCustomFixed from "../AlertCustomFixed";
import parseHtml from "html-react-parser";

const Delivery = ({ regiaoEntrega, pontoRetirada, endereco }) => {
  return (
    <>
      <List sx={{ width: "100%" }}>
        {/* <Subtitulo>Endereço destino</Subtitulo> */}

        {regiaoEntrega && (
          <>
            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={<ListItemText primary={"Delivery"} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Entrega Selecionada:</ListItemText>
            </ListItem>


          </>
        )}

        {pontoRetirada && (
          <>
            <ListItem
              sx={{ marginTop: "1rem", marginBottom: '.5rem' }}
              secondaryAction={<ListItemText primary={"Retirada em loja"} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Entrega Selecionada:</ListItemText>
            </ListItem>
            {
              !!pontoRetirada?.descricao &&
              <AlertCustomFixed type="info" sx={{ textAlign: 'start' }}>
                <Typography>
                  Informação sobre o ponto de retirada:
                </Typography>
                {parseHtml(pontoRetirada?.descricao)}
              </AlertCustomFixed>
            }


          </>
        )}

        {!regiaoEntrega && !pontoRetirada && (
          <>
            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={<ListItemText primary={"Sedex"} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Entrega Selecionada:</ListItemText>
            </ListItem>
          </>
        )}

        {!!endereco && (
          <>
            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={
                <ListItemText
                  primary={`${endereco.logradouro} - ${endereco.numero}`}
                />
              }
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Logradouro:</ListItemText>
            </ListItem>

            <ListItem
              sx={{ marginTop: "1rem" }}
              secondaryAction={<ListItemText primary={endereco.bairro} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Bairro:</ListItemText>
            </ListItem>
            <ListItem
              sx={{ marginTop: "1rem", marginBottom: '.5rem' }}
              secondaryAction={<ListItemText primary={endereco.cidade} />}
              disablePadding
            >
              <ListItemText sx={{ pl: 1 }}>Cidade:</ListItemText>
            </ListItem>

            {!!regiaoEntrega?.descricaoHtml &&
              <AlertCustomFixed type="info" sx={{ textAlign: 'start' }}>
                <Typography>
                  Informação delivery:
                </Typography>
                {parseHtml(regiaoEntrega?.descricaoHtml)}
              </AlertCustomFixed>
            }
          </>
        )}
      </List>
    </>
  );
};

export default Delivery;
