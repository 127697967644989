import Typography from "@mui/material/Typography";

export default function Subtitulo({ color, label, align, sx, children = "", className }) {
  return (
    <Typography
      textAlign={align}
      color="text.secondary"
      sx={sx}
      className={className}
      
      variant="body1"
    >
      {label ?? children}
    </Typography>
  );
}
