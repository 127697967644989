import { makeStyles } from "@mui/styles";

const ip = " !important";

export const Style = makeStyles((theme) => ({

    itemListMaisMoedas: {
        display: 'block',
        justifyContent: 'space-between !important',
        width: "100%",
        background:'#fcfcfc',
        border:'1px solid #ccc',
        borderRadius:10,
       
    }

}));
