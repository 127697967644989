
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { SEOTAGS } from "../../util/typesReactQuery";
import { getTags } from '../../api/SEO'


const useSeoConfig = () => {

      const { data: tags, refetch } = useQuery({
            queryKey: [SEOTAGS],
            queryFn: getTags,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                  initialConfig();
            },
            retryDelay: 3000
      })



      const initialConfig = () => {
            let code = tags?.data?.codigoTagGlobalAnalytics || "";


            if (!!code) {
                  const tagManagerArgs = {
                        gtmId: { code }
                  }
                  TagManager.initialize(tagManagerArgs)
            }

      }

      const sendTagConversionLogin = () => {

            let codeEntra = tags?.data?.codigoTagConversaoGoogleBotaoEntrar || "";
            if (!!codeEntra) {
                  const dataLayer = {
                        event: { codeEntra },
                  };

                  TagManager.dataLayer(dataLayer);
            }

      }

      const sendTagConversionCadastrar = () => {
            let codeCadastrar = tags?.data?.codigoTagConversaoGoogleBotaoCadastrar || "";
            if (codeCadastrar) {
                  const dataLayer = {
                        event: { codeCadastrar },
                  };

                  TagManager.dataLayer(dataLayer);
            }
      }

      const sendTagConversionFinalizar = () => {
            let codeFinalizar = tags?.data?.codigoTagConversaoPaginaCompraFinalizada || "";
            if (codeFinalizar) {
                  const dataLayer = {
                        event: { codeFinalizar },
                  };

                  TagManager.dataLayer(dataLayer);
            }
      }

      const sendPageAtual = (titlePage) => {

            const dataLayer = {
                  event: { titlePage },
                  page: window.location.pathname
            };

            TagManager.dataLayer(dataLayer);
      }


      return {
            sendTagConversionCadastrar,
            sendTagConversionLogin,
            sendTagConversionFinalizar,
            sendPageAtual
      }

}

export default useSeoConfig;