import { useState, useEffect } from "react";
import CampoTexto from "../../../components/Campos/CampoTexto";
import SeletorFilter from "../../../components/Campos/SeletorFilter";
import ButtonAcaoGrande from "../../../components/Botoes/ButtonAcaoGrande";
import { GetBancosNacionais } from "../../../api/BancosNacionais";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";


function DadoBancario({ formaPagamento, onNext, idTipoPedido }) {
  const [bancos, setBancos] = useState([]);
  const [dadoBancario, setDadoBancario] = useState({
    idBancoNacional: 0,
    agencia: "",
    digitoAgencia: "",
    numeroConta: "",
    digitoConta: "",
  });


  const init = async () => {
    const response = await GetBancosNacionais();
    setBancos(response.data);
  };

  useEffect(() => {
    init();
  }, []);

  const handlerChangeDadoBancario = (propriedade, value) => {
    setDadoBancario({ ...dadoBancario, [propriedade]: value });
  };

  const handlerSelectedBanco = (id) => {
    setDadoBancario({ ...dadoBancario, idBancoNacional: id });
  };

  const handlerNext = (event) => {
    let pagamento = formaPagamento;
    pagamento.dadoBancario = dadoBancario;
    onNext(event, pagamento);
  };

  return (
    <>
      <Box style={{ float: "left" }}>
        <AccountBalanceIcon sx={{ paddingRight: 3, color: "#aaa" }} />
        <Typography>{formaPagamento.descricao}</Typography>
      </Box>
      {bancos && (
        <SeletorFilter
          label="Bancos"
          itens={bancos}
          onSelectedId={handlerSelectedBanco}
        />
      )}
      <CampoTexto
        name="agencia"
        label="Agência"
        value={dadoBancario.agencia}
        handlerChange={(e) =>
          handlerChangeDadoBancario(e.target.name, e.target.value)
        }
        enable={true}
      />
      <CampoTexto
        name="digitoAgencia"
        label="Digíto Agência"
        value={dadoBancario.digitoAgencia}
        handlerChange={(e) =>
          handlerChangeDadoBancario(e.target.name, e.target.value)
        }
        enable={true}
      />
      <CampoTexto
        name="numeroConta"
        label="Número de Conta"
        value={dadoBancario.numeroConta}
        handlerChange={(e) =>
          handlerChangeDadoBancario(e.target.name, e.target.value)
        }
        enable={true}
      />
      <CampoTexto
        name="digitoConta"
        label="Digíto Conta"
        value={dadoBancario.digitoConta}
        handlerChange={(e) =>
          handlerChangeDadoBancario(e.target.name, e.target.value)
        }
        enable={true}
      />
      <ButtonAcaoGrande
        Handle={(event) => handlerNext(event)}
        texto="Continuar"
        loading={false}
      ></ButtonAcaoGrande>
    </>
  );
}

export default DadoBancario;
