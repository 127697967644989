import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  campo: {
    margin: "10px",
    padding: 10,
  },
  
}));

export default useStyle;
