import { configurationVariables } from "../../../environment";
import api from "../../index";

const idOrigem = 3;
const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getAllItensCarrinho = async (idCliente) => {
  try {
    const { data: response, status } = await api.get(
      `/CarrinhoGeral/GetAllItensCarrinhos?idCliente=${idCliente}&idOrigem=${idOrigem}&idHotsite=${idHotsite}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R2AC0");
  }
};
