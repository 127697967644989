import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Grid: {
    padding: 0,
  },
  container:{
    paddingLeft:0,
    [theme.breakpoints.up("sm")]: {
      paddingLeft:20,
      paddingTop:20,
    }
  },
  Titulo:{
    padding:'8px 20px 30px 20px',
  },
  Salvar:{
    padding:'8px 20px 30px 20px',
    marginLeft:'auto !important',

  }
}));

export default useStyles;
