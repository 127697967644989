import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  box: {
    margin: "0",

    [theme.breakpoints.up("sm")]: {
      margin: "auto",

   
    },
  },
}));

export default useStyles;
