import { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ButtonAcaoGrande from "../../../Botoes/ButtonAcaoGrande";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Style } from "./styles";
import theme from "../../../Paleta/theme";
import CheckIcon from "@mui/icons-material/Check";
import { formatMoneyInString } from "../../../../util/helpers";
import { useSeguro } from "../../../../store/seguroViagem";
import useAlert from "../../../../hooks/useAlert";
import { addSeguroViagem } from "../../../../api/Carrinho/SeguroViagem";

import SeguroDetalhesPacote from "../../../SeguroDetalhesPacote";
import LogoSeguro from "../../../../../src/assets/images/assistcard.png";

function Itens() {
  const navigation = useNavigate();
  const classes = Style();
  const [showModal, setShowModal] = useState(false);

  const { infoSeguro, updateSelectPacote } = useSeguro();
  const [setIsLoading] = useState(false);
  const [seguros, setSeguros] = useState();
  const { showAlertError } = useAlert();

  useEffect(() => {
    setSeguros(() => infoSeguro.pacotes);
  }, [infoSeguro.pacotes]);

  const handlerComprarPacote = async (pacote = null) => {
    if (!pacote) {
      showAlertError("Ops, é necessario escolher um pacote");
      return;
    }

    const response = await addSeguroViagem(infoSeguro, pacote);
    updateSelectPacote(pacote);

    if (!!response?.errorMessage) {
      showAlertError(`Ops, algo deu errado: ${response.errorMessage} - R06JF`);
      return;
    }

    navigation("/SeguroViagem/Passageiros");
  };

  const handlerSelectedSeguro = (pacote) => (event) => {
    setShowModal((state) => !state);
    updateSelectPacote(pacote);
    if (pacote) {
      setIsLoading(true);

      let dados = {
        ...infoSeguro,
        pacoteSelecionado: { ...pacote },
      };
    }
  };

  return (
    <>
      <Grid className={classes.Container}>
        <Grid className={classes.Grid} item xs={11} lg={8}>
          <Box className={classes.Titulo}>
            <WorkOutlinedIcon sx={{ mr: 2 }} />
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Escolha seu Seguro Viagem e viaje mais tranquilo!
            </Typography>
          </Box>
        </Grid>
        {seguros &&
          seguros.map((pacote, index) => {
            return (
              <Fragment key={index}>
                <Grid className={classes.Item} sx={{}} container xs={11} lg={8}>
                  <Grid
                    container
                    item
                    lg={2}
                    md={12}
                    sm={12}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Typography className={classes.text}>Seguradora</Typography>
                    <Box
                      component="img"
                      src={LogoSeguro}
                      className={classes.img}
                      sx={{}}
                    ></Box>
                  </Grid>
                  <Grid
                    container
                    item
                    lg={5}
                    md={12}
                    sm={12}
                    direction="column"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <>
                      <Typography
                        sx={{
                          fontSize: 21,
                          fontWeight: "bold",
                          color: theme.palette.primary.main,
                        }}
                      >
                        {pacote.nomeProduto}
                      </Typography>
                      <Typography color="text.secondary" sx={{ mt: 0 }}>
                        <CheckIcon className={classes.Check} />
                        Confira os detalhes para mais informações
                      </Typography>
                    </>
                    <Box sx={{}}>
                      <Typography
                        onClick={handlerSelectedSeguro(pacote)}
                        className={classes.span}
                        color="text"
                      >
                        <span>
                          Ver mais <ArrowForwardIosIcon sx={{ fontSize: 11 }} />
                        </span>
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item md={12} sm={12} lg={4} className={classes.Bloco}>
                    <Typography className={classes.ValorFinal}>
                      Valor final:
                    </Typography>
                    <Box className={classes.Valor}>
                      <Typography
                        onClick={() => setShowModal(!showModal)}
                        sx={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          ml: 1,
                          fontSize: 24,
                        }}
                        color="text"
                      >
                        {formatMoneyInString(pacote.valorTotal)}
                      </Typography>
                    </Box>
                    <Grid className={classes.ButtonAcaoGrande}>
                      <ButtonAcaoGrande
                        Handle={() => handlerComprarPacote(pacote)}
                        texto="Contratar"
                        className={classes.buttonSearch}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <br></br>
              </Fragment>
            );
          })}
      </Grid>
      <Box
        display="flex"
        sx={{ maxWidth: "600px !important" }}
        justifyContent="start"
      >
        <SeguroDetalhesPacote
          isValorVisible={true}
          showModal={showModal}
          setShowModal={setShowModal}
          handlerClose={handlerSelectedSeguro}
        />
      </Box>
    </>
  );
}

export default Itens;
