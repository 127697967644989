import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CampoTexto from "../Campos/CampoTexto";
import CampoCep from "../Campos/CampoCep";
import Seletor from "../Seletor";
import { Skeleton } from "@mui/material";

const tiposEndereco = [
  {
    id: 1,
    descricao: "Residencial",
  },
  {
    id: 2,
    descricao: "Comercial",
  },
  {
    id: 3,
    descricao: "Não Informado",
  },
];

export default function EnderecoItem({ data, setData, isEdit = false, setTextError = null, setTextSuccess = null }) {
  const [enderecoState, setEnderecoState] = useState(data);
  const [cepChanged, setCepChanged] = useState(false);



  useEffect(() => {
    setEnderecoState(data);
    setData(data);
  }, [data]);

  const handleChange = (e) => {
    setEnderecoState({ ...enderecoState, [e.target.name]: e.target.value });

    if (e.target.name === "cep")
      setCepChanged(true);

    setData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  };


  const limparCampos = () => {
    let newEndereco = enderecoState;
    newEndereco.cidade = "";
    newEndereco.bairro = "";
    newEndereco.logradouro = "";
    newEndereco.ufDescricao = "";
    newEndereco.idUf = "";
    newEndereco.idPais = "";

    setEnderecoState(newEndereco);
    setData(newEndereco);
  }

  const handleChangeEnderecoByCEP = (e) => {

    if (e?.errorMessage) {
      setTextError(`Ops, ${e.errorMessage} - RLNVD `);
      limparCampos();
      return;
    }

    setTextSuccess("Eba, encontramos o seu endereço")

    let newEndereco = enderecoState;
    newEndereco.cidade = e?.data?.cidade;
    newEndereco.bairro = e?.data?.bairro;
    newEndereco.logradouro = e?.data?.logradouro;
    newEndereco.ufDescricao = e?.data?.ufDescricao;
    newEndereco.idUf = e?.data?.idUf;
    newEndereco.idPais = e?.data?.idPais;

    setEnderecoState(newEndereco);
    setData(newEndereco);
  };

  const handlerChangeSelect = (name, value) => {
    setEnderecoState({ ...enderecoState, [name]: value });
    setData({ ...enderecoState, [name]: value });
  };




  return (
    <>

      {enderecoState && (
        <Grid
          container
          xs={12}
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 2, md: 3, p: 15 }}
        >
          <Grid item xs={6}>
            <Seletor
              setSelect={(e) => handlerChangeSelect("idTipoEndereco", e)}
              list={tiposEndereco}
              size="small"
              fullWidth={true}
              label="Tipos de Endereço"
              value={enderecoState.idTipoEndereco}
            />
          </Grid>

          <Grid item xs={6}>
            <CampoCep
              handlerChange={handleChange}
              name="cep"
              recarregar={cepChanged}
              size="small"
              fullWidth={true}
              value={enderecoState.cep}
              label={"Cep"}
              enable={true}
              setEndereco={handleChangeEnderecoByCEP}
              idTipoEndereco={enderecoState.idTipoEndereco}
              idCliente={enderecoState.idCliente}
              setTextError={setTextError}
            />
          </Grid>
          <Grid item xs={6}>
            <CampoTexto
              handlerChange={handleChange}
              name="logradouro"
              size="small"
              value={enderecoState.logradouro}
              label="Rua"
              enable={isEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <CampoTexto
              handlerChange={handleChange}
              name="numero"
              size="small"
              value={enderecoState.numero}
              label="Número"
              enable={true}
            />
          </Grid>
          <Grid item xs={6}>
            <CampoTexto
              handlerChange={handleChange}
              name="complemento"
              size="small"
              value={enderecoState.complemento}
              label="Complemento"
              enable={isEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <CampoTexto
              handlerChange={handleChange}
              name="bairro"
              size="small"
              value={enderecoState.bairro}
              label="Bairro"
              enable={isEdit}
            />
          </Grid>
          <Grid item xs={6}>
            <CampoTexto
              handlerChange={handleChange}
              name="cidade"
              size="small"
              value={enderecoState.cidade}
              label="Cidade"
              enable={false}
            />
          </Grid>
          <Grid item xs={6}>
            <CampoTexto
              handlerChange={handleChange}
              name="UfDescricao"
              value={enderecoState.ufDescricao}
              label="Uf"
              size="small"
              enable={false}
            />
          </Grid>
        </Grid>
      )
      }
    </>
  );
}
