import { makeStyles } from "@mui/styles";

const Style = makeStyles((theme) => ({
  containerPassageiro: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    cursor: "pointer",
  },
  Item: {
    display: "contents",
    width: "100%",
    background:'#eee',
    [theme.breakpoints.up("sm")]: {
      background:'#eee',
      // display: "flex",
      width: "100%",
    },
  },
  containerSelecao: {},
  boxSelecao: {},
  campoViajante: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  boxInformativo: {},
  boxButton: {
    borderRadius: 6,
    justifyContent: "space-between",
    width: "95%",
    marginTop: 10,
    flexDirection: "row",
    background: "#fff",
    alignItems: "center",
    boxShadow: "0 4px 8px rgba(68,68,68,.2)",
  },
  literalQuantidade: {
    fontSize: "30px !important",
    marginLeft: "10px",
    marginRight: "10px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px !important",
    },
  },
  button: {
    cursor: "pointer",
    color: theme.palette.primary.main,
    margin: 10,
    fontSize: 30,
  },
  Campo: {
    padding: 10,

    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginRight: "12px !important",
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 14,
      paddingBottom: 15,
      width: "100%",
    },
  },
  boxCamposIdade: {
    // display: "flex",
    // flexDirection: "row",
    alignItems: "center",
  },
  Paper: {
    width: "100vw",
    left: 0,
    top: 0,
    height: "100vh",
   
    position: "absolute",
    boxShadow: "0px 8px 8px 8px rgba(0,0,0,0.1)",
    zIndex: 2000,
    [theme.breakpoints.up("sm")]: {
      width: 360,
      marginTop: 0,
      position: "absolute",
      boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.2) !important",
      zIndex: 2000,
      height: "auto",
      top: "auto",
      left: "auto",
    },
  },
  Box: {
    display: "flex",
    alignItems: "center",
    background: "#eee",
    width: "100%",
    margin: "auto",
    justifyContent: "center",
    borderBottom: "1px solid #ddd",
    marginBottom: 20,

    paddingTop: 60,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      background: "#eee",
      width: "100%",
      margin: "auto",
      justifyContent: "center",
      borderBottom: "1px solid #ddd",
      marginBottom: 12,

      paddingTop: 20,
    },
  },
  Titulo: {
    fontSize: "18px !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px !important",
    },
  },
}));

export default Style;
