import { makeStyles } from "@mui/styles";


const useStyle = makeStyles((theme) => ({
      BadgeMenu: {
            '& .MuiBadge-badge ': {
                  color: '#fff !important',
            },
            '& span': {
                  right: '-1.1rem',
                  top: '.75rem'
            }
      },
}));

export default useStyle;