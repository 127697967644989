import { Alert, AlertTitle, Badge, Box, Grid, Typography } from "@mui/material";
import useCheckPendencia from "../../hooks/useCheckPendencia";
import AlertCustomFixed from "../AlertCustomFixed";
import useAlert from "../../hooks/useAlert";
import { useEffect } from "react";
import TiposPendencia from "../../util/typesTiposPendencia";

function PendenciaCliente({
  handlerPanelDocumentos,
  handlerPanelDadosCadastrais,
}) {
  const { pendencias, messageError } = useCheckPendencia();
  const { showAlertError } = useAlert();

  useEffect(() => {
    if (!!messageError) showAlertError(messageError);
  }, [messageError]);

  const handlerChangePanels = (pendencia) => {
    if (!pendencia.hasOwnProperty("tipoPendencia")) {
      console.error("Não consta a propriedade tipoPendencia");
      return;
    }

    if (pendencia?.tipoPendencia?.id === TiposPendencia.documentos)
      handlerPanelDocumentos();
    if (pendencia?.tipoPendencia?.id === TiposPendencia.dadosCadastrais)
      handlerPanelDadosCadastrais();
  };

  return (
    <>
      <Grid container >
        <Grid item md={8} lg={8} sm={12} sx={{ mt: 2, mr: 1 }}>
          <AlertCustomFixed
            isHabilitaButton={false}
            type="warning"
          >
            Se você já tomou as medidas necessárias, por favor, aguarde enquanto nossa equipe verifica e atualiza suas informações.
          </AlertCustomFixed>
        </Grid>
        <Grid item md={8} lg={8} sm={12} sx={{ display: "flex", justifyContent: "space-around", mt: 4, mb: 4 }}>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge color="warning" variant="dot" sx={{ mr: 1 }} />
            <Typography>Não Impede de operar</Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge color="error" variant="dot" sx={{ mr: 1 }} />
            Impede de operar
          </Box>
        </Grid>
        <Grid item md={8} lg={8} sm={12}>
          {!!pendencias && pendencias.length > 0 ? (
            pendencias.map((pendencia, index) => (
              <AlertCustomFixed
                key={`${index}pen`}
                isHabilitaButton={true}
                textButton={"Resolver Agora"}
                handlerActionButton={() => handlerChangePanels(pendencia)}
                type={pendencia.impedeOperar ? "error" : "warning"}
                sx={{ boxShadow: "6px 6px 8px -3px #c6c6c6" }}
              >
                <AlertTitle>{pendencia.descricao}</AlertTitle>
                {pendencia.observacao}
              </AlertCustomFixed>
            ))
          ) : (
            <AlertCustomFixed
              isHabilitaButton={false}
              type="info"
              sx={{ boxShadow: "6px 6px 8px -3px #c6c6c6" }}
            >
              Você não possuí nenhuma pendência em seu cadastro.
            </AlertCustomFixed>
          )}
        </Grid>

      </Grid>



    </>
  );
}

export default PendenciaCliente;
