
export const formatMoneyInString = (valor, currency = "BRL") => {
  return (valor ?? 0).toLocaleString("pt-BR", {
    style: "currency",
    currency: currency,
    maximumSignificantDigits: 10,
    maximumFractionDigits: 2,
    formatMoneyStringInDecimal: 2,
    currencyDisplay: "symbol",
  });
};

export const formatMoneyInStringForm = (n, c) => {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = ",",
    t = ".",
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;
  return (
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
      Math.abs(n - Number(i))
        .toFixed(c)
        .slice(2)
      : "")
  );
};

export const removePontoString = (value) => {
  let formatStringValue = "";

  if (!value.includes(",")) value += ",00";

  if ((value.match(/\./g) || []).length > 1)
    formatStringValue = value.replaceAll(".", "");
  else formatStringValue = value.replace(".", "");

  return formatStringValue;
};

export const formatMoneyToDecimal = (value) => {
  var teste = value
    .replace(".", "")
    .replace(",", ".")
    .replace("R$ ", "")
    .replace(" ", "");

  return teste;
};

export const formatMoneyStringInDecimal = (
  value,
  isAcrecimoDecimal = false
) => {
  let formatStringValue = "";
  let valor = value;

  if (typeof value == "number") return value;

  if (isAcrecimoDecimal) {
    if (!valor.includes(",")) {
      valor += ",00";
    }
  }

  if ((value.match(/\./g) || []).length > 1)
    formatStringValue = valor.replaceAll(".", "");
  else formatStringValue = valor.replace(".", "");

  formatStringValue = formatStringValue.replace(",", ".");

  let valueFormat = Math.abs(formatStringValue);

  return valueFormat;
};

// Usado para formatar a data para o objeto New Date(), retornando) uma string '2022,08,12'
// Params:
// obj Date
export const formatDateForString = (date) => {
  return date.toISOString().split("T")[0].split("-").join(",");
};

export const formatStringForStringDate = (string) => {
  return string.split("-").join(",");
};

export const formatAmountDecimaHouse = (value, numberHouse) => {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (numberHouse || -1) + "})?");
  return Number(value.toString().match(re)[0]);
};

export function formatMoney(n, c) {
  if (isNaN(n)) return 0;

  return parseFloat(n).toFixed(c);
}

export const configInitialvalue = (obj) => {
  if (obj) {
    if ("valorInicial" in obj) {
      if (obj.valorInicial > 0) {
        let valor = String(obj.valorInicial);
        if (!valor.includes(",")) return (valor += ",00");

        return valor;
      }

      if (obj.valorMinimo > 0) {
        let valor = String(obj.valorMinimo);
        if (!valor.includes(",")) return (valor += ",00");

        return valor;
      }
    }
    return "100,00";
  }

  return "100,00";
};

export const multFunctions = (...funcoes) => {
  return function (value, casas) {
    return funcoes.reduce((v, f) => f(v, casas), value);
  };
};


export function darkenColor(hexColor, percent) {
  // Converte o valor hexadecimal em componentes RGB
  const red = parseInt(hexColor.substr(1, 2), 16);
  const green = parseInt(hexColor.substr(3, 2), 16);
  const blue = parseInt(hexColor.substr(5, 2), 16);

  // Calcula o percentual a ser aplicado
  const factor = 1 - percent / 100;

  // Calcula os novos valores RGB com base no fator de escurecimento
  const newRed = Math.floor(red * factor);
  const newGreen = Math.floor(green * factor);
  const newBlue = Math.floor(blue * factor);

  // Converte os novos valores RGB para hexadecimal
  const darkenedHex = `#${newRed.toString(16).padStart(2, '0')}${newGreen.toString(16).padStart(2, '0')}${newBlue.toString(16).padStart(2, '0')}`;

  return darkenedHex;
}


function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-1);
  }
  /* eslint-enable no-bitwise */

  return color;
}

/**
 * 
 * @param {string} name => Nome que sera usando para criar o "balão"
 * @param {string} size => Seleciona um tamanho mudando apenas alguns pixel (small, medium, large) 
 * @returns 
 */
export function stringAvatar(name, size = "large") {
  if (!name) return "";

  let nameSplit = name.split(" ");

  //? Elimina qualquer valor vazio, null ou undefined do array
  nameSplit = nameSplit.filter(Boolean);

  let abreviacaoName = "";

  if (nameSplit.length < 2) abreviacaoName = nameSplit[0][0];
  else abreviacaoName = nameSplit[0][0] + nameSplit[1][0];

  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size === "large" ? "40px !important" : size === "medium" ? "32px !important" : "30px !important",
      textTransform: "uppercase !important",
      height: size === "large" ? "40px !important" : size === "medium" ? "32px !important" : "30px !important",
      fontSize: size === "large" ? "16px !important" : size === "medium" ? "14px !important" : "15px !important",
      marginRight: 1,
    },
    children: `${abreviacaoName}`,
  };
}


export function verificaPedidoDiferente(itemCarrinho, pedido) {
  if (!!pedido?.itens?.length && itemCarrinho) {
    if (pedido?.itens?.[0]?.idTipoOperacaoCarrinho !== itemCarrinho?.idTipoOperacaoCarrinho)
      return "Ops, seu carrinho será atualizado devido o tipo da operação ser diferente, deseja continuar?";


    if (pedido.praca.id !== itemCarrinho.idPraca)
      return "Ops, seu carrinho será atualizado devido a troca da região, deseja continuar?"

  }
}


export function verificaPedidoTiposIguais(itemCarrinho, pedido) {

  if (!!pedido?.itens?.length && itemCarrinho) {
    if (pedido?.itens?.every(item => item.idTipoOperacaoCarrinho !== itemCarrinho?.idTipoOperacaoCarrinho))
      return;

    if (pedido?.itens?.every(item => item.idItemVitrine !== itemCarrinho?.idItemVitrine))
      return "Ops, você não adicionou a moeda selecionada em seu carrinho, deseja continuar mesmo assim?";

    return;
  }
}

//verifica se a idade atual calculada pela data, bate com a idade atual informada
function isIdadeDiferente(idadeCalculada, idadeCorreto) {
  if (idadeCalculada !== idadeCorreto)
    return true;
  return false;
}

