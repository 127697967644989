
import { styled } from '@mui/material/styles';
import { Badge } from '@mui/material'

const BadgeCustom = ({ quantidade, children, sx, isImportant = false }) => {
      const StyledBadge = styled(Badge)(({ theme }) => ({
            '& .MuiBadge-badge': {
                  color: "white"
            },
      }));
      return (
            <StyledBadge badgeContent={quantidade} color={isImportant ? "error" : "primary"} sx={sx}>
                  {children ? children : ""}
            </StyledBadge>

      )
}

export default BadgeCustom;