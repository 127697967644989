import { Height } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Item: {
    background: '#f4fdff',
    borderRadius: '4px',
    border: '1px dashed #aaa',
    marginLeft: 18,

  },

  containerImg: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    marginTop: '.4rem',

    "& $img": {
      height: '100px',
      objectFit: 'cover',

    }
  }

}));

export default useStyles;
