import api from "../index";
import { configurationVariables } from "../../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getByIdCliente = async (idCliente) => {
  try {
    const { status, data: response } = await api.get(
      `/OrdemPagamento/GetByIDCliente?idCliente=${idCliente}&idHotsite=${idHotsite}`
    );
    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + " R34FT");
  }
};

export const getByIdOrdemPagamento = async (idOrdemPagamento, idCliente) => {
  try {

    const { status, data: response } = await api.get(
      `/OrdemPagamento/GetByID?idOrdemPagamento=${idOrdemPagamento}&idCliente=${idCliente}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {

    throw new Error(error + " R34FT");
  }
};
