import { useState, useEffect } from "react";
import { VitrineBase } from "../../VitrineBase";
import {
  getVitrineRecebimento,
  getValoresItemCarrinho,
} from "../../../../../api/Vitrine";
import { addItemRecebimento } from "../../../../../api/Carrinho/TranferenciaInternacional";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAuth } from "../../../../../hooks/AuthContext";
import useAlert from "../../../../../hooks/useAlert/index";
import { useNavigate } from "react-router-dom";
import {
  ButtonAcaoGrande,
  LoadingVitrine,
  Modal,
  ButtonVoltar,
} from "../../../..";
import {
  formatMoney,
  formatMoneyStringInDecimal,
  configInitialvalue,
  removePontoString,
} from "../../../../../util/helpers";
import useLojaFechada from "../../../../../hooks/useLojaFechada";
import usePedido from "../../../../../hooks/usePedido";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { useQuery } from "react-query";
import {
  GETVALORESITEMSRECEBIMENTO,
  VITRINERECEBIMENTO,
  ORDEMPAGAMENTOBYID,
} from "../../../../../util/typesReactQuery";
import { getByIdOrdemPagamento } from "../../../../../api/OrdemPagamento";

export default function Recebimento({ selectedOrdem, setSelectedOrdem }) {
  const { showAlertError, showAlertInfo } = useAlert();
  const { user } = useAuth();
  const [isVitrineError, setIsVitrineError] = useState(false);
  const [vitrineRecebimento, setVitrineRecebimento] = useState(null);
  const [itemVitrine, setItemVitrine] = useState(null);
  const [termoAceito, setTermoAceito] = useState(false);
  const [termoResponsabilidade, setTermoResponsabilidade] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [valorItemCarrinho, setValorItemCarrinho] = useState({});
  const [isErrorTermo, setIsErrorTermo] = useState(false);
  const [isErrorTermoRespon, setIsErrorTermoRespon] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const { itensPedido: listaDeItensNoCarrinho } = usePedido();
  const theme = useTheme();
  const [showModal, setModalShow] = useState(false);
  const [itemCarrinho, setItemCarrinho] = useState(null);
  const navigation = useNavigate();
  const [ordemPagamento, setOrdemPagamento] = useState(null);

  const { errors: errorsLojaFechada, lojaFechada } = useLojaFechada();

  useEffect(() => {
    if (errorsLojaFechada) {
      console.error("Primecase  Error - ", errorsLojaFechada);
      showAlertError(errorsLojaFechada);
      return;
    }
  }, [errorsLojaFechada]);

  useEffect(() => {

    if (vitrineRecebimento)
      init(vitrineRecebimento);

  }, [vitrineRecebimento]);

  const init = async (response) => {
    setLoadingButton(true);
    try {

      let itemSelecionado = response.itensVitrine.find(
        (item) => item.moeda.id === ordemPagamento.moeda.id
      );


      let valor = String(ordemPagamento?.valor);
      valor = removePontoString(valor);

      if (!valor)
        valor = "100,00";

      if (!valor.includes(","))
        valor += ",00";

      setItemVitrine((prev) => ({ ...itemSelecionado, valorMe: valor }));

    } catch (error) {

      showAlertError(error.message);

    } finally {

      setLoadingButton(false);

    }
  };

  const { isFetching: isLoadingVitrine, refetch: refreshOrdemPagamento } = useQuery(
    [ORDEMPAGAMENTOBYID],
    () => getByIdOrdemPagamento(selectedOrdem, user.id),
    {
      onSuccess: (data) => {
        if (!data) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não Foi encontrado nenhuma vitrine de Recebimento - R4A03`
          );
          return;
        }
        if (data.errorMessage) {
          showAlertError(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - R198C`
          );
          return;
        }

        setOrdemPagamento((prev) => ({ ...data.data }));
      },
      onError: (error) => {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - R9960`
        );

        setIsVitrineError(true);
      },
      enabled: false,
    }
  );

  useEffect(() => {
    if (!!selectedOrdem) {
      setLoadingButton(true);
      refreshOrdemPagamento();
    }
  }, []);

  useQuery([VITRINERECEBIMENTO], () => getVitrineRecebimento(user.id), {
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não Foi encontrado nenhuma vitrine de Recebimento - RISGZ`
        );
        return;
      }
      if (data.errorMessage) {
        showAlertError(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RQHCY`
        );

        setIsVitrineError(true);

        return;
      }
      setVitrineRecebimento((prev) => ({ ...data.data }));

    },
    onError: (error) => {
      showAlertError(
        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RC5ZW`
      );
    },

    onSettled: () => {
      setLoadingButton(false);
    },
    refetchOnWindowFocus: false,
  });

  const { isFetching: isLoadingImpostos, refetch: refetchImpostos } = useQuery([GETVALORESITEMSRECEBIMENTO],
    () =>
      getValoresItemCarrinho(
        vitrineRecebimento?.id || 0,
        itemVitrine?.id || 0,
        ordemPagamento?.idNatureza || 0,
      ),
    {
      onSuccess: (data) => {
        if (data?.errorMessage) {
          console.error(
            `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data?.errorMessage} - RPA5P`
          );
          return;
        }

        setValorItemCarrinho((prev) => ({ ...data?.data }));
      },
      onError: (error) => {
        console.error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RS4NJ`
        );

        setIsVitrineError(true);
      },
      onSettled: () => {
        setLoadingButton(false);
      },

      refetchOnWindowFocus: false,
      refetchInterval: 5000 * 60,
    }
  );

  useEffect(() => {
    if (itemVitrine?.id) {
      setLoadingButton(true);
      refetchImpostos();
    }
  }, [itemVitrine])

  useEffect(() => {
    if (!!Object.keys(valorItemCarrinho).length) {
      if (itemVitrine !== null) {
        let item = {
          idItemVitrine: itemVitrine?.id,
          valorMe: formatMoneyStringInDecimal(ordemPagamento?.valor ?? "0"),
          valorMn: 0,
          impostos: [],
          valorVet: 0,
          idFinalidade: ordemPagamento?.idNatureza,
          idVitrine: vitrineRecebimento?.id,
          idHotsite: user?.idHotsite ?? 0,
          tarifaAdministrativa: 0,
          moeda: itemVitrine?.moeda,
          indisponivel: itemVitrine?.indisponivel,
          indisponivelText: itemVitrine?.indisponivelText,
        };
        handlerCalculate(item);
      }
    }
  }, [valorItemCarrinho]);

  useEffect(() => {
    if (!!ordemPagamento?.id)
      setDisabledButton(false);
  }, [ordemPagamento]);

  const handlerCalculate = async (i) => {
    try {
      if (!valorItemCarrinho) {
        setLoadingButton(true);
        refetchImpostos();
        return;
      }

      let item = i;

      item.indisponivel = valorItemCarrinho?.indisponivel;
      item.indisponivelText = valorItemCarrinho?.indisponivelText;

      item.impostos = valorItemCarrinho?.impostos;
      item.idTaxa = valorItemCarrinho?.idTaxa;
      item.valorTaxaAplicada = formatMoney(
        valorItemCarrinho?.valorTaxa,
        item.moeda?.numeroCasaDecimais
      );
      item.tarifaAdministrativa = formatMoney(
        valorItemCarrinho?.tarifaAdministrativa,
        2
      );

      if (item.valorMe < valorItemCarrinho?.valorMinimo) {
        showAlertInfo(
          `Não é possível solicitar a quandidade de ${item.valorMe}, poís o mínimo permitido é ${valorItemCarrinho?.valorMinimo}`
        );

        item.valorMe = valorItemCarrinho?.valorMinimo;
      }

      const valorBaseMn = item.valorMe * item.valorTaxaAplicada;
      let valorMn = formatMoney(valorBaseMn, 2);

      if (item.impostos) {
        item.impostos.map((i) => {
          const valorImpostoBase = (valorBaseMn * i.aliquota) / 100;
          i.valor = formatMoney(valorImpostoBase, 2);
          const valorMnNovo = parseFloat(valorMn) - parseFloat(i.valor);
          valorMn = formatMoney(valorMnNovo, 2);
        });
      }
      valorMn = parseFloat(valorMn) - parseFloat(item.tarifaAdministrativa);

      item.valorMn = formatMoney(valorBaseMn, 2);
      item.valorVet = formatMoney(
        valorMn / item.valorMe,
        item.moeda?.numeroCasaDecimais
      );
      item.idCliente = user.id;
      item.valorTotalMN = formatMoney(valorMn, 2);
      item.idTipoItemCarrinho = 6;

      setItemCarrinho(item);

      if (item.indisponivel) throw new Error(item.indisponivelText);
    } catch (error) {
      showAlertError(error.message);
    }
  };

  const handlerAddCart = async (event) => {
    setLoadingButton(true);

    event.preventDefault();
    try {
      if (!termoAceito) {
        setIsErrorTermo(true);
        throw new Error("É necessário aceitar o termo de uso.");
      }

      if (!termoResponsabilidade) {
        setIsErrorTermoRespon(true);
        throw new Error("É necessário aceitar o termo de responsabilidade.");
      }

      if (itemCarrinho.indisponivel)
        throw new Error(itemCarrinho.indisponivelText);

      let customOrdemPagamento = {
        id: ordemPagamento?.id,
        codigoOrdem: ordemPagamento?.codigoOrdem,
      };

      if (lojaFechada && lojaFechada.isFechada) {
        navigation("/LojaFechada");
      } else {
        const response = await addItemRecebimento(
          itemCarrinho.idCliente,
          itemCarrinho,
          customOrdemPagamento,
          vitrineRecebimento.finalidades.find(
            (f) => f.id === itemCarrinho.idFinalidade
          )
        );

        if (response && !!response.errorMessage)
          throw new Error(response.errorMessage);

        if (response) {
          setTimeout(() => {
            navigation(
              "/carrinho/transferencia-internacional/recebimento/pagamento"
            );
          }, 2000);
        }
      }
    } catch (error) {
      showAlertError(error.message);
    } finally {
      setTimeout(() => {
        setLoadingButton(false);
      }, 2000);
    }
  };

  const handlerChangeFinalidade = (id) => {
    setItemCarrinho({ ...itemCarrinho, idfinalidade: id });
    setVitrineRecebimento({ ...vitrineRecebimento, idfinalidade: id });
  };

  const handlerChangeItem = (event) => {
    setItemVitrine((prev) => ({
      ...vitrineRecebimento.itensVitrine.find(
        (i) => i.id === event.target.value
      ),
    }));
    setValorItemCarrinho((prev) => null);
  };

  const handlerChangeValueMe = (event) => {
    let valor = event.target.value;
    setItemVitrine((prev) => ({ ...prev, valorMe: valor }));
  };

  const handlerBlurItemChange = (event) => {
    let valor = event.target.value;

    if (!!!valor) valor = "100,00";

    if (!valor.includes(",")) valor += ",00";

    setItemVitrine((prev) => ({ ...prev, valorMe: valor }));
  };

  const handleShowModal = (event) => {
    setModalShow(true);
  };

  const handlerModalOptions = (res) => (event) => {
    if (res === "sim") handlerAddCart(event, false);
    setModalShow(false);
  };

  const handlerSelectNovaOrdem = (event) => {
    setSelectedOrdem(0);
  };

  return (
    <>

      <Modal
        show={showModal}
        close={() => setModalShow(false)}
        sx={{ border: "1px solid #000" }}
      >
        <ErrorOutlineIcon
          color="primary"
          fontSize="large"
          sx={{ margin: "auto", width: "100%" }}
        />

        <Typography
          sx={{
            display: "flex",
            alignContent: "center",
            gap: ".3rem",
            textAlign: "center",
          }}
        >
          Você possui itens de outra vitrine no seu carrinho, tem certeza que
          deseja substitui-los?
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "space-around", mt: 1 }}>
          <ButtonAcaoGrande
            sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
            color="primary"
            texto="Sim"
            loading={loadingButton}
            Handle={handlerModalOptions("sim")}
          />
          <ButtonAcaoGrande
            sx={{ zIndex: theme.zIndex.mobileStepper, width: "40%" }}
            color="error"
            texto="não"
            loading={loadingButton}
            Handle={handlerModalOptions("não")}
          />
        </Box>
      </Modal>

      {(loadingButton) && (
        <LoadingVitrine />
      )}

      {!loadingButton &&
        vitrineRecebimento && (
          <>
            <ButtonVoltar mostraTexto handleVoltar={handlerSelectNovaOrdem}>
              Selecione outra ordem
            </ButtonVoltar>

            <Box sx={{}}>
              {itemVitrine !== null &&
                itemCarrinho !== null &&
                itemCarrinho.valorTaxaAplicada > 0 && (
                  <VitrineBase
                    isRecebimento={true}
                    vitrine={vitrineRecebimento}
                    onChangeItem={handlerChangeItem}
                    onAddCart={
                      listaDeItensNoCarrinho?.itens?.length > 0 &&
                        listaDeItensNoCarrinho?.itens?.every(
                          (i) => i.idVitrine !== itemCarrinho.idVitrine
                        )
                        ? handleShowModal
                        : handlerAddCart
                    }
                    itemSelected={itemVitrine}
                    itemCarrinho={itemCarrinho}
                    onChangeFinalidade={handlerChangeFinalidade}
                    onChangeValorMe={handlerChangeValueMe}
                    onChangeTermo={setTermoAceito}
                    onChangeTermoResponsabilidade={setTermoResponsabilidade}
                    isTermoResponsabilidadeAceite={termoResponsabilidade}
                    isTermoUsoAceite={termoAceito}
                    onBlurValueChangeItem={handlerBlurItemChange}
                    isErrorTermo={isErrorTermo}
                    isErrorTermoResponsabilidade={isErrorTermoRespon}
                    loadingAddcart={loadingButton}
                    disabledButton={disabledButton}
                  />
                )}
            </Box>
          </>
        )}
      {isVitrineError && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "80vh", marginTop: "-1rem", background: "#fcfcfc" }}
        >
          <Grid item>
            <Card>
              <CardHeader subheader="❌ Comunicado importante" />
              <CardContent>
                <Typography variant="body1">
                  A vitrine não esta disponivel para operações no momento.
                  <br />
                  Para mais detalhes, entre em contato conosco.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
}
