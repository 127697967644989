const TipoPanels = {
      OptionsEditions: 0,
      editDados: 1,
      editEnderecos: 2,
      editDocumentos: 3,
      editDadosBancarios: 4,
      editMinhaConta: 5,
      pendencias: 6
};

export default TipoPanels;