import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import useStyles from "./styles";

export default function CampoDocumentoIdentificacao({
  name,
  value,
  handlerChange,
  size,
  idTipoDocumento,
}) {
  const [tipoDocumento, setTipoDocumento] = useState("");
  const [maskDefault, setMaskDefault] = useState("");
  const [isError, setIsError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (idTipoDocumento === 1) {
      setMaskDefault("999.999.999-99");
      setTipoDocumento("CPF");
    } else if (idTipoDocumento === 2) {
      setMaskDefault("99.999.999/9999-99");
      setTipoDocumento("CNPJ");
    } else {
      setMaskDefault("");
      setTipoDocumento("Passaporte");
    }
  }, [idTipoDocumento]);

  useEffect(() => {
    const valueOrigin = value.replace(/\D+/g, "");
    if (idTipoDocumento === 1) {
      setIsError(valueOrigin.length !== 11);
      setIsError(valueOrigin.length > 0);
    } else if (idTipoDocumento === 2) {
      setIsError(valueOrigin.length !== 14);
      setIsError(valueOrigin.length > 0);
    } else {
      setIsError(valueOrigin.length < 5);
      setIsError(valueOrigin.length > 0);
    }
  }, [idTipoDocumento, value]);

  return (
    <InputMask
      onChange={handlerChange}
      mask={maskDefault}
      value={value}
      disabled={false}
      maskChar="_"
    >
      {() => (
        <TextField
          spellCheck="false"
          
          required
          fullWidth
        
          size={size}
          id="documentoIdentificacao"
          label={`Digite o ${tipoDocumento}`}
          name={name}
          value={value}
          autoFocus
          onChange={handlerChange}
          //helperText={isError ? `Número do ${tipoDocumento} inválido!` : ""}
          error={isError}
          className={classes.root}
        />
      )}
    </InputMask>
  );
}
