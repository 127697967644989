import VitrineChip from "../../components/Vitrine/ChipInternaciona";


function ChipInternacional() {
      return (
            <VitrineChip />
      );
}

export default ChipInternacional;
