import api from "../../api";

//?=================================== notificacao ================================

export const getNotificacaoCliente = async (idCliente) => {
    try {
        if (!idCliente) throw new Error("Cliente não encontrado - ROFY7");
        const { data: dataResponse } = await api.get(
            `notificacaoCliente/GetAll?idCliente=${idCliente}`
        );
        return {

            errorMessage: dataResponse.errorMessage,
            data: dataResponse.data,
        }
    } catch (error) {
        throw new Error(error + "RE5MG");
    }
};

export const getNotificacoesNaoLidasCliente = async (idCliente, pagina) => {
    try {
        if (!idCliente) throw new Error("Cliente não encontrado - RO1S7");
        const { data: dataResponse } = await api.get(
            `notificacaoCliente/GetNaoLidas?idCliente=${idCliente}&pagina=${pagina}`
        );
        return {

            errorMessage: dataResponse.errorMessage,
            data: dataResponse.data.filter((not) => not.titulo !== null),
        }
    } catch (error) {
        throw new Error(error + " RE5NH");
    }
};

export const getNotificacoesLidasCliente = async (idCliente, pagina) => {
    try {
        if (!idCliente) throw new Error("Cliente não encontrado - RO1S7");
        const { data: dataResponse } = await api.get(
            `notificacaoCliente/GetLidas?idCliente=${idCliente}&pagina=${pagina}`
        );


        return {

            errorMessage: dataResponse.errorMessage,
            data: dataResponse.data.filter((not) => not.titulo !== null),
        }
    } catch (error) {
        throw new Error(error + " RE5MG");
    }
};

export const setNotificacaoClientelido = async (
    idCliente,
    idNotificacao,
) => {
    try {
        if (!idCliente) throw new Error("Cliente não encontrado - RURI7");
        if (!idNotificacao) throw new Error("notificação não encontrada - RKWQV");

        const { data: dataResponse } = await api.post(
            `/notificacaoCliente/MarcaLida`,
            null,
            {
                params: {
                    idCliente: idCliente,
                    idNotificacaoCliente: idNotificacao,
                },
            }
        );

        return {

            errorMessage: dataResponse.errorMessage,
            data: dataResponse.data,
        }
    } catch (error) {
        throw new Error(error + " RNEMU");
    }

};

export const setMarcartodasComoLida = async (
    idCliente,
) => {
    try {
        if (!idCliente) throw new Error("Cliente não encontrado - RS45E");

        const { data: dataResponse } = await api.post(
            `/notificacaoCliente/MarcaTodasLidas`,
            null,
            {
                params: {
                    idCliente: idCliente,
                },
            }
        );

        return {

            errorMessage: dataResponse.errorMessage,
            data: dataResponse.data,
        }

    } catch (error) {
        throw new Error(error + " RTUIK");
    }

};