import { makeStyles } from "@mui/styles";

const style = makeStyles((theme) => ({
  IconButton: {
    color: theme.palette.error.main + " !important",
    opacity: 1,
    "&:hover": {
      opacity: 1,
    },
  },
}));

export default style;
