import { useState, Fragment } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Box from "@mui/material/Box";
import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
  Skeleton,
} from "@mui/material";
import Titulo from "../Textos/Titulo";
import Subtitulo from "../Textos/Subtitulo";
import parseHtml from "html-react-parser";
import { getPontosRetiradaByIDPraca } from "../../api/Carrinho/Turismo";
import { useQuery } from "react-query";
import { PONTOSRETIRADA } from "../../util/typesReactQuery";
import useStyles from "./styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CheckIcon from "@mui/icons-material/Check";

export default function PontoRetirada({ selectedID, setSelectedID, idPraca }) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    data: pontosRetirada,
    isLoading: isLoadingPontosRetirada,
    refetch: refetchPontosRetirada,
  } = useQuery(PONTOSRETIRADA, () => getPontosRetiradaByIDPraca(idPraca), {
    refetchOnWindowFocus: false,
    enabled: true,
  });

  return (
    <>
      <Grid
        className={classes.blocoPedidos}
        sx={{ display: "block", justifyContent: "center" }}
        item
        xs={12}
      >
        <Grid className={classes.PageInicial}>
          {!isLoadingPontosRetirada ? (
            <>
              <Titulo sx={{ mb: 1, mt: 4 }}>Retirar em loja</Titulo>
              <Subtitulo sx={{ mb: 1 }}>
                Selecione a loja que deseja retirar seu pedido
              </Subtitulo>
            </>
          ) : (
            <>
              <Skeleton width="100%" height={50} animation="wave" />
              <Skeleton width="100%" height={50} animation="wave" />
            </>
          )}

          <List
            dense
            sx={{
              width: "100%",
              maxWidth: "100%",
              bgcolor: "background.paper",
              maxHeight: "50vh",
              overflow: "auto",
              position: "relative",
            }}
          >
            {!isLoadingPontosRetirada ? (
              pontosRetirada &&
              pontosRetirada.map((ponto, index) => {
                return (
                  ponto.id > 0 && (
                    <ListItem
                      className={
                        selectedID == ponto.id
                          ? `${classes.PontoRetirada} ${classes.checked}`
                          : `${classes.PontoRetirada}`
                      }
                      sx={{ padding: 0 }}
                      key={index}
                    >
                      <ListItemButton
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                          justifyContent: "center",
                        }}
                        onClick={setSelectedID(ponto)}
                      >
                        <ListItem sx={{ width: "100%", justifySelf: "center" }}>
                          <ListItemText
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: "center",
                              fontWeight: "bold",
                              alignItems: "center",
                            }}
                            multiline
                            id={ponto.id}
                            primary={
                              <LocationOnIcon
                                fontSize="inherit"
                                sx={{
                                  mr: 1,
                                  color: theme.palette.secondary.main,
                                }}
                              />
                            }
                            secondary={
                              <Typography
                                sx={{ fontWeight: "bold", fontSize: 15 }}
                              >
                                {ponto.nome}
                              </Typography>
                            }
                            style={{}}
                          />
                        </ListItem>

                        <ListItem>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              fontWeight: "bold",
                            }}
                          >
                            <Typography sx={{ textAlign: "center" }}>
                              {" "}
                              {parseHtml(ponto.descricao || "")}
                            </Typography>
                          </Box>

                          {selectedID == ponto.id && (
                            <CheckIcon
                              sx={{
                                color: theme.palette.primary.main,
                                fontSize: 20,
                                marginLeft: 0,
                              }}
                            />
                          )}
                        </ListItem>
                      </ListItemButton>
                    </ListItem>
                  )
                );
              })
            ) : (
              <>
                <Skeleton width="100%" height={100} animation="wave" />
              </>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  );
}
