import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  Container: {
    margin: "auto",
    padding: 10,
    background: "#fcfcfc",
  },
  Item: {
    padding: 20,
    border: "1px solid #ddd",
    background: "#fff",
    borderRadius: 10,
    margin: "0",
    display: "flex",
    justifyContent: "space-between ",
    "&:hover": {
      background: "#fcfcfc",
    },
    [theme.breakpoints.up('sm')]:{
      padding: 20,
      border: "1px solid #ddd",
      background: "#fff",
      borderRadius: 10,
      margin: "auto",
      display: "flex",
      justifyContent: "space-between ",
      "&:hover": {
        background: "#fcfcfc",
      },
    }
  },
  Check: {
    fontSize: "24px !important",
    paddingTop: "10px !important",
    marginRight: "2px !important",
  },
  Titulo: {
    display: "flex",
    marginBottom: "20px",
    alignItems: "center",
  },
  Modal: {
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "750px !important",
    },
  },
  Bloco: {
    borderLeft: "none",
    width: "100%",
    marginTop: 21,
    borderTop: "1px solid #ccc",
    [theme.breakpoints.up("sm")]: {
      borderLeft: "1px solid #ccc",
      paddingLeft: 40,
      borderTop: "none",
      marginTop: 0,
      width: "auto",
    },
  },
  ValorFinal: {
    borderLeft: "none",
    marginTop: 12,
    textAlign: "left",
    paddingTop: 12,
    [theme.breakpoints.up("sm")]: {
      textAlign: "end",
      marginTop: 0,
      paddingTop: 0,

      borderTop: "none",
    },
  },
  Valor: {
    cursor: "pointer",
    fontWeight: "bold",
    ml: 1,
    display: "flex",
    fontSize: 24,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "end",
      mb: 3,
    },
  },
  span: {
    textDecoration: "underline",
    marginTop: "15px !important",
    [theme.breakpoints.up("sm")]: {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  ButtonAcaoGrande: {
    maxWidth: "100%",
    marginLeft: "auto",
    [theme.breakpoints.up("sm")]: {
      cursor: "pointer",
      maxWidth: "75%",
      textDecoration: "underline",
    },
  },
  Grid: {
    margin: "0 !important",
    display: "flex",
    justifyContent: "space-between ",
    mb: 2,
    mt: 5,
    [theme.breakpoints.up("sm")]: {
      margin: "auto !important",
      display: "flex",
      justifyContent: "space-between ",
      mb: 2,
      marginTop: "30PX !important",
    },
  },
  img: {
    width: "23%",
    borderRadius: 8,
    margin: "0",
   marginBottom:15,
    [theme.breakpoints.up("sm")]: {
      width: "80%",
      borderRadius: 8,
      margin: "auto",
    },
  },
  text:{
    margin:'0',
    display:'none',
    [theme.breakpoints.up("sm")]:{
      marginLeft:'15px !important',
      display:'block',
    }
  }
}));
