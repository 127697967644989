import { useNavigate } from "react-router-dom";
import CarrinhoPage from "../../../components/CarrinhoPage";
import OpcoesPagamento from "../../../components/OpcoesPagamento";
import { useAuth } from "../../../hooks/AuthContext";

import { Skeleton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { addPagamento } from "../../../api/Carrinho/ChipInternacional";
import useAlert from "../../../hooks/useAlert";
import useLojaFechada from "../../../hooks/useLojaFechada";
import { useSeguro } from "../../../store/seguroViagem";
import { getlocalStorageTimerCarrinho } from "../../../util/LocalStorage";
import TiposPedido from "../../../util/typesTipoPedido";

export function Pagamento() {
  const idTipoPedido = 5; //  RemessaEnvio = 18
  const navigation = useNavigate();
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert();
  const [loadingAddPagamento, setLoadingAddPagamento] = useState(false);
  const { errors: errorLojaCambio, lojaFechada } = useLojaFechada();
  const [dataLocal, setDataLocal] = useState(() =>
    getlocalStorageTimerCarrinho()
  );
  const { updateSelectFormaPagamento } = useSeguro();

  const steps = [
    {
      label: "Vitrine",
      description: "",
      link: "/ChipInternacional",
    },

    {
      label: "Forma de pagamento",
      description: ``,
      link: "/ChipInternacional/Pagamento",
    },
    {
      label: "Finalizar",
      description: ``,
      link: "/ChipInternacional/Resumo",
    },
  ];

  useEffect(() => {
    if (errorLojaCambio) {
      console.error("Primecase Error - ", errorLojaCambio);
      showAlertError(errorLojaCambio);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaCambio]);

  const handlerNext = async (event, pagamento) => {
    event.preventDefault();
    try {
      setLoadingAddPagamento(true);
      const response = await addPagamento(user.id, pagamento);

      if (!!response?.errorMessage)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${response.errorMessage} - R8UV7`
        );

      if (!!!response)
        throw new Error(
          `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico:não foi possivel registrar a forma de pagamento selecionada- R55HV`
        );

      updateSelectFormaPagamento(pagamento);

      if (response && !response.errorMessage) {
        setTimeout(() => {
          navigation("/ChipInternacional/Resumo");
        }, 2000);
      }
      setTimeout(() => {
        setLoadingAddPagamento(false);
      }, 2100);
    } catch (error) {
      setLoadingAddPagamento(false);
      showAlertError(`${error} - RKLFJ`);
    }
  };

  const handleBack = () => {
    navigation("/ChipInternacional");
  };

  return (
    <>
      <CarrinhoPage
        handleBack={handleBack}
        disableButtonNext={true}
        activeStep={1}
        steps={steps}
      >
        {/*<BreadCrumbsPaginas list={breadCrumbs} />*/}
        {loadingAddPagamento ? (
          <Skeleton animation="wave" height={50} width={"100%"} />
        ) : (
          <Typography style={{ fontSize: "17pt", marginBottom: "20pt" }}>
            Forma de pagamento
          </Typography>
        )}

        <OpcoesPagamento
          idTipoPedido={TiposPedido.VendaChipInternacional}
          onNext={handlerNext}
          loadingAddPagamento={loadingAddPagamento}
        />
      </CarrinhoPage>
    </>
  );
}
