import React, { useState } from "react";
import MenuDashboard, { Paginas } from "../../../../components/MenuDashboard";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getAllSaldo } from "../../../../redux/actions/saldo.action";
import { useAuth } from "../../../../hooks/AuthContext";
import { useDispatch } from "react-redux";
import { getTipoOperacaoSaldo } from "../../../../api/chamadasApi";
import Alert from "../../../../components/Snack/Snack";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import AdicaoReais from "../../../../components/OperacaoSaldo/AdicaoReais";
import SaqueSaldo from "../../../../components/OperacaoSaldo/SaqueSaldo";
import IconButton from "@mui/material/IconButton";
import { useQuery } from "react-query";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import OperarAtivos from "../../../../components/OperarAtivos";
import SearchIcon from "@mui/icons-material/Search";
import ButtonAcaoGrande from "../../../../components/Botoes/ButtonAcaoGrande";
import FormMoedaCustom from "../../../../components/Forms/FormMoedas";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import imageCripto from "../../../../assets/images/Criptomoedas/bitcoin.png";
import useStyles from "./styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "8px",
  p: 4,
};

const currencies = [
  {
    value: "BTC",
    image: <imageCripto />,
    label: "BTC",
  },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export function Ativos() {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const queryKey = ["@CLIENT_SALDO"];
  const { data: dataTipoOperacao } = useQuery(["teste"], getTipoOperacaoSaldo, {
    staleTime: 1000 * 300,
  });

  useQuery(queryKey, () => dispatch(getAllSaldo(user.id)));

  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState("teste");
  const [tipoAlert, setTipoAlert] = useState("success");

  const [operacaoDesejada] = useState(null);

  const [value, setValue] = React.useState(0);
  const [currency, setCurrency] = React.useState("EUR");

  const Change = (event) => {
    setCurrency(event.target.value);
  };

  const handleCurrency = (event) => {
    setCurrency(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  return (
    <>
      <Alert
        severity={tipoAlert}
        text={alertText}
        show={showAlert}
        close={() => setShowAlert(!showAlert)}
      />
      <MenuDashboard pagina={Paginas.Cripto}>
        <Grid
          className={classes.BlocoCripto}
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={6} className={classes.Saldo}>
            <Box className={classes.Box}>
              {" "}
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "50%",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu"></IconButton>
                <InputBase placeholder="Pesquisar" />
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>

                <IconButton
                  color="primary"
                  sx={{ p: "10px" }}
                  aria-label="directions"
                ></IconButton>
              </Paper>
              <Grid>
                <Button
                  className={classes.Button}
                  size="small"
                  disabled
                  variant="contained"
                >
                  Meus ativos
                </Button>
                <Link className={classes.Link} to="/Criptomoeda/ComprarCripto">
                  <Button
                    className={classes.Button}
                    size="small"
                    variant="outlined"
                  >
                    Ver Preços
                  </Button>
                </Link>
              </Grid>
            </Box>
            <OperarAtivos sx={{ mt: 15 }} />
          </Grid>
          <Grid item xs={5} className={classes.Saldo}>
            <Box
              style={{ display: "flex", borderRight: "1px solid #ccc" }}
            ></Box>

            <Tabs
              style={{ width: "100%" }}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                style={{ width: "50%" }}
                label="Converter"
                {...a11yProps(0)}
              />
              <Tab style={{ width: "50%" }} label="Vender" {...a11yProps(1)} />
            </Tabs>

            <TabPanel value={value} index={0}>
              <Box style={{ display: "flex" }}>
                <Typography
                  sx={{ mb: 3, mr: 2 }}
                  color="text.secondary"
                  variant="h6"
                >
                  Converter
                </Typography>
              </Box>
              <Typography style={{ fontWeight: "500" }} color="primary">
                De
              </Typography>
              <TextField
                spellCheck="false"
                id="outlined-select-currency"
                style={{ width: "35%" }}
                value={currency}
                onChange={handleCurrency}
                helperText=""
              >
                {currencies.map((option, index) => (
                  <MenuItem
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                    value={option.value}
                  >
                    <Box sx={{ mt: 30 }} width="15" src={imageCripto} />
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                spellCheck="false"
                style={{ width: "63%" }}
                id="outlined-required"
                defaultValue="0,0000000"
              />
              <Typography
                style={{ fontWeight: "500", marginBottom: 35 }}
                color="text.secondary"
              >
                Disponível: 0,00499625 BNB
              </Typography>
              <Typography style={{ fontWeight: "500" }} color="primary">
                Para
              </Typography>
              <TextField
                spellCheck="false"
                id="outlined-select-currency"
                select
                style={{ width: "35%" }}
                value={currency}
                onChange={Change}
                helperText=""
              >
                {currencies.map((option, index) => (
                  <MenuItem
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                    value={option.value}
                  >
                    <Box sx={{ mt: 30 }} width="15" src={imageCripto} />
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                spellCheck="false"
                style={{ width: "63%" }}
                id="outlined-required"
                defaultValue="0,0000000"
              />

              <ButtonAcaoGrande texto="Converter para BNB" />
            </TabPanel>
            <TabPanel style={{ width: "100%" }} value={value} index={1}>
              <Box style={{ display: "flex" }}>
                <Typography
                  sx={{ mb: 3, mr: 2 }}
                  color="text.secondary"
                  variant="h6"
                >
                  Vender por R$
                </Typography>
              </Box>
              <Typography style={{ fontWeight: "500" }} color="primary">
                Simulação
              </Typography>
              <FormMoedaCustom size="medium" prefix={"R$"} placeholder="R$" />
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  variant="h6"
                  color="error"
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: "flex",
                    paddingRight: 5,
                  }}
                >
                  ≈
                </Typography>

                <Typography
                  variant="h6"
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: "flex",
                    paddingRight: 5,
                  }}
                >
                  <span style={{ color: "#aaa", marginRight: 2 }}>R$</span>{" "}
                  0,00000000
                </Typography>
              </Box>

              <ButtonAcaoGrande texto="Vender por R$" />
            </TabPanel>
          </Grid>

          {/*<Grid item xs={8} sx={{mt:30}} >

            <TabPanel value={value} index={0}>

              <Ativos />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CompraCripto></CompraCripto>
            </TabPanel>



          </Grid>*/}
        </Grid>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.CompleteModal} sx={style}>
            {operacaoDesejada === "ADD" && (
              <AdicaoReais
                tipoOperacaoSaldo={
                  dataTipoOperacao?.find((tipo) => tipo.id === 1) ?? 1
                }
                queryKey={queryKey}
                modalControl={setOpen}
                handlerShowAlert={setShowAlert}
                handlerTipoAlert={setTipoAlert}
                handlerTextAlert={setAlertText}
              />
            )}
            {operacaoDesejada === "RET" && (
              <SaqueSaldo
                tipoOperacaoSaldo={
                  dataTipoOperacao?.find((tipo) => tipo.id === 2) ?? 1
                }
                queryKey={queryKey}
                modalControl={setOpen}
                handlerShowAlert={setShowAlert}
                handlerTipoAlert={setTipoAlert}
                handlerTextAlert={setAlertText}
              />
            )}
          </Box>
        </Modal>
      </MenuDashboard>
    </>
  );
}
