import { useState, useEffect } from "react";

import Seletor from "../../../Seletor";
import Grid from "@mui/material/Grid";
import { Skeleton } from "@mui/material";

export default function Indicações({ dadosForm, setForm, setData, isLoading }) {
  const handlerChangeSelect = (value) => {
    setForm((previewData) => {
      const newCliente = { ...previewData };
      newCliente.idIndicacao = value;
      return newCliente;
    });

    setData((previewData) => {
      const newCliente = { ...previewData };
      newCliente.idIndicacao = value;
      return newCliente;
    });
  };

  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {dadosForm.enableIndicacoes &&
          (isLoading ? (
            <Grid item sx={{ pr: 0 }} xs={6} md={6}>
              <Skeleton animation="wave" height={60} width={"100%"} />
            </Grid>
          ) : (
            <Grid item xs={12} md={12}>
              <Seletor
                sx={{ width: "100%" }}
                setSelect={(e) => handlerChangeSelect(e)}
                list={dadosForm.indicacoes}
                size="medium"
                fullWidth={true}
                variantTextField="outlined"
                label="Selecione seu operador*"
                value={dadosForm.idIndicacao}
              />
            </Grid>
          ))}
      </Grid>
    </>
  );
}
