export function localStorageGetItem() {
  return localStorage.getItem("@ecommercePRIMECASE") || "{}";
}

export function getlocalStorageTimerCarrinho() {
  return localStorage.getItem("@TimerCarrinho") || null;
}

export function saveLocalStorageTimerCarrinho(timer) {
  localStorage.setItem("@TimerCarrinho", timer.toString())
}

export function clearLocalStorageTimerCarrinho() {
  localStorage.removeItem("@TimerCarrinho");
}

export function saveLocalStorageCarrinhoTemp(data) {
  localStorage.setItem("@CarrinhoTemp", JSON.stringify(data))
}
export function clearLocalStorageCarrinhoTemp() {
  localStorage.removeItem("@CarrinhoTemp");
}

export function getlocalStorageCarrinhoTemp() {
  return localStorage.getItem("@CarrinhoTemp") || null;
}

export function getlocalStorageIdHotsite() {
  return parseInt(localStorage.getItem("@idHotsiteLink")) || 0;
}

export function saveLocalStorageIdHotsite(idHotsite) {
  localStorage.setItem("@idHotsiteLink", idHotsite)
}

export function clearLocalStorageIdHotsite() {
  localStorage.removeItem("@idHotsiteLink");
}