import React, { useState, useEffect, useRef } from "react";
import Seletor from "../Seletor";
import { PedidoCambio } from "../PedidoCambio";
import PedidoOuro from "../PedidoOuro";
import {
  Grid,
  Typography,
  Skeleton,
  Box,
  FormControl,
  Alert,
  AlertTitle,
} from "@mui/material";
import List from "@mui/material/List";
import { useQuery, useInfiniteQuery } from "react-query";
import Button from "@mui/material/Button";
import { getTiposMercado } from "../../api/chamadasApi";

import {
  getPedidosCambioByIDCliente,
  getPedidosOuroByIDCliente,
} from "../../api/Cambio";
import { getPedidoByIDCliente } from "../../api/Carrinho/ChipInternacional";

import { getPedidosSeguroViagemByIDCliente } from "../../api/PedidoSeguroViagem";

import { useAuth } from "../../hooks/AuthContext";
import { TIPOSMERCADO, MEUSPEDIDOSCAMBIO } from "../../util/typesReactQuery";
import TiposMercado from "../../util/typesMercado";
import useStyles from "./styles";
import TransacoesImage from "../../assets/images/money-transfer.png";
import useAlert from "../../hooks/useAlert";
import { useTheme } from "@mui/material";
import { PedidoSeguroViagem } from "../PedidoSeguroViagem";
import AlertCustomFixed from "../AlertCustomFixed";

export default function Pedidos({
  handlerShowModal,
  isFilter = false,
  isHome = false,
}) {
  const { user } = useAuth();
  const classes = useStyles();
  const { showAlertError } = useAlert();
  const ref = useRef(null);
  const [selectedIDMercado, setSelectedIDMercado] = useState(1);
  const theme = useTheme();
  const [pedidos, setPedidos] = useState();
  const [isCarregaButton, setIsCarregaButton] = useState(true);
  const [tiposMercado, setTiposMercado] = useState([]);

  const handlerChangeTipoMercado = (value) => {
    setPedidos(undefined);
    setSelectedIDMercado(value);
  };

  const scrollToBottom = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const {
    // isLoading: isLoadingPedidos,
    refetch: refetchPedidos,
    isFetching: isLoadingPedidos,
    // data: pedidos,
    isSuccess,
    error,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    MEUSPEDIDOSCAMBIO,
    ({ pageParam = 1 }) => {
      switch (selectedIDMercado) {
        case TiposMercado.Cambio:
          return getPedidosCambioByIDCliente(user.id, pageParam);

        case TiposMercado.Ouro:
          return getPedidosOuroByIDCliente(user.id, pageParam);

        case TiposMercado.SeguroViagem:
          return getPedidosSeguroViagemByIDCliente(user.id, pageParam);

        case TiposMercado.ChipInternacional:
          return getPedidoByIDCliente(user.id, pageParam);

        default:
          return null;
      }
    },
    {
      onSuccess: (data) => {
        setPedidos(data);
      },
      getNextPageParam: (lastPage, allPages, data) => {
        if (lastPage.length === 5) {
          return allPages.length + 1;
        }
        return undefined;
      },
      onError: (error) => {
        console.error("error", error);
        showAlertError(
          `Ops, algo deu errado: ${error.message ?? error} - RC8CD`
        );
      },
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading: isLoadingTiposMercado } = useQuery(
    TIPOSMERCADO,
    () => getTiposMercado(),
    {
      onSuccess: (data) => {
        if (!data?.data && data?.data.length === 0) {
          showAlertError("Ops, não foi possivel buscar nenhum mercado - R362U");
          console.error(
            "Não consta nenhum mercado ativo para implementar o filtro - R362U"
          );
        }

        setTiposMercado((prev) => data?.data);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    refetchPedidos();
  }, [selectedIDMercado]);

  return (
    <>
      <Grid container className={classes.blocoPedidos} >
        <Grid className={classes.PageInicial} item xs={12}>
          {!isLoadingTiposMercado && !isLoadingPedidos ? (
            tiposMercado.length > 0 ? (
              <Box className={classes.Filter}>
                <Typography sx={{ textAlign: "end", mr: 2 }}>
                  Filtrar por:
                </Typography>
                <FormControl
                  className={classes.FormControl}
                  size="small"
                  sx={{ mr: 1, width: "13em" }}
                  fullWidth
                >
                  {tiposMercado && (
                    <Seletor
                      // setSelect={setSelectedIDMercado}
                      addEmpty={false}
                      list={tiposMercado}
                      // list={TestTipo}
                      setSelect={(e) => handlerChangeTipoMercado(e)}
                      size="small"
                      label="Tipo de operação"
                      value={selectedIDMercado}
                      className={classes.Seletor}
                    />
                  )}
                </FormControl> 
              </Box>
            ) : (
              <Box>
                <AlertCustomFixed type="info" isHabilitaButton={false}>
                 Não foi encontrado nenhum tipo de operação
                </AlertCustomFixed>
              </Box>
            )
          ) : (
            <FormControl
              className={classes.FormControl}
              size="small"
              sx={{ mr: 1, width: "13em" }}
              fullWidth
            >
              <Skeleton height={60} animation="wave" />
            </FormControl>
          )}

          {selectedIDMercado === 0 && (
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                textAlign: "center",
                marginTop: 5,
              }}
            >
              <>
                <Box component="img" sx={{ width: 65 }} src={TransacoesImage} />

                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ textAlign: "center", margin: "auto", m: 2 }}
                >
                  Aqui você encontrará suas operações<br></br> realizadas em
                  nossa plataforma.
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="primary"
                  sx={{ textAlign: "center", margin: "auto" }}
                >
                  *Filtre de acordo com a operação que deseja visualizar.
                </Typography>
              </>
            </List>
          )}
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              textAlign: "center",
            }}
          >
            <>
              {typeof pedidos === "undefined" ||
                (pedidos.pages.length <= 0 && (
                  <>
                    <Box
                      component="img"
                      sx={{ width: 65 }}
                      src={TransacoesImage}
                    ></Box>

                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ textAlign: "center", margin: "auto", m: 3 }}
                    >
                      Aqui você encontrará suas operações de{" "}
                      {selectedIDMercado === TiposMercado.Cambio
                        ? "Câmbio"
                        : selectedIDMercado === TiposMercado.Ouro
                          ? "Ouro"
                          : "Seguro de Viagem"}
                      <br></br> realizadas em nossa plataforma.
                    </Typography>
                  </>
                ))}

              {isLoadingPedidos &&
                [...Array(3)].map((_, index) => (
                  <Skeleton
                    height={180}
                    key={index}
                    animation="wave"
                    sx={{ mb: -6, mt: -5 }}
                  />
                ))}

              {!isLoadingPedidos &&
                pedidos?.pages?.length > 0 &&
                tiposMercado?.length > 0 && (
                  <>
                    <PedidoCambio
                      isShowButtonMore={setIsCarregaButton}
                      isLoadingPedidos={isLoadingPedidos}
                      pedidos={[...pedidos?.pages]}
                      habilitaVisualizarDetalhes={isFilter}
                      tipoMercado={selectedIDMercado}
                    ></PedidoCambio>
                  </>
                )}

              {isFilter &&
                !isHome &&
                selectedIDMercado > 0 &&
                tiposMercado?.length > 0 &&
                pedidos !== undefined &&
                hasNextPage &&
                pedidos.pages[0].length > 0 &&
                isCarregaButton &&
                (!isFetchingNextPage ? (
                  <>
                    <Grid item sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        size="small"
                        onClick={fetchNextPage}
                        variant="contained"
                        sx={{
                          mt: 2,
                          mb: 4,
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        Carregar mais +
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Skeleton
                    width="100%"
                    height={60}
                    animation="wave"
                    sx={{ mr: 2 }}
                  />
                ))}

              {!!pedidos && pedidos.pages[0].length <= 0 && (
                <Alert severity="info" sx={{ mt: theme.spacing(3) }}>
                  {selectedIDMercado === TiposMercado.Cambio && (
                    <>Não consta nenhum pedido de câmbio realizado em sistema</>
                  )}

                  {selectedIDMercado === TiposMercado.Ouro && (
                    <>Não consta nenhum pedido de ouro realizado em sistema</>
                  )}

                  {selectedIDMercado === TiposMercado.Cripto && (
                    <>Não consta nenhum pedido de cripto realizado em sistema</>
                  )}
                  {selectedIDMercado === TiposMercado.SeguroViagem && (
                    <>
                      Não consta nenhum pedido de Seguro de viagem realizado em
                      sistema
                    </>
                  )}
                  {selectedIDMercado === TiposMercado.ChipInternacional && (
                    <>
                      Não consta nenhum pedido de Chip internacional realizado em
                      sistema
                    </>
                  )}
                </Alert>
              )}
            </>
          </List>

          <div ref={ref} />
        </Grid>
      </Grid>
    </>
  );
}
