
import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid'

const CustomGrid = ({ children, index, array, md }) => {
    const [tamanhoCamposMobile, setTamanhoCamposMobile] = useState(6);


    useEffect(() => {

        if (index != (array.length - 1)) {
            setTamanhoCamposMobile(6)
            return;
        }

        if (index % 2 == 0)
            setTamanhoCamposMobile(12)

    }, [])

    return <>
        <Grid  item xs={tamanhoCamposMobile} md={md}>
            {children}
        </Grid>

    </>

}

export default CustomGrid;