import { useAuth } from "../../../../../hooks/AuthContext";
import { PedidoConcluidoPagamentoEscolhido, MenuDashboard, PaginasMenuDashboard, LoadingPedidoRealizadoPagamentoEscolhido } from '../../../../../components'
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { GetPedidoByID } from '../../../../../api/PedidoComercial';
import useAlert from "../../../../../hooks/useAlert";
import { PEDIDOCONCLUIDOPAGAMENTOESCOLHIDO } from '../../../../../util/typesReactQuery'
import { Grid } from "@mui/material";
import { useEffect } from "react";
import useSeoConfig from "../../../../../hooks/useSeoConfig";

export default function ConfirmacaoEnvio() {



  const { user } = useAuth();
  const { showAlertError } = useAlert();
  const { idPedido } = useParams()
  const { sendPageAtual } = useSeoConfig();

  useEffect(() => {
    sendPageAtual("Pedido_efetuado_envio")
  }, []);

  const { data: Pedido } = useQuery([`${PEDIDOCONCLUIDOPAGAMENTOESCOLHIDO}${idPedido}`], () => GetPedidoByID(user.id, idPedido), {
    onSuccess: (data) => {
      if (!data || !data?.data) {
        showAlertError(
          "Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: Nenhum pedido foi encontrado - R2AA7 "
        );
        return;
      }

      if (data.errorMessage) {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - R3256 `
        );
        return;
      }


    },
    onError: (error) => {
      showAlertError(
        `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  R98CC`
      );
    },
    refetchOnWindowFocus: false,
  })



  return (
    <>

      <MenuDashboard pagina={PaginasMenuDashboard.Remessas}>
        <Grid sx={{ display: 'flex', height: '70vh', margin: 'auto' }}>
          {!!Pedido?.data ?
            <PedidoConcluidoPagamentoEscolhido pedido={Pedido.data} />
            :
            <LoadingPedidoRealizadoPagamentoEscolhido />
          }</Grid>
      </MenuDashboard>
    </>
  );
}
