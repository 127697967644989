import { Id } from "react-flags-select";
import api from "..";

import { configurationVariables } from "../../environment/index";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getPedidosSeguroViagemByIDCliente = async (
  idCliente,
  pagina = 1
) => {
  const { data: pedidos, status } = await api.get(
    `/pedidosSeguroViagem/GetPedidosSeguroViagemByIDCliente`,
    {
      params: {
        idCliente: idCliente,
        idHotsite: idHotsite,
        pagina: pagina,
      },
    }
  );

  if (!!pedidos.errorMessage) throw new Error(pedidos.errorMessage);

  return pedidos.data;
};

// //! Pega o pedido de turismo do cliente
export const GetPedidoByID = async (idCliente, idPedido) => {
  try {
    const { data: response, status } = await api.get(
      `/pedidosSeguroViagem/GetByID?idCliente=${idCliente}&idHotsite=${idHotsite}&idPedido=${idPedido}`
    );

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data,
    };
  } catch (error) {
    throw new Error(error + "R773C");
  }
};

