import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";

// UrlApi: "https://prd-ecommerce-und-api.primecasesuporte.com.br/api",
// UrlApi: "https://hlg-ecommerce-par-api.primecase.com.br/api",
export const variables: IVariable = {
  IdHotsite: 3,
  Loja: "Unida Câmbio",
  UserStorage: "+Ldk3/RowkShd+nUhs0Z3w==",
  UserToken: "wYDujXAMXUqL3eXBkLO3aQ==",
  UrlApi: "https://prd-ecommerce-und-api.primecase.com.br/api",
  UrlLoja:"https://unidacambio.com.br/",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#2d3483",
      light: "#484E93",
      //lighter: "#ece6f7",
      dark: darkenColor("#2d3483", 10), // Escurece em 20%
    },
    secondary: {
      main: "#000",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
