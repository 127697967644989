import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  selected: {
    borderColor: `${theme.palette.primary.main} !important`,

    backgroundColor: "#f6f6f6 !important",
  },

  containerChip: {
    marginBottom: "1rem !important",
    width: "100%",
    padding: "25px",
    borderRadius: "12px",
    // boxShadow:
    //   "0px 0px 0.5px rgba(66, 71, 76, 0.32), 0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 40px #eeeeee",

    [theme.breakpoints.up("lg")]: {
      marginTop: "0 !important",
      marginBottom: "1rem !important",
      width: "100%",
      padding: "0",
      borderRadius: "12px",
      // boxShadow:
      //   "0px 0px 0.5px rgba(66, 71, 76, 0.32), 0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 40px #eeeeee",
    },
  },
  SeletorPais: {
    display: "block",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "space-between",
      
      alignItems: "center",
      width: "100%",
    },
  },
  card: {
    borderRadius: "0 ",
    padding: "3px 8px",
    marginTop: "25px",
    minHeight: "350px !important",
    border: "1px solid #00205b !important",
  },

  Seletor: {
    width: "100%",

    [theme.breakpoints.up("lg")]: {
      width: "50%",
    },
  },

  Button: {
    cursor: "pointer",
  },
  Box: {
    display: "flex",
    flexDirection: "row",
    gap: ".5rem",
    width: "60%",
    padding: 20,
    paddingLeft: 0,
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      gap: ".5rem",

      padding: 0,
    },
  },

  container: {
    display: "flex",
    flexDirection: "row",
    gap: ".5rem",
    width: "100%",
    padding: 7,
    paddingLeft: 30,
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
      flexDirection: "row",
      gap: ".5rem",
      padding: 20,

      paddingLeft: 30,
    },
  },

  Pais: {
    marginBottom: "20px !important",
    [theme.breakpoints.up("lg")]: {
      marginBottom: "0 !important",
    },
  },

  Dias: {
    fontWeight: "600",
    textAlign: "left !important",
  },
  Valor: {
    display: "flex",
    alignItems: "baseline",
    marginTop: "25%",
    paddingBottom: 12,
    justifyContent: "flex-start",
  },
  textoDetalhes: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.9rem",
    justifyContent: "center",
    margin: "20px 0px !important",
    textAlign: "left",
    opacity: 0.6,
  },
  Dados: {
    fontSize: "1rem !important",
    display: "block",
    color: "black",
    alignItems: "center !important",
    justifyContent: "flex-start",
    [theme.breakpoints.up("lg")]: {
      fontSize: "1rem !important",
      display: "flex",
      color: "black",
      alignItems: "center !important",
      justifyContent: "flex-start",
    },
  },
  IconChip: {
    background: `${theme.palette.secondary.main}`,
    width: 150,
    height: 35,
    borderRadius: "5px",
    marginBottom: -45,
    marginTop: "42px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detalhes: {
    textDecoration: "underline",
    cursor: "pointer",
    color: `${theme.palette.secondary.main}`,
    fontSize: "17px !important",
  },
}));
