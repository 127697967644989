import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  Container: {
    height: "100vh",
  },
  Item: {
    margin: "auto !important",
    maxWidth: "33em !important",
    marginTop: "0 !important",
  },
  Lateral: {
    display: "block",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
    }
  },
  CloseIcon: {
    marginLeft: "95%",
    marginBottom: "15px !important",
    cursor: "pointer",
    fontSize: "18px !important",
  },
  Titulo: {
    marginBottom: "10px !important",
    color: "black",
    fontWeight:'600 !important',
  },
  Subtitulo: {
    fontWeight: "bold !important",
    color: "#222",
    marginBottom: "20px !important",
  },
  FormControl: {
    width: "100% !important",
    marginBottom: "2em !important",
    marginTop: "1em !important",
  },
  BoxImg: {
    my: 3,
    backgroundColor: "none",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  Link: {
    color: theme.palette.primary.main,
    display:'flex',
    alignItems:'center',
    textDecoration:'underline'
  },
}));

export default useStyles;
