import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Hidden from "@mui/material/Hidden";
import Copyright from "../../components/Copyright";
import EspacoMenuLateralEsquerdo from "../../components/EspacoMenuLateralEsquerdo";
import EspacoConteudoLateralDireito from "../../components/EspacoConteudoLateralDireito";
import useAlert from "../../hooks/useAlert";
import { Rodape, LogoEmpresa } from "../../components";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import api from "../../api";
import useStyles from "./styles";

import { sendDataToAPI } from "../../util/encryptApi";

export default function VerificacaoEmail() {
  const { showAlertSuccess, showAlertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [acessoViaEmailAutenticacao] = useState(
    searchParams.get("autenticacao") !== null
  );
  const [sucessoVerificacaoToken, setSucessoVerificacaoToken] = useState(false);

  const handlerVoltarPaginaLogin = (e) => {
    e.preventDefault();
    navigate("/Login");
  };

  useEffect(() => {
    if (acessoViaEmailAutenticacao === true) {
      async function validaToken() {
        const tokenUrl = searchParams.get("autenticacao");
        if (tokenUrl !== null) {
          setLoading(true);
          try {
            const response = await api.post(
              `/cadastro/Autenticar?token=${tokenUrl}`
            );
            if (
              response.data.errorMessage &&
              response.data.errorMessage.length > 0
            ) {
              showAlertError(response.data.errorMessage);
              setLoading(false);
              setSucessoVerificacaoToken(false);
            } else {
              showAlertSuccess("Verificação de email feita com sucesso");
              setSucessoVerificacaoToken(true);
            }
          } catch (error) {
            showAlertError(error.message);
          }
          setLoading(false);
        }
      }

      validaToken();
    }
  }, []);

  let navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      <CssBaseline />

      {acessoViaEmailAutenticacao && (
        <>
          <Grid container component="main" className={classes.Container}>
            <Hidden smDown>
              <EspacoMenuLateralEsquerdo isDrawer={false}>
                <Grid
                  item
                  md={11}
                  xs={false}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LogoEmpresa idHotsite={0} />
                </Grid>

                <Grid
                  item
                  md={1}
                  sm={1}
                  xs={false}
                  sx={{
                    mx: 5,
                  }}
                >
                  <Copyright />
                </Grid>
              </EspacoMenuLateralEsquerdo>
            </Hidden>
            {/* MENU DIREITO */}
            <EspacoConteudoLateralDireito
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
              }}
            >
              <Hidden smUp>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginRight: "20px",
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                      my: 3,
                      backgroundColor: "none",
                    }}
                  >
                    <LogoEmpresa idHotsite={0} />
                  </Box>
                </Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Grid item className={classes.Item} lg={12}>
                  <Box
                    sx={{
                      mx: 4,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <TaskAltIcon
                      color="primary"
                      sx={{
                        fontSize: 65,
                        textAlign: "center",
                        margin: "auto",
                        mb: 3,
                      }}
                    />
                    {!loading && (
                      <Typography
                        className={classes.Titulo}
                        variant="h5"
                        align="center"
                      >
                        {sucessoVerificacaoToken
                          ? "Verificamos seu email com sucesso!"
                          : "Erro ao tentar validar seu email, tente efetuar o login novamente"}
                      </Typography>
                    )}
                    {loading && <Skeleton sx={{ width: "100%" }} height={80} />}
                    <Button
                      style={{ marginTop: 25 }}
                      variant="contained"
                      fullWidth
                      disabled={loading}
                      onClick={handlerVoltarPaginaLogin}
                    >
                      Fazer login
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              <Rodape />
            </EspacoConteudoLateralDireito>
          </Grid>
        </>
      )}
    </>
  );
}
