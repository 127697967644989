import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/material";
import Hidden from "@mui/material/Hidden";
import useStyles from "./styles";
import { ButtonCustom } from "../Botoes/Button";
import ButtonAcaoGrande from "../Botoes/ButtonAcaoGrande";
import VerificationInput from "react-verification-input";
import ButtonAcaoGrandeOutlined from "../Botoes/ButtonAcaoGrande copy";

export default function CodigoAutenticacao({
  loading,
  handleSolicitaCodigoNovamente,
  handleChange,
  handleSubmit,
}) {

  const classes = useStyles();
  const [counter, setCounter] = useState(60);

  const handlerSolicitaCodigoNovamente = () => {
    if (counter !== 0) {
      throw new Error(
        "A contagem para enviar novamente outro código de autenticação ainda não foi concluída. R194F"
      );
    } else {
      handleSolicitaCodigoNovamente();
      setCounter(60);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setCounter(counter - 1);
    }, 1000);

    if (counter === 0) clearTimeout(timer);
  }, [counter]);

  return (
    <>
      <Grid item className={classes.criar} xs={12}>
        <Grid item className={classes.criar} lg={6}>
          <Grid
            className={classes.Item}
            item
            xs={12}
            alignItems="center"
            justifyContent="center"
          ></Grid>
          <Box
            sx={{
              my: 8,
              mx: 4,
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <Typography className={classes.Titulo} variant="h5">
              Confirmação de e-mail
            </Typography>
            <Typography className={classes.Subtitulo} variant="body1">
              Enviamos um código de autenticação em seu e-mail,<br></br> adicione ele abaixo. <br></br>
              <br></br>
            </Typography>

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}


            >

              <VerificationInput
                onChange={handleChange}
                length={5}
                autoFocus={true}
                validChars={/^\d+$/}
                classNames={{
                  container: classes.ContainerVerification,
                  character: classes.VerificationCaracter,
                  characterInactive: classes.VerificationCaracterInac,
                  characterSelected: classes.VerificationCaracterSelec,
                  characterFilled: classes.VerificationCaracterFil,
                }}
              />
  <Typography sx={{textAlign:'center', opacity:0.5}} variant="body2">
             
              Seu código expira em 3 minutos.
            </Typography>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{ mt: 3, mb: 2, borderRadius: 2 }}
                />
              ) : (
                <ButtonCustom
                  color="primary"
                  variant="contained"
                  type="submit"
                  className={classes.Button}
                  onClick={handleSubmit}
                  sx={{ mt: 3, mb: 2, p: 1, width: "100%" }}
                >
                  Confirmar
                </ButtonCustom>
              )}
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height={40}
                  sx={{ mt: 3, mb: 2, borderRadius: 2 }}
                />
              ) : (
                <ButtonAcaoGrandeOutlined
                  fullWidth
                  variant=""
                  disabled={counter !== 0}
                  Handle={handlerSolicitaCodigoNovamente}
                  sx={{ mt: "10rem !important", mb: 2}}
                >
                  Solicitar novamente {counter > 0 ? "(" + counter + ")" : ""}
                </ButtonAcaoGrandeOutlined>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Hidden smUp>
        <Grid
          item
          md={1}
          sm={1}
          xs={false}
          sx={{
            mx: 5,
          }}
        ></Grid>
      </Hidden>
    </>
    // </Modal>
  );
}
