import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useAuth } from "../../hooks/AuthContext";
import { FeedbackTypeStep } from "./Steps/FeedbackTypeStep";
import { FeedbackContentStep } from "./Steps/FeedbackContentStep";
import { FeedbackSuccessStep } from "./Steps/FeedbackSuccessStep";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import { ButtonCustom } from "../Botoes/Button";

export const feedbackTypes = {
  BUG: {
    title: "Problema",
  },
  IDEA: {
    title: "Ideia",
  },
  OTHER: {
    title: "Outro",
  },
};
export function Feedback() {
  const { user } = useAuth();
  // const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [feedbackType, setFeedbackType] = useState(null);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const handleClick = (e) => {
    setOpenFeedback(!openFeedback);
  };
  function handleRestartFeedback() {
    setFeedbackSent(false);
    setFeedbackType(null);
  }
  return (
    user && (
      <>
        {/* <Hidden smUp>
        <Typography sx={{ml:2, mt:1, display:'flex', alignItems:"center" }} onClick={handleClick}> <LiveHelpOutlinedIcon fontSize="small" sx={{m:0.5}} className="w-6 h-6" />FeedBack</Typography>
       
       </Hidden> */}

        <Hidden smDown>
          <ButtonCustom
            variant="contained"
            size="small"
            sx={{
              zIndex: "modal",
              textTransform: "capitalize !important",
              alignItems: "center",
              position: "fixed",
bottom:30,
              mt: 5,
              right: 2,

              mr: 2,
            }}
            onClick={handleClick}
          >
            {openFeedback ? (
              <CloseIcon fontSize="medium" className="w-6 h-6" />
            ) : (
              <LiveHelpOutlinedIcon
                fontSize="medium"
                sx={{ m: 0.5 }}
                className="w-6 h-6"
              />
            )}
            Feedback
          </ButtonCustom>
        </Hidden>
        <Popover
          // className={classes.Popover}
          open={openFeedback}
          onClose={handleClick}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <Box 
          // className={classes.Box}
          >
            {feedbackSent ? (
              <FeedbackSuccessStep
                onFeedbackRestartRequested={handleRestartFeedback}
              />
            ) : (
              <>
                {!feedbackType ? (
                  <FeedbackTypeStep onFeedbackTypeChanged={setFeedbackType} />
                ) : (
                  <FeedbackContentStep
                    feedbackType={feedbackType}
                    onFeedbackRestartRequested={handleRestartFeedback}
                    onFeedbackSent={() => setFeedbackSent(true)}
                  />
                )}
              </>
            )}
          </Box>
        </Popover>
      </>
    )
  );
}
