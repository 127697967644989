import { Divider } from "@mui/material";
import { Subtitulo } from "../../";
import parseHtml from "html-react-parser";
import CardCustom from "../../Card";
import { Fragment } from "react";

const OperacoesBancarias = ({
  formaPagamento,
  isDetalhes,
}) => {
  return (
    <>
      <CardCustom isDetalhes={isDetalhes}>
        <Subtitulo sx={{ color: "#222", fontSize: "18px" }}>
          Use os dados bancários para realizar o pagamento
        </Subtitulo>
        <hr style={{ borderColor: "#ccc" }} />

        {formaPagamento.map((f, index) => (
          <Fragment key={index}>
            {index !== 0 && (
              <>
                <Divider sx={{ mt: 2.5, mb: 1.5 }} />
              </>
            )}

            {!!f.descricaoHtml ? (
              <>
                <Subtitulo sx={{ fontWeight: "bold", mb: 2, color: "#000" }}>
                  {f.descricao}
                </Subtitulo>
                <Subtitulo sx={{ color: "#000" }}>
                  {parseHtml(f.descricaoHtml ?? "")}
                </Subtitulo>
              </>
            ) : (
              <>
                <Subtitulo style={{ color: "#fff !important" }}>
                  {" "}
                  {parseHtml(f.descricao ?? "")}
                </Subtitulo>
              </>
            )}
          </Fragment>
        ))}
      </CardCustom>
    </>
  );
};

export default OperacoesBancarias;
