import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { saveLocalStorageCarrinhoTemp, saveLocalStorageIdHotsite, clearLocalStorageIdHotsite, getlocalStorageIdHotsite } from '../../util/LocalStorage'
import { useParams } from "react-router-dom";


export default function PreLogin() {
      const [searchParams] = useSearchParams();
      const navigate = useNavigate();
      const { idHotsite } = useParams();

      function hasHotsite() {
            let idHotsiteStorage = getlocalStorageIdHotsite();

            if (isNaN(idHotsite) || idHotsite === '0') {
                  if (idHotsiteStorage > 0) {
                        clearLocalStorageIdHotsite();
                        navigate(0)
                  }
            }


            let idHotsiteProps = parseInt(idHotsite);

            if (idHotsite > 0) {
                  if (idHotsiteStorage !== idHotsiteProps) {
                        saveLocalStorageIdHotsite(idHotsiteProps)
                        navigate(0)
                  }
            }



      }


      useEffect(() => {

            let newArrayParams = [];


            hasHotsite();

            searchParams.forEach((value, key) => {
                  newArrayParams.push({ [key]: value });
            })

            let objParams = Object.assign({}, ...newArrayParams);
            let natureza = { id: objParams?.Nid ?? 0, descricao: objParams?.Ndescricao ?? "" }
            let formObj = { ...objParams, natureza: { ...natureza } }


            saveLocalStorageCarrinhoTemp(formObj);

            navigate("/login", false)

      }, [])

      return;
}