import { Box, Typography } from "@mui/material";
import { useAuth } from "../../hooks/AuthContext";
import TiposStatusCliente from "../../util/typesTipoStatusCliente";
import { GETDADOSCLIENTE } from '../../util/typesReactQuery'
import ErrorIcon from "@mui/icons-material/Error";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { useQuery } from "react-query";
import { getByIdCliente } from "../../api/Cadastro/index";
import useAlert from "../../hooks/useAlert";
import { useDispatch } from "react-redux";

export default function ControllClienteAlertaStatus() {
    const { user, updateIdTipoStatus } = useAuth();
    const { showAlertError } = useAlert();
    const dispatch = useDispatch();

    useQuery({
        queryKey: [GETDADOSCLIENTE],
        queryFn: () => getByIdCliente(user?.id),
        refetchOnWindowFocus: true,
        enabled: user.id > 0,
        onSuccess: (data) => {


            if (!data?.data) {
                showAlertError()
                return;
            }

            if (data?.errorMessage) {
                showAlertError(`Ops, algo deu errado: ${data.data.errorMessage}`);
                return;
            }

            updateIdTipoStatus(data.data.idTipoStatusCliente)

        },
        onError: (error) => {
            showAlertError(`Ops, algo deu errado: ${error?.message ?? error}`)
        }
    });

    return (
        <>
            {user &&
                (user.idTipoStatusCliente === 1 || user.idTipoStatusCliente === 5) &&
                <Box
                    className="w-full"
                    sx={{ backgroundColor: "rgb(253, 237, 237)", padding: "10px", }}
                >
                    {user.idTipoStatusCliente ===
                        TiposStatusCliente.AguardandoAprovacao ? (
                        <Typography
                            sx={{
                                fontSize: "14px !important",
                                textAlign: "center",
                                color: "rgb(95, 33, 32)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '.2rem'
                            }}
                        >
                            <ErrorIcon
                                style={{
                                    fontSize: 14,
                                    color: "rgb(95, 33, 32)",

                                }}
                            />
                            {` `}Seu cadastro está sendo validado. Assim que liberarmos seu acesso, você receberá um e-mail.
                        </Typography>
                    ) : user.idTipoStatusCliente === TiposStatusCliente.Bloqueado ? (
                        <Typography
                            sx={{
                                fontSize: "14px !important",
                                textAlign: "center",
                                color: "rgb(95, 33, 32)",
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '.2rem'
                            }}
                        >
                            <DoDisturbIcon
                                style={{
                                    fontSize: 14,
                                    color: "rgb(95, 33, 32)",
                                }}
                            />
                            Seu acesso está bloqueado, por favor, entre em contato conosco
                            para reativação.
                        </Typography>
                    ) : (
                        ""
                    )}
                </Box>
            }
        </>
    );
}