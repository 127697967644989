import { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { addSaldo } from "../../api/chamadasApi";
import { StepCustom, Position } from "../Steps/StepCustom";
import FormMoedaCustom from "../Forms/FormMoedas";
import Divider from "../DeviderCustom/index";
import { useAuth } from "../../hooks/AuthContext";
import { useMutation, useQueryClient } from "react-query";
import style from "./styleAdicao";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Pix from "../../assets/images/pix.png";
import Qrcode from "../../assets/images/qrcode.png";
import { getBancoNacionalCanalBancario } from "../../api/chamadasApi";
import { CardActionArea } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { NOTIFICATION, CLIENTESALOD } from "../../util/typesReactQuery";

const steps = [
  {
    label: "",
    show: false,
  },

  {
    label: "",
    show: false,
  },
  {
    label: "",
    show: false,
  },
];

const AdicaoReais = ({
  showAlertError,
  showAlertSuccess,
  handlerShowAlert,
  tipoOperacaoSaldo,
  modalControl,
}) => {
  const { user } = useAuth();
  const cssStyle = style();
  const [bancoNacionais, setBancoNacionais] = useState([]);
  const [selectedPix, setSelectedPix] = useState("");
  const [formData, setFormData] = useState({
    valorReais: "0,00",
    idTipoOperacaoSaldo: tipoOperacaoSaldo?.id,
    observacao: "Adição de Dados",
    idBancoNacionalCanalBancario: 0,
    idCliente: user?.id,
  });

  const bancoFilter = bancoNacionais
    ? bancoNacionais.find(
        (banco) => banco.id === formData.idBancoNacionalCanalBancario
      )
    : {};
  const [activeSteps, setActiveSteps] = useState(0);
  const [permiteNextStep, setPermiteNextEstep] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutation(addSaldo, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {
      if (data || data.errorMessage !== "")
        throw new Error(data.errorMessage, " - RGATP");

      if (data.status !== 200) {
        throw new Error("Tente Novamente mais tarde - ", "RVO8T");
      }

      showAlertSuccess("Oba, recebemos a sua solicitação de adição de saldo");

      if (modalControl) modalControl(false);
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      console.error(err);
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
          err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      queryClient.invalidateQueries(CLIENTESALOD);
      queryClient.invalidateQueries(NOTIFICATION);
    },
  });

  useEffect(() => {
    if (bancoNacionais && bancoNacionais.length < 2 && activeSteps === 1) {
      let idBanco =
        formData.idBancoNacionalCanalBancario > 0 ? 0 : bancoNacionais[0].id;
      setActiveSteps(
        formData.idBancoNacionalCanalBancario > 0
          ? activeSteps - 1
          : activeSteps + 1
      );
      setFormData({ ...formData, idBancoNacionalCanalBancario: idBanco });
    }

    switch (activeSteps) {
      case 0:
        if (formData.valorReais <= 0) setPermiteNextEstep(false);
        else setPermiteNextEstep(true);
        break;
      case 1:
        if (formData.idBancoNacionalCanalBancario <= 0)
          setPermiteNextEstep(false);
        else setPermiteNextEstep(true);
        break;
      default:
        setPermiteNextEstep(true);
        break;
    }
  }, [formData.valorReais, formData.idBancoNacionalCanalBancario, activeSteps]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getBancoNacionalCanalBancario();
        setBancoNacionais(response.data);
      } catch (error) {
        console.error("❌ ", error + " RW8IG");
        showAlertError("Opa, não foi possivel registrar sua operação de saldo");
      }
    })();
  }, []);

  const handlerChangeValue = (event) => {
    setFormData({ ...formData, valorReais: event.target.value });
  };

  const handlerBlurValue = (event) => {
    if (!formData.valorReais.includes(","))
      setFormData({ ...formData, valorReais: formData.valorReais + ",00" });
  };

  const handlerSetPix = (pixComponent, idBanco) => (event) => {
    event.preventDefault();
    setSelectedPix(pixComponent);
    setFormData({ ...formData, idBancoNacionalCanalBancario: idBanco });
  };

  const handlerCopyPIX = (event) => {
    event.preventDefault();
    navigator.clipboard
      .writeText(event.target.getAttribute("data-pix"))
      .then(() => {
        showAlertSuccess("Pix copiado com sucesso !!");
      })
      .catch((err) => {
        showAlertError(
          "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
            err
        );
      });
  };

  const formatMoney = (value) => {
    return parseFloat(
      value
        .replace(".", "")
        .replace(" ", "")
        .replace("R$", "")
        .replace(",", ".")
    );
  };

  const handlerSubmitAddSaldo = async (event) => {
    event.preventDefault();
    let valorFormatado = formatMoney(formData.valorReais);
    mutation.mutate({ ...formData, valorReais: valorFormatado });
  };

  function ValorDeposito() {
    return (
      <Grid contained key={"opa"}>
        <Grid item xs={12} sx={{ textAlign: "left" }}>
          <Typography id="modal-modal-title" variant="h6">
            Quanto você deseja depositar?
          </Typography>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <FormMoedaCustom
            casasDecimais={2}
            value={formData?.valorReais}
            onChange={handlerChangeValue}
            onblur={handlerBlurValue}
            size="medium"
            prefix={"R$ "}
          />
        </Grid>
      </Grid>
    );
  }

  function PagamentoPix() {
    return (
      <>
        {" "}
        <Typography id="modal-modal-title" variant="h6">
          Como você deseja depositar?
        </Typography>
        <br></br>
        <Typography
          style={{
            background: "#f5ebe2",
            borderRadius: "8px",
            padding: "10px",
          }}
          id="modal-modal-title"
          variant="subtitle1"
          component="h2"
        >
          <InfoIcon fontSize="small" />
          Lembramos que somente serão reconhecidos os depósitos originados de
          contas de sua titularidade.<br></br>
          Veja como fazer a devolução se você depositou incorretamente.
          Selecione o Banco que deseja realizar o pix
        </Typography>
        {!!bancoNacionais &&
          bancoNacionais.map((banco, index) => (
            <Fragment key={index}>
              <Card
                style={{ marginTop: 13, background: "#fcfcfd" }}
                className={
                  selectedPix === `pix${index}` ? cssStyle.checked : {}
                }
              >
                <CardActionArea
                  onClick={handlerSetPix(`pix${index}`, banco?.id)}
                >
                  <CardContent
                    style={{ background: "#fcfcfd", border: "1px solid #ccc" }}
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      {" "}
                      <Box
                        component="img"
                        style={{ width: "25px", height: "25px" }}
                        src={Pix}
                      ></Box>
                      <Grid style={{ marginLeft: 12 }}>
                        <Typography
                          style={{ fontWeight: 500, textAlign: "left" }}
                          id="modal-modal-title"
                          variant="body2"
                          component="h2"
                        >
                          {banco.bancoNacional.descricao}
                        </Typography>
                        <Typography
                          style={{ fontWeight: 500, textAlign: "left" }}
                          id="modal-modal-title"
                          variant="body2"
                          color="text.secondary"
                          component="h2"
                        >
                          Pix: {banco.chavePix}
                        </Typography>
                      </Grid>
                    </Box>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Fragment>
          ))}
      </>
    );
  }

  function SubmitCredito() {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <MoreHorizIcon
              style={{ margin: "auto", width: "100%", fontSize: 50 }}
            />
            <Typography
              sx={{ mb: 2 }}
              style={{ textAlign: "center" }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              Agradecemos a confiança
            </Typography>
            <Typography
              style={{ textAlign: "center" }}
              id="modal-modal-title"
              variant="body2"
              component="h2"
            >
              Iremos verificar seu pagamento, e se estiver tudo certo,<br></br>{" "}
              enviaremos uma notificação.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              style={{ fontWeight: 500, color: "blue", textAlign: "center" }}
              id="modal-modal-title"
              variant="body2"
              component="h2"
              sx={{ mb: 4, mt: 3 }}
            >
              {bancoFilter.bancoNacional.descricao}
            </Typography>
            <Box style={{ background: "#eee", padding: 15, borderRadius: 10 }}>
              <Typography color="text.secondary">Chave pix: </Typography>
              <br></br>
              <Typography>{bancoFilter.chavePix}</Typography>
              <Grid container xs={12} style={{ display: "flex" }}>
                <Grid item xs={6}>
                  <img
                    width="120
                "
                    src={Qrcode}
                    alt="qrCode"
                  />
                </Grid>
                <Grid item style={{ margin: "auto" }} xs={6}>
                  <Button
                    size="small"
                    data-pix={bancoFilter.chavePix}
                    onClick={handlerCopyPIX}
                    variant="outlined"
                  >
                    <ContentCopyIcon />
                    Copiar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Box sx={{ maxWidth: "auto", flexGrow: 1 }}>
      <StepCustom
        steps={steps}
        activeStep={activeSteps}
        setActiveStep={setActiveSteps}
        positionChildren={Position.Top}
        handlerFinsish={handlerSubmitAddSaldo}
        isNext={permiteNextStep}
      >
        <ValorDeposito />
        <PagamentoPix />
        <SubmitCredito />
      </StepCustom>
    </Box>
  );
};

export default AdicaoReais;
