import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: '3% !important',
        margin: 15,
        paddingBottom: theme.spacing(2),
        // marginBottom: '1rem ',
        width: "100%",
        height: "auto",
        background: "#fff",
        borderBottom: "1px solid #eee",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(0),
            marginBottom: '0rem !important',
            paddingBottom: 0,

        },
    }
}));

export default useStyles;
