import { makeStyles } from "@mui/styles";

export const Style = makeStyles((theme) => ({
  Container: {
    width: "100%",
  },
  Card: {
    paddingBottom: "20px",
    boxShadow: "none !important",
    borderBottom: "1px solid #ccc",
  },
}));
