import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    overflowY: "auto",
    width: "100%",
    position: "fixed !important",
    top: "0 !important",
    left: "0 !important",
    [theme.breakpoints.down("sm")]: {
      "& button": {
        marginBottom: "0rem !important",
      },
      height: "auto",
      overflowY: "auto",
      width: "100%",
      position: "relative !important",
      top: "0 !important",
      left: "0 !important",
    },
  },

  Bloco: {
    [theme.breakpoints.up("md")]: {
      backgroundColor: "none",
      margin: "auto !important",
    },
    [theme.breakpoints.up("lg")]: {},
  },

  buttonVoltar: {
    paddingTop: "10%",
  },

  BlocoBeneficiario: {
    padding: 15,
    overflow: "auto",
    width: "100vw",
    position: "relative !important",
    [theme.breakpoints.up("sm")]: {
      width: "35em",
      maxHeight: "100%",
      position: "relative !important",
      overflow: "hidden",
    },
  },

  logoSM: {
    width: 100,
    height: "auto",
    padding: "0 !important",
    margin: "0 !important",
    position: "relative",
    left: "17%",
    transform: "translateX(-50%)",
    marginTop: "-3rem !important",
  },
  BotaoContinuar: {
    position: "relative",
    width: "100%",
    bottom: 10,
    [theme.breakpoints.up("sm")]: {
      position: "relative",
      width: "100%",
      bottom: 5,
    },
  },
}));

export default useStyles;
