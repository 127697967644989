import api from "..";
import { configurationVariables } from "../../environment";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getTags = async () => {
  try {
    const { status, data: response } = await api.get(
      `SEO/Get`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RKFS");
  }
};

