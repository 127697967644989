import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import { BreadCrumbsPaginas, ItensCart, MenuDashboard } from "../../components";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ListIcon from "@mui/icons-material/List";
import usePedido from "../../hooks/usePedido";
import useAlert from "../../hooks/useAlert";
import { useNavigate } from "react-router-dom";

function Carrinho() {
  const {
    alertInfoPedido,
    errorsPedido,
    itensPedido,
    urlOperacao,
    caminhoVitrine,
    refetchPedido,
  } = usePedido();
  const { showAlertError, showAlertInfo } = useAlert();
  const navigate = useNavigate();


  useEffect(() => {
    if (!!alertInfoPedido) {
      showAlertInfo(alertInfoPedido);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }

    if (!!errorsPedido) {
      showAlertError(errorsPedido);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [alertInfoPedido, errorsPedido]);

  const breadCrumps = [
    {
      label: "Meu Carrinho",
      icon: <ShoppingCartIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
    {
      label: "Itens",
      icon: <ListIcon sx={{ mr: 0.5 }} fontSize="inherit" />,
    },
  ];

  return (
    <>

      <MenuDashboard pagina={-1}>
        <Box
          sx={{
            mt: 3,
            pb: 2,
            width: "100%",
            height: "auto",
            background: "#fff",
          }}
        >
          <BreadCrumbsPaginas list={breadCrumps} />
        </Box>

        <Grid
          sx={{
            mt: 3,
            width: "100%",
            height: "auto",
            pl: 3,
            pr: 3,
          }}
        >
          <ItensCart
            refetchPedido={refetchPedido}
            pedido={itensPedido}
            url={urlOperacao}
            urlVitrine={caminhoVitrine}
          />
        </Grid>
      </MenuDashboard>
    </>
  );
}

export default Carrinho;
