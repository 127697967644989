import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";

export const variables: IVariable = {
  IdHotsite: 0,
  Loja: "goprime",
  UserStorage: "+tIrn4SMUU6C4HIR4rNClg==",
  UserToken: "04WH9O+upUqAOs9dbIMXcQ==",
  UrlApi: "https://prd-ecommerce-gop-api.primecase.com.br/api",
  UrlLoja:"https://prd-ecommerce-gop-web.primecase.com.br/",

};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#8bb011",
      light: "#a1ff97",
      //lighter: "#ece6f7",
      dark: "#030f46",
    },
    secondary: {
      main: "#030f46",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    //white: {
    //  main: "#fff",
    //},
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
