import { Card, CardContent } from "@mui/material"

const CardCustom = ({ children }) => {
    return (
        <Card style={{ borderRadius: "10px !important", minHeight: 'auto', margin: 'auto', maxWidth: '45rem', boxShadow:'none',}}>
            <CardContent
                sx={{
                    maxHeight: '80vh',
                    background: '#fcfcfc',
                    overflow: 'auto',
                    border:'1px solid #ccc',
                   
                }}
            >
                {children}

            </CardContent>
        </Card>
    )
}

export default CardCustom;