import { FormControl, TextField } from "@mui/material";
import React from "react";
import Styles from "./styles";

const CampoTipoNumerico = (
  {
    inputProps,
    size,
    variant,
    required,
    value,
    onChange,
    autoFocus = false,
    label,
  },
  props
) => {
  const classStyle = Styles();
  return (
    <>
      <FormControl
        variant="outlined"
        sx={{ width: "100% !important", display: "flex" }}
        index={props.index}
        size={size}
        fullWidth={true}
      >
        <TextField
          spellCheck="false"
          {...props}
          size={size}
          variant={variant}
          required={required}
          value={value}
          label={label}
          type="number"
          onChange={onChange}
          autoFocus={autoFocus}
          inputProps={{ maxLength: 2 }}
        />
      </FormControl>
    </>
  );
};

export default CampoTipoNumerico;
