import { useState } from "react";
import DocumentoCard from "../../components/DocumentoCard";
import { useTheme } from "@mui/material/styles";
import { Grid, Typography, Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import Button from "@mui/material/Button";
import Alert from "../../components/Snack/Snack";
import { addComprovantePedido } from "../../api/Cambio";
import DownloadIcon from "@mui/icons-material/Download";
import { PEDIDOCOMPROVANTES } from "../../util/typesReactQuery";
import { useMutation, useQueryClient } from "react-query";
import useStyles from "./style";
import useAlert from "../../hooks/useAlert";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const AnexarComprovante = ({
  documentos,
  loading,
  userID,
  idPedido,
  permiteUpload = true,
}) => {
  const initialStateDoc = {
    base64: "",
    nomeArquivo: "",
    idCliente: userID,
    contentType: "",
    tamanho: 0,
    idPedido: idPedido,
  };

  const { showAlertSuccess, showAlertError } = useAlert();

  const [openAddDoc, setOpenAddDoc] = useState(false);
  const [formData, setFormData] = useState(initialStateDoc);
  const [isHoverDrg, setIsHoverDrg] = useState(false);

  const theme = useTheme();
  const queryClient = useQueryClient();

  const mutation = useMutation(addComprovantePedido, {
    onSuccess: (response) => {
      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess("Documento anexado com sucesso");
        setFormData(initialStateDoc);
      }
    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      showAlertError(
        "Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - " +
        err
      );
    },
    //função chamada quando é concluido ou da erro
    onSettled: async () => {
      queryClient.refetchQueries(PEDIDOCOMPROVANTES);
    },
  });

  const handlerOpenDoc = (event) => {
    event.preventDefault();
    setOpenAddDoc(!openAddDoc);
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.error("Error: ", error);
    };
  };

  const handlerChangeDoc = (event) => {

    let file = null;

    if (!!event?.dataTransfer) {
      file = event.dataTransfer.files[0]
      setOpenAddDoc(() => true);
    } else
      file = event.target.files[0];

    // transforma em base 64
    getBase64(file, async (result) => {
      setFormData({
        ...formData,
        nomeArquivo: file.name,
        base64: result,
        tamanho: file.size,
        contentType: file.type,
      });
    });
  };

  const handlerEnviarDoc = (event) => {
    event.preventDefault();
    mutation.mutate(formData);
  };

  const preventBrowserDefault = (e) => {
    e.preventDefault();
  }

  const changeHoverDrag = (value) => {
    setIsHoverDrg(() => value);
  }

  const eventsDrag = {
    onDragEnter: (e) => {
      preventBrowserDefault(e);
      changeHoverDrag(true);
    },
    onDragOver: (e) => {
      preventBrowserDefault(e);
      changeHoverDrag(true);
    },
    onDragLeave: (e) => {
      preventBrowserDefault(e);
      changeHoverDrag(false);
    },
    onDrop: (e) => {
      preventBrowserDefault(e);
      handlerChangeDoc(e)
      changeHoverDrag(false);

    },

  }

  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} className={classes.container} sx={{ border: `1px dashed ${theme.palette.primary.main}` }} {...eventsDrag}>
        <Grid
          item
          xs={10}
          sx={{ pl: 2, display: "flex", justifyContent: "space-between", margin: 'auto' }}
        >
          <Typography
            color="text.secondary"
            sx={{
              fontSize: "16px",
              fontWeight: 'bold',
              mb: theme.spacing(0),
              textAlign: "center !important",
              margin: "auto",
              pb: 2,
            }}
          >
            <FilePresentIcon color="black" fontSize="10" sx={{ mr: 1 }} />
            Comprovantes de pagamento
          </Typography>
        </Grid>

        {
          isHoverDrg ?
            <>
              <Typography
                color="text.secondary"
                textAlign="center"
                sx={{ mb: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                variant="h6"
              >
                Solte o arquivo para adiciona-lo
                <FileDownloadIcon />
              </Typography>
            </>
            :
            <>
              <Grid
                item
                xs={10}
                container
                sx={{ pl: 2, display: "flex", justifyContent: "space-between", margin: 'auto' }}
              >
                <>
                  {documentos === undefined ||
                    (documentos.data.length === 0 && (
                      <Typography
                        color="text.secondary"
                        sx={{
                          fontSize: ".875rem",
                          mb: theme.spacing(0),
                          textAlign: "center !important",
                          margin: "auto",
                          pb: 2,
                        }}
                      >
                        Ainda não há comprovantes anexados neste pedido
                      </Typography>
                    ))}
                </>
              </Grid>

              <Box className={classes.Box}>
                {permiteUpload && (
                  <>
                    <Button
                      component="label"
                      size="small"
                      variant="contained"
                      sx={{ alignItems: "center", display: "flex" }}
                      onChange={handlerChangeDoc}
                    >
                      <DownloadIcon fontSize="small" sx={{ mr: 1 }} />
                      Adicionar comprovante
                      <input type="file" accept="image/*" hidden />
                    </Button>
                    <Typography
                      color="text.secondary"
                      textAlign="center"
                      sx={{ mt: 1, mb: 1 }}
                      variant="subtitle1"
                    >
                      Seu arquivo não deve ultrapassar 5MB
                    </Typography>
                    {formData.tamanho > 0 && (
                      formData.contentType === 'application/pdf' ?
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                          <PictureAsPdfIcon fontSize='large' sx={{ mt: '.5rem' }} />
                        </Box>
                        :
                        (formData.contentType === "image/png" || formData.contentType === "image/jpeg" || formData.contentType === "image/webp") ?
                          <Box
                            component="img"
                            src={formData.base64}
                            sx={{
                              margin: "auto",
                              justifyContent: "center",
                              display: "flex",
                              maxHeight: "60px",
                              maxWidth: "130px",
                            }}
                          />
                          :

                          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                            <InsertDriveFileIcon fontSize="large" />
                          </Box>
                    )}
                    {/* {formData.tamanho > 0 && (
                      <Box
                        sx={{
                          margin: "auto",
                          justifyContent: "center",
                          display: "flex",
                          maxHeight: "60px",
                          maxWidth: "130px",
                        }}
                        component="img"
                        src={formData.base64}
                      />
                    )} */}
                  </>
                )}
                {formData.tamanho > 0 && (
                  <Box
                    sx={{
                      margin: "auto",
                      mt: 1,
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      size="small"
                      onClick={handlerEnviarDoc}
                    >
                      Enviar comprovante
                    </Button>
                  </Box>
                )}
              </Box>

              <Grid container sx={{ pl: 2 }}>
                {loading ? (
                  <>
                    <Grid item xs={10}>
                      <Skeleton
                        variant="rectangular"
                        height={20}
                        key={1}
                        sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Skeleton
                        variant="rectangular"
                        height={20}
                        key={2}
                        sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <Skeleton
                        variant="rectangular"
                        height={20}
                        key={3}
                        sx={{ mt: 3, mb: 2, m: 1, borderRadius: 2, zindex: 1000 }}
                      />
                    </Grid>
                  </>
                ) : (
                  documentos !== undefined &&
                  documentos.data.length > 0 &&
                  documentos.data.map(
                    (doc, index) =>
                      doc.base64 !== "" && (
                        <Grid item xs={12} lg={12} key={index}>
                          <Box
                            fullWidth
                            mb={2}
                            sx={{
                              width: "100%",
                              display: "flex !important",
                              justifyContent: "center !important",
                            }}
                            key={index}
                          >
                            <DocumentoCard documento={doc} key={index} />
                          </Box>
                        </Grid>
                      )
                  )
                )}
              </Grid>
            </>
        }



      </Grid>
    </>
  );
};

export default AnexarComprovante;
