import { Badge } from "@mui/material";
import menuDashStyle from "./style";

const BadgeMenu = ({ children, color = "error", valor = "!" }) => {
      const classes = menuDashStyle();
      return (
            <Badge
                  className={classes.BadgeMenu}
                  badgeContent={valor}
                  color={color}
            >
                  {children}
            </Badge>
      )

}

export default BadgeMenu;