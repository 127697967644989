import { useState } from 'react'
import { useQuery } from 'react-query'
import { LOJAFECHADA } from '../../util/typesReactQuery'
import { GetFuncionamnentoLoja } from '../../api/Carrinho/LojaFechada'


const useLojaFechada = () => {
    const [lojaFechada, setLojaFechada] = useState(null);
    const [errors, setErrors] = useState(null);

    useQuery(LOJAFECHADA, () => GetFuncionamnentoLoja(), {
        onSuccess: (data) => {
            if (!data || !!data.errorMessage)
                setErrors(`Ops, algo deu errado, por favor informe o suporte o seguinte error: Não foi possivel verificar se a loja esta aberta - R1B94 `)

            setLojaFechada(data.data);
        },
        onError: (error) => {
            setErrors(`Ops, algo deu errado, por favor informe o suporte o seguinte error: ${error} - RD88E `)
        }
    })



    return { lojaFechada, errors }
}

export default useLojaFechada