import CryptoJS from "crypto-js";
import api from "../api/index";

const apiKey = CryptoJS.enc.Utf8.parse("c5e38ad500734f95");

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), apiKey, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

export const sendDataToAPI = async (url, data) => {
  const encryptedData = encryptData(data);
  return await api.post(url, { encryptedData });
};
