
import Turismo from "../../../../../components/Vitrine/Turismo";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Box, Hidden } from "@mui/material";
import TiposPedido from '../../../../../util/typesTipoPedido';
import { BreadCrumbsPaginas, MenuDashboard, PaginasMenuDashboard, SubMenusDashBoard } from "../../../../../components";

export default function VitrineRecargaCartao() {
  const breadCrumps = [
    {
      label: "Cartão Pré Pago Internacional",
      icon: <CreditCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    },
    {
      label: "Recarregar Cartão",
      icon: <AddShoppingCartIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    }
  ]
  return (
    <>
      <MenuDashboard pagina={PaginasMenuDashboard.Cartao} subMenuSelecionado={SubMenusDashBoard.RecargaCartao}>


        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>



        <Turismo idTipoOperacao={TiposPedido.VendaEspecie} isRecarga={true} isEspecie={false} />
      </MenuDashboard>
    </>
  );
}
