
import Ouro from "../../../../components/Vitrine/Ouro";
import Money from "@mui/icons-material/PaidOutlined";
import { Box, Hidden } from "@mui/material";
import { BreadCrumbsPaginas, MenuDashboard, PaginasMenuDashboard } from "../../../../components";
import TiposPedido from '../../../../util/typesTipoPedido';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import useSeoConfig from "../../../../hooks/useSeoConfig";
import { useEffect } from "react";

export default function VitrineOuro() {

    const { sendPageAtual } = useSeoConfig();

    useEffect(() => {
        sendPageAtual('Vitrine_ouro');
    }, [])


    const breadCrumps = [
        {
            label: "Ouro",
            icon: <Money sx={{ mr: 0.5 }} fontSize="inherit" />
        },
        {
            label: "Comprar Ouro",
            icon: <AccountBalanceIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        }
    ]
    return (
        <>
            <MenuDashboard pagina={PaginasMenuDashboard.Ouro}>
                <Hidden smUp>
                    <Box sx={{ pl: 2 }}>
                        <BreadCrumbsPaginas list={breadCrumps} />
                    </Box>
                </Hidden>

                <Hidden smDown>
                    <Box sx={{ pt: 2 }}>
                        <BreadCrumbsPaginas list={breadCrumps} />
                    </Box>
                </Hidden>

                <Ouro idTipoOperacao={TiposPedido.VendaOuro} isEspecie={true} />
            </MenuDashboard>
        </>
    );
}
