import api from "../../api";
import { configurationVariables } from "../../environment";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getAllPaisesPossuiPagadorRecebedorParametros = async (
  idTipoPagadorRecebedor
) => {
  try {
    const { status, data: response } = await api.get(
      `pais/GetAllPossuiParametroPagadorRecebedor?idTipoPagadorRecebedor=${idTipoPagadorRecebedor}&idHotsite=${idHotsite}`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RSAX9");
  }
};

export const getAllPaises = async () => {
  try {
    const { status, data: response } = await api.get(`pais/GetAll`);

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R81F");
  }
};

export const getAllPaisByIDVitrine = async (idVitrine) => {
  try {
    const response = await api.get(
      `Pais/GetAllByIDVitrine?idVitrine=${idVitrine}`
    );

    return {
      status: response?.status ?? 400,
      data: response?.data?.data ?? null,
      errorMessage: response?.errorMessage ?? null,
    };
  } catch (error) {
    throw new Error(error + " R81F");
  }
};