import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import { Hidden } from "@mui/material";
import Grid from "@mui/material/Grid";

export const Position = {
  Top: "top",
  Center: "center",
  Bottom: "bottom",
};

export function StepCustom({
  steps,
  activeStep,
  setActiveStep,
  children,
  positionChildren,
  handlerFinsish,
  isNext = true,
  isPrevious = true,
}) {
  const handleNext = () => {
    if (isNext) setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (isPrevious) {
      if (activeStep === 0) return;
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  function RenderChildren() {
    return (
      <>
        {children.map((child, index) => (
          <React.Fragment key={index}>
            {activeStep === index && child}
          </React.Fragment>
        ))}
      </>
    );
  }

  return (
    <Grid item xs={12} alignItems="" justifyContent="center">
      {positionChildren === Position.Top && <RenderChildren />}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        component="img"
        sx={{

          backgroundColor: "none",
          alignItems: "center",
        }}
      />
      <Hidden>
        <Stepper activeStep={activeStep} orientation="horizontal">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel> {step.show ?? false ? step.label : ""}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <br></br>
      </Hidden>

      {positionChildren === Position.Center && (
        <>
          <RenderChildren />
          <br />
          <br />
        </>
      )}

      {activeStep === steps.length - 1 ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              color="secondary"
              disabled={activeStep === 0}
              variant="contained"
              onClick={handleBack}
            >
              Voltar
            </Button>

            <Button
              onClick={handlerFinsish}
              color="primary"
              variant="contained"
            >
              Finalizar
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              color="secondary"
              disabled={activeStep === 0 || !isPrevious}
              variant="contained"
              onClick={handleBack}
            >
              Voltar
            </Button>

            <Button
              color="primary"
              disabled={!isNext}
              variant="contained"
              onClick={handleNext}
            >
              Continuar
            </Button>
          </Box>
        </>
      )}

      {positionChildren === Position.Bottom && (
        <>
          <br />
          <br />
          <RenderChildren />
        </>
      )}
    </Grid>
  );
}
