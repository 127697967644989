import api from "../../index";

const idOrigem = 3;

// ? Busca se a loja está aberta ou fechada
export const GetFuncionamnentoLoja = async () => {
   try {
      const { data: response, status } = await api.get(`HorarioFuncionamento/Get?idOrigem=${idOrigem}`,);

      return {
         status,
         errorMessage: response.errorMessage,
         data: response.data,
      };

   } catch (error) {
      throw new Error(error + "5448");
   }
};


