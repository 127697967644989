import PrivateRoute from '../../util/PrivateRoute';
import { Routes, Route } from "react-router-dom";
import VitrineSeguro from '../../pages/SeguroViagem/VitrineSeguro';
import { Confirmacao as ConfirmacaoPedidoSeguro } from "../../pages/SeguroViagem/Confirmacao";
import { Pagamento as SeguroViagemPagamento } from "../../pages/SeguroViagem/Pagamento";
import { Passageiros as SeguroViagemPassageiros } from "../../pages/SeguroViagem/Passageiros";
import ResumoSeguro from "../../pages/SeguroViagem/ResumoSeguro";

const SeguroRoutes = () => {
      return (
            <Routes>
                  <Route
                        path="/SeguroViagem"
                        element={
                              <PrivateRoute>
                                    <VitrineSeguro />
                              </PrivateRoute>
                        }
                  />

                  <Route
                        path="/SeguroViagem/Passageiros"
                        element={
                              <PrivateRoute>
                                    <SeguroViagemPassageiros />
                              </PrivateRoute>
                        }
                  />


                  <Route
                        path="/SeguroViagem/Pagamento"
                        element={
                              <PrivateRoute>
                                    <SeguroViagemPagamento />
                              </PrivateRoute>
                        }
                  />

                  <Route
                        path="/SeguroViagem/ResumoSeguro"
                        element={
                              <PrivateRoute>
                                    <ResumoSeguro />
                              </PrivateRoute>
                        }
                  />


                  <Route
                        path="/SeguroViagem/Confirmacao/:idPedido"
                        element={
                              <PrivateRoute>
                                    <ConfirmacaoPedidoSeguro />
                              </PrivateRoute>
                        }
                  />
            </Routes>
      )
}

export default SeguroRoutes;