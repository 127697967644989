import { useState, Fragment, useEffect } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import { Alert, Grid, Typography } from "@mui/material";
import { Seletor } from "../";
import Titulo from "../Textos/Titulo";
import Subtitulo from "../Textos/Subtitulo";
import parseHtml from "html-react-parser";
import { getRegioesEntregaByIDPraca } from "../../api/Carrinho/Turismo";
import { useQuery } from "react-query";
import { REGIOESENTREGA } from "../../util/typesReactQuery";
import useStyles from "./styles";
import { formatMoneyInString } from '../../util/helpers'
import AlertCustomFixed from "../AlertCustomFixed";

export default function RegiaoEntrega({
  idPraca,
  isVenda,
  isEspecie,
  handlerSelectRegiaoEntrega = () => { },
  handlerSelectTipoDelivery = () => { },
  handlerChangeValorFrete = () => { },
}) {
  const classes = useStyles();

  const [selectedIDUF, setSelectedIDUF] = useState(0);
  const [selectedIDCidade, setSelectedIDCidade] = useState(0);
  const [selectedIDRegiaoEntrega, setSelectedIDRegiaoEntrega] = useState(0);
  const [selectedIDTipoDelivery, setSelectedIDTipoDelivery] = useState(0);
  const [descricaoHtmlDelivery, setDescricaoHtmlDelivery] = useState("");
  const [UFS, setUFS] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [regioesFiltradas, setRegioesFiltradas] = useState([]);
  const [tiposDelivery, setTiposDelivery] = useState([]);
  const [valorFrete, setValorFrete] = useState(0);



  const {
    data: regioesEntrega,
    isLoading: isLoadingRegioesEntrega,
    refetch: refetchRegioesEntrega,
  } = useQuery([REGIOESENTREGA, idPraca], () => getRegioesEntregaByIDPraca(idPraca, isVenda, isEspecie), {
    refetchOnWindowFocus: false,

  });


  useEffect(() => {
    console.log({ regioesEntrega });
    console.log({ regioesFiltradas })
  }, [regioesEntrega])


  const preencheUFS = () => {
    if (regioesEntrega != undefined) {
      var ufsParaIncluir = [];
      regioesEntrega.forEach(function (regiao) {
        var ufID = regiao.uf.id;
        if (ufsParaIncluir.some((e) => e.id === ufID) === false) {
          ufsParaIncluir.push(regiao.uf);
        }
      });

      setUFS(ufsParaIncluir);
    }
  };

  const preencheCidades = () => {
    if (regioesEntrega && selectedIDUF > 0) {
      var cidadesParaIncluir = [];
      regioesEntrega
        .filter((i) => i.cidade.uf.id === selectedIDUF)
        .map((regiao) => {
          var ufID = regiao.cidade.uf.id;
          var cidadeID = regiao.cidade.id;

          if (cidadesParaIncluir.some((e) => e.id === cidadeID) === false) {
            cidadesParaIncluir.push(regiao.cidade);
          }
        });
      setCidades(cidadesParaIncluir);
    }
  };

  const preencheRegioesFiltradas = () => {
    if (regioesEntrega && selectedIDCidade > 0) {
      var regioesFiltradasParaIncluir = [];
      regioesEntrega
        .filter((i) => i.cidade.id === selectedIDCidade)
        .map((regiao) => {
          if (
            regioesFiltradasParaIncluir.some((e) => e.id === regiao.id) ===
            false
          ) {
            regioesFiltradasParaIncluir.push(regiao);
          }
        });
      setRegioesFiltradas(regioesFiltradasParaIncluir);
    }
  };

  const preencheTipoDelivery = (regiaoSelecionada) => {

    if (!!regiaoSelecionada)
      setTiposDelivery(regiaoSelecionada.tiposDelivery);

  };

  function configuraStateUF() {
    setCidades([]);
    setSelectedIDCidade(0);
    setRegioesFiltradas([]);
    setSelectedIDRegiaoEntrega(0);
    setTiposDelivery([]);
    setSelectedIDTipoDelivery(0);

    preencheCidades();
  }

  function configuraStateCidade() {
    setRegioesFiltradas([]);
    setSelectedIDRegiaoEntrega(0);
    setTiposDelivery([]);
    setSelectedIDTipoDelivery(0);

    preencheRegioesFiltradas();
  }

  function configuraStateRegiaoEntrega() {
    setTiposDelivery([]);
    setSelectedIDTipoDelivery(0);
    handlerChangeValorFrete(0);
    setValorFrete(0);


    var regiaoEscolhida;
    if (selectedIDRegiaoEntrega > 0 && regioesEntrega.length > 0) {
      regiaoEscolhida = regioesEntrega.find(
        (i) => i.id == selectedIDRegiaoEntrega
      );

      setDescricaoHtmlDelivery(regiaoEscolhida?.descricaoHtml ?? "")
      setValorFrete(regiaoEscolhida.valorFrete);
      handlerSelectRegiaoEntrega(regiaoEscolhida);
      handlerChangeValorFrete(regiaoEscolhida.valorFrete);
    }



    preencheTipoDelivery(regiaoEscolhida);
  }

  function configuraStateTipoDelivery() {
    handlerChangeValorFrete(0);
    setValorFrete(0);

    if (selectedIDTipoDelivery > 0) {

      var regiaoEscolhida = regioesEntrega.find(
        (i) => i.id === selectedIDRegiaoEntrega
      );
      var tipoDeliverySelecionado = regiaoEscolhida.tiposDelivery.find(
        (i) => i.id === selectedIDTipoDelivery
      );


      handlerSelectTipoDelivery(tipoDeliverySelecionado);

      setValorFrete(tipoDeliverySelecionado.valorFrete);
      handlerChangeValorFrete(tipoDeliverySelecionado.valorFrete);
    } else {
      configuraStateRegiaoEntrega();
    }
  }


  useEffect(() => {
  }, [tiposDelivery]);

  useEffect(() => {
    preencheUFS();
  }, [regioesEntrega != undefined]);

  useEffect(() => {
    configuraStateUF();
  }, [selectedIDUF]);

  useEffect(() => {
    configuraStateCidade();
  }, [selectedIDCidade]);

  useEffect(() => {
    configuraStateRegiaoEntrega();

  }, [selectedIDRegiaoEntrega]);


  useEffect(() => {
    if (selectedIDTipoDelivery > 0)
      configuraStateTipoDelivery();
  }, [selectedIDTipoDelivery, configuraStateTipoDelivery])




  return (
    <>
      <Grid container className={classes.blocoPedidos} item xs={12}>
        <Grid item xs={12}>
          <Titulo sx={{ mb: 1, mt: 3 }}>Região de entrega</Titulo>
          <Subtitulo sx={{ mb: 2 }}>Por favor, Selecione a região de entrega</Subtitulo>
        </Grid >
        <Grid item xs={12}>
          <br />
          {!isLoadingRegioesEntrega && UFS ? (
            <Seletor
              setSelect={setSelectedIDUF}
              addEmpty={true}
              list={UFS}
              size="small"
              label="UF"
              fullWidth
              value={selectedIDUF}
            />
          )
            :
            <Skeleton width={"100%"} height={50} animation="wave" />
          }
        </Grid>
        <Grid item xs={12}>
          <br />
          {!isLoadingRegioesEntrega && cidades ? (
            <Seletor
              setSelect={setSelectedIDCidade}
              addEmpty={true}
              list={cidades}
              size="small"
              label="Cidade"
              fullWidth
              value={selectedIDCidade}
            />
          )
            :
            <Skeleton width={"100%"} height={50} animation="wave" />

          }
        </Grid>
        <Grid item xs={12}>
          <br />
          {!isLoadingRegioesEntrega && regioesFiltradas ? (
            <Seletor
              setSelect={setSelectedIDRegiaoEntrega}
              addEmpty={true}
              list={regioesFiltradas}
              size="small"
              label="Região"
              fullWidth
              value={selectedIDRegiaoEntrega}
            />
          )
            :
            <Skeleton width={"100%"} height={50} animation="wave" />
          }
        </Grid>
        <Grid item xs={12}>
          <br />
          {tiposDelivery && tiposDelivery.length > 0 && (
            <Seletor
              setSelect={(valor) => { setSelectedIDTipoDelivery(valor) }}
              addEmpty={true}
              list={tiposDelivery}
              size="small"
              label="Tipo de delivery"
              fullWidth
              value={selectedIDTipoDelivery}
              textoOpcaoPadrao="Entegra Padrão"
            />
          )

          }
        </Grid >
        <Grid item xs={12}>
          <br />
          {descricaoHtmlDelivery.length > 0 && (
            <>
              <Subtitulo>
                <AlertCustomFixed type="info">
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Informações sobre a entrega:
                  </Typography>
                  {parseHtml(descricaoHtmlDelivery)}
                </AlertCustomFixed>
              </Subtitulo>
            </>
          )}
        </Grid >
        <Grid item xs={12} mb={2}>
          <br />
          {(selectedIDRegiaoEntrega > 0 || selectedIDTipoDelivery > 0) && (
            <Subtitulo><Alert severity="info"> Valor da sua entrega é: {formatMoneyInString(valorFrete)}</Alert></Subtitulo>
          )}
        </Grid >
      </Grid>
    </>
  );

}
