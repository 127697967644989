import { useState } from "react";
import { useAuth } from "../../../../hooks/AuthContext";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ButtonVoltar from "../../../../components/Botoes/ButtonVoltar";
import DadosBasicos from "../../../../components/Cadastro/DadosBasicos";
import { ButtonCustom } from "../../../../components/Botoes/Button";
import { saveDadosBasicos } from "../../../../api/Cadastro";
import useAlert from "../../../../hooks/useAlert";
import useStyles from "./styles";
import { Box } from "@mui/material";
import { Titulo } from "../../../../components";

const DadosPessoais = ({ handlerCancelEdit }) => {
  const { user } = useAuth();
  const classes = useStyles();
  const { showAlertSuccess, showAlertError } = useAlert();
  const [dadosCliente, setDadosCliente] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handlerSubimit = async () => {
    try {
      setIsLoading(true);
      const response = await saveDadosBasicos(
        dadosCliente,
        user.idPerfilLimiteOperacional,
        user.id,
        true
      );

      if (response.errorMessage && response.errorMessage.length > 0) {
        showAlertError(response.errorMessage);
      } else {
        showAlertSuccess("Alteração salva com sucesso!");
      }
      setIsLoading(false);
    } catch (error) {
      showAlertError(error.message);
    }
  };

  return (
    <>
      <Grid container className={classes.container}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Grid item>
              <ButtonVoltar
                handleVoltar={handlerCancelEdit}
                mostraTexto={true}
              />
            </Grid>
            <Grid item className={classes.Titulo}>
              <Titulo variant="h6">Seus dados</Titulo>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid lg={6} item >
            <DadosBasicos
              idCliente={user.id}
              idPerfilLimiteOperacional={user.idPerfilLimiteOperacional}
              setDada={setDadosCliente}
              isLoading={isLoading}
            />
          </Grid>

          <Grid className={classes.Salvar} lg={12} item >
            <ButtonCustom
              typeButton="primary"
              variant="contained"
              size="medium"
              loading={isLoading}
              onClick={handlerSubimit}
            >
              Salvar alterações
            </ButtonCustom>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DadosPessoais;
