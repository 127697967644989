import { InputLabel, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import styles from "./styles";
import GoldIcon from "../../../assets/images/bargold.ico";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    width: "100%",
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "14px 26px 14px 14px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {},
  },
}));

export default function SeletorMoeda({
  label,
  size,
  sx,
  fullWidth,
  defaultValue,
  disabled = false,
  value,
  list,
  setSelect,
  isOuro = false,
}) {
  const [open, setOpen] = useState(false);
  const classes = styles();

  const labelName = `select-${label}`;
  const idName = `id-${label}`;

  const handleShow = (isShow) => {
    setOpen(isShow);
  };

  return (
    <>
      <InputLabel id={labelName} size={size ?? "medium"}>
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        input={<BootstrapInput />}
        autoWidth={true}
        labelId={labelName}
        id={idName}
        variant="outlined"
        sx={sx}
        open={open}
        onClose={() => handleShow(false)}
        onOpen={() => handleShow(true)}
        value={value}
        label={label}
        size={size ?? "medium"}
        onChange={setSelect}
        fullWidth={fullWidth ?? false}
      >
        {list &&
          list.map((row, index) => (
            <MenuItem key={index} value={row.id}>
              {isOuro ? (
                <img
                  src={GoldIcon}
                  className={classes.bandeiraOuro}
                  loading="lazy"
                  alt="Imagem de barra de ouro"
                />
              ) : (
                <img
                  src={`/images/Bandeiras/${row?.moeda?.codigoBacen}.svg`}
                  loading="lazy"
                  className={classes.Bandeira}
                  alt="Imagem da bandeira brasileira"
                />
              )}

              {row.descricao ? row.descricao : row.moeda.descricao}
            </MenuItem>
          ))}
      </Select>
    </>
  );
}
