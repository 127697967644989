import { makeStyles } from "@mui/styles";
const important = "!important";

const useStyle = makeStyles((theme) => ({
  Link: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  AlertaLimite: {
    display: "flex",
    justifyContent: "",
    position: "relative",
    width: "100%",
    alignItems: "center",
    background: theme.palette.primary.lighter + important,

    zIndex: 2,
    padding: 20,
    margin: "0 !important",
  },
  Bloco: {
    padding: 15,
  },

  BlocoLimite: {
    overflow: "auto",
   

  },
 

  Grid: {
    paddingTop: "5px",
    padding: 15,
    [theme.breakpoints.up("sm")]: {
      margin: "0 !important",
    },
  },

  containerTable: {
    "& .MuiTableCell-root": {
      fontSize: ".9rem",
      textAlign: "start",
    },
  },

  emfaseText: {
    "& span": {
      color: theme.palette.primary.main,
      fontSize: "1.5rem !important",
      fontWeight: "600 !important",
    },
  },

  ButtonComplete: {
    borderRadius: "8px !important",
    backgroundColor: theme.palette.primary.main + "!important",
    opacity: 1,
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeInOut,
    }),
    "&:hover": {
      opacity: 0.8,
    },
  },
}));

export default useStyle;
