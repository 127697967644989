import { Alert, Button } from "@mui/material";
import useStyle from "./styles";


/**
 * 
 * @param {string} type - O tipo do alerta podendo ser: "error", "warning" e "info", por padrão será warning.
 * @param {string} textButton - O texto que sera inserido no botão de ação.
 * @param {function} handlerActionButton - A ação que o botão deve tomar.
 * @param {boolean} isHabilitaButton - Informa se deve conter um botão de ação. 
 */

export default function AlertCustomFixed({ children, type = "warning", textButton = "", handlerActionButton = null, isHabilitaButton = true, sx }) {
  const classes = useStyle();

  return (
    <Alert className={classes.AlertaLimite} severity={type} sx={sx}
      action={
        isHabilitaButton &&
        <Button size="small" color="primary" onClick={handlerActionButton}>
          {textButton}
        </Button>
      }
    >
      {
        children
      }


    </Alert >
  );
}
