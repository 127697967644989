import { useEffect, useState } from "react";
import PagadorRecebedor from "../../../../../components/PagadorRecebedor";
import { useAuth } from "../../../../../hooks/AuthContext";
import {
  getPagadorRecebedor,
  addItemPagadorRecebedor,
} from "../../../../../api/Carrinho/TranferenciaInternacional";
import CarrinhoPage from "../../../../../components/CarrinhoPage";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { Skeleton } from "@mui/material";
import { LoadingBeneficiario, Timer } from "../../../../../components";
import useLojaFechada from "../../../../../hooks/useLojaFechada";
import useAlert from "../../../../../hooks/useAlert";
import { saveLocalStorageTimerCarrinho } from "../../../../../util/LocalStorage";

export default function Beneficiarios() {
  const [carrinho, setCarrinho] = useState(null);
  const { user } = useAuth();
  const navigation = useNavigate();
  const [loadingSelect, setLoadingSelect] = useState(false);
  const { showAlertError } = useAlert();
  const { errors: errorLojaFechada, lojaFechada } = useLojaFechada();

  useEffect(() => {
    if (errorLojaFechada) {
      console.error("Primecase Error - ", errorLojaFechada);
      showAlertError(errorLojaFechada);
      return;
    }

    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada", false);
  }, [lojaFechada, errorLojaFechada]);

  const steps = [
    {
      label: "Vitrine",
      description: "",
    },
    {
      label: "Remetente",
      description: "",
    },
    {
      label: "Forma de pagamento",
      description: ``,
    },
    {
      label: "Conferir",
      description: ``,
    },
    {
      label: "Finalizar",
      description: ``,
    },
  ];

  useEffect(() => {
    init(user.id);
  }, []);

  const init = async (idCliente) => {
    const pagadoresRecebedores = await getPagadorRecebedor(idCliente);
    setCarrinho(pagadoresRecebedores.data);
    saveLocalStorageTimerCarrinho(pagadoresRecebedores.data.dataExpiracao);
  };

  const handlerNext = async (event, pagadorRecebedor) => {
    event.preventDefault();
    setLoadingSelect(true);
    const response = await addItemPagadorRecebedor(user.id, pagadorRecebedor);

    if (response && !response.errorMessage) {
      setTimeout(() => {
        navigation(
          "/carrinho/transferencia-internacional/recebimento/pagamento"
        );
      }, 2000);
    }

    setTimeout(() => {
      setLoadingSelect(false);
    }, 2000);
  };

  const handleBack = () => {
    navigation("/carrinho/transferencia-internacional/recebimento/vitrine");
  };

  return (
    <>
      <CarrinhoPage
        disableButtonNext={true}
        handleBack={handleBack}
        steps={steps}
        activeStep={1}
      >
        {!!carrinho?.dataExpiracao && (
          <Timer
            active={!!carrinho.dataExpiracao}
            dataExpiracao={carrinho?.dataExpiracao}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}
        <>
          {loadingSelect ? (
            <Skeleton height={50} animation="wave" width={250} />
          ) : (
            <Typography style={{ fontSize: "17pt", paddingBottom: "20pt" }}>
              Quem está enviando?
            </Typography>
          )}

          {carrinho ? (
            <PagadorRecebedor
              idPais={carrinho.pais.id}
              paises={carrinho.paises}
              idTipoPagadorRecebedor={2}
              onNext={handlerNext}
              tipoLabel="Recebedor"
              loadingSelect={loadingSelect}
            />
          ) : (
            <LoadingBeneficiario />
          )}
        </>
      </CarrinhoPage>
    </>
  );
}
