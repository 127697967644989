import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link, useSearchParams } from "react-router-dom";
import PasswordIcon from "@mui/icons-material/Password";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Typography,
  Hidden,
  Box,
  Grid,
  CssBaseline,
  Button,
} from "@mui/material";
import useAlert from "../../hooks/useAlert";
import {
  CampoPassword,
  CampoEmail,
  Rodape,
  EspacoConteudoLateralDireito,
  LogoEmpresa,
} from "../../components";

import api from "../../api";

import { sendDataToAPI } from "../../util/encryptApi";

export default function NovaSenha() {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  if (searchParams.get("token") === null || searchParams.get("token") === "") {
    setTimeout(() => {
      navigate("/Login");
    }, 500);
  }

  const { showAlertSuccess, showAlertError } = useAlert();
  const [loading, setLoading] = useState(false);
  const [helperTextError, setHelperTextError] = useState("");
  const [isError, setIsError] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);
  const [formData, setFormData] = useState({
    senha: "",
    confirmacaoSenha: "",
    token: "",
    email: "",
  });

  const handleChange = (e) => {
    if (e.target.name === "confirmacaoSenha") {
      if (e.target.value !== formData.senha) {
        setHelperTextError("Confirmar senha deve ser igual ao campo senha");
        setIsError(true);
      } else {
        setHelperTextError("");
        setIsError(false);
      }
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (!formData.email) {
      setDisabledButton(true);
      return;
    }

    if (!formData.senha && !formData.confirmacaoSenha) {
      setDisabledButton(true);
      return;
    }

    if (formData?.senha !== formData?.confirmacaoSenha) {
      setDisabledButton(true);
      return;
    }

    setDisabledButton(false);
    return;
  }, [formData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await sendDataToAPI("/Cadastro/v2/AtualizarNovaSenha", {
      token: searchParams.get("token"),
      email: formData.email,
      senha: formData.senha,
    });

    if (response.data.errorMessage && response.data.errorMessage.length > 0) {
      showAlertError(response.data.errorMessage);
    } else {
      showAlertSuccess("Senha alterada com sucesso!");
      setTimeout(() => {
        navigate("/Login");
      }, 1000);
    }
  };

  return (
    <>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Hidden smDown></Hidden>
        {/* MENU DIREITO */}
        <EspacoConteudoLateralDireito style={{ margin: "auto" }}>
          <Hidden>
            <Grid
              item
              xs={12}
              alignItems="center"
              style={{
                display: "flex",
                justifyContent: "center",
                marginRight: "20px",
              }}
              justifyContent="center"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  my: 3,
                  backgroundColor: "none",
                  alignItems: "center",
                }}
              >
                <LogoEmpresa idHotsite={0} />
              </Box>
            </Grid>
          </Hidden>

          {searchParams.get("token") !== null && (
            <Grid item style={{ margin: "auto" }} xs={12}>
              <Grid item style={{ margin: "auto" }} lg={6}>
                <Link to="/Login">
                  <ArrowBackIosNewIcon
                    style={{ color: "black" }}
                  ></ArrowBackIosNewIcon>
                </Link>
                <Box
                  sx={{
                    my: 8,
                    mx: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <PasswordIcon style={{ marginBottom: 20, fontSize: 50 }} />
                  <Typography
                    style={{ fontWeight: 700, marginBottom: 20, color: "#555" }}
                    component="h1"
                    variant="h5"
                  >
                    Criação de nova senha
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "normal",
                      color: "#666",
                      marginBottom: 20,
                      textAlign: "center",
                    }}
                    component="h1"
                    variant="body1"
                  >
                    Basta preencher os campos abaixo para criar uma nova senha
                    de acesso ao sistema.
                  </Typography>

                  <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 1 }}
                  >
                    <CampoEmail
                      label="Confirme seu email"
                      value={formData.email}
                      name="email"
                      handlerChange={handleChange}
                    />
                    <CampoPassword
                      name="senha"
                      label="Senha"
                      value={formData.senha}
                      loading={loading}
                      handleChange={handleChange}
                    />

                    <CampoPassword
                      name="confirmacaoSenha"
                      label="Confirmar Senha"
                      value={formData.confirmacaoSenha}
                      loading={loading}
                      helperText={helperTextError}
                      handleChange={handleChange}
                      error={isError}
                    />

                    <Button
                      type="submit"
                      fullWidth
                      color="primary"
                      variant="contained"
                      disabled={disabledButton}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Confirmar!
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          <Rodape />
        </EspacoConteudoLateralDireito>
      </Grid>
    </>
  );
}
