import { makeStyles } from "@mui/styles";
import theme from "../../components/Paleta/theme";

const useStyles = makeStyles(() => ({
  Texto: {
    marginLeft: "15px !important",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "15px !important",
    },
  },
}));

export default useStyles;
