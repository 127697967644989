import api from "../";
import { configurationVariables } from "../../environment";
import TiposOrigem from "../../util/typesTipoOrigem";
import TiposPedido from "../../util/typesTipoPedido";


const idHotsite = parseInt(configurationVariables.IdHotsite);


export const GetCupomByCodigo = async (formData) => {
  try {
    const params = new URLSearchParams();
    params.append("codigo", formData?.Cupom?.codigoPromocional);
    params.append("idTipoOperacao", formData?.idTipoOperacao);
    params.append("idMoeda", formData?.itensVitrine?.moeda?.id);
    params.append("idOrigem", 3);
    params.append("valorME", formData?.valorME);
    params.append("valorTaxaAplicada", formData?.valorTaxaAplicada);
    params.append("idNatureza", formData?.Natureza?.idNatureza);

    const { data: response, status } = await api.get(`/Cupom/GetByCodigo`, {
      params,
    });

    return {
      status,
      errorMessage: response.errorMessage,
      data: response.data ?? [],
    };
  } catch (error) {
    throw new Error(error + "RP64E");
  }
};

export const AddCupomCarrinho = async ({
  userID,
  codigoCupom,
  idTipoPedido,
}) => {
  try {
    let data = null;
    let response = { errorMessage: null };
    let status = 0;

    switch (parseInt(idTipoPedido)) {
      case TiposPedido.CompraEspecie:
      case TiposPedido.VendaEspecie:
        data = await api.post(
          `/CarrinhoTurismo/ConfiguraCupom?idCliente=${userID}&idOrigem=${TiposOrigem.lojaCambioWeb}&codigoCupom=${codigoCupom}&idHotsite=${idHotsite}`
        );
        break;
      case TiposPedido.Remessa:
      case TiposPedido.Recebimento:
        data = await api.post(
          `/CarrinhoRemessa/ConfiguraCupom?idCliente=${userID}&idOrigem=${TiposOrigem.lojaCambioWeb}&codigoCupom=${codigoCupom}&idHotsite=${idHotsite}`
        );
        break;
      case TiposPedido.VendaOuro:
        data = await api.post(
          `/CarrinhoOuro/ConfiguraCupom?idCliente=${userID}&idOrigem=${TiposOrigem.lojaCambioWeb}&codigoCupom=${codigoCupom}&idHotsite=${idHotsite}`
        );
        break;
      default:
        response.errorMessage = "Um ou mais erros foram encontrados - R512S";
        break;
    }

    if (!data) {
      response.errorMessage = "Um ou mais erros foram encontrados - R2C7W";
      status = 400;
    }
    if (data) {
      response.errorMessage = data.data.errorMessage;
      status = data.status;
    }

    return {
      status,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + "R286D");
  }
};

export const RemoveCupomCarrinho = async ({ userID, idTipoPedido }) => {
  try {
    let data = null;
    let response = { errorMessage: null };
    let status = 0;
    switch (parseInt(idTipoPedido)) {
      case TiposPedido.CompraEspecie:
      case TiposPedido.VendaEspecie:
        data = await api.post(
          `/CarrinhoTurismo/RemoverCupom?idCliente=${userID}&idOrigem=${TiposOrigem.lojaCambioWeb}&idHotsite=${idHotsite}`
        );
        break;
      case TiposPedido.Remessa:
      case TiposPedido.Recebimento:
        data = await api.post(
          `/CarrinhoRemessa/RemoverCupom?idCliente=${userID}&idOrigem=${TiposOrigem.lojaCambioWeb}&idHotsite=${idHotsite}`
        );
        break;
      case TiposPedido.VendaOuro:
        data = await api.post(
          `/CarrinhoOuro/RemoverCupom?idCliente=${userID}&idOrigem=${TiposOrigem.lojaCambioWeb}&idHotsite=${idHotsite}`
        );
        break;
      default:
        response.errorMessage = "Um ou mais erros foram encontrados - R512S";
        break;
    }

    if (!data) {
      response.errorMessage = "Um ou mais erros foram encontrados - R4B4E";
      status = 400;
    }
    if (data) {
      response.errorMessage = data.data.errorMessage;
      status = data.status;
    }

    return {
      status,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + "R805B");
  }
};
