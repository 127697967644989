import { makeStyles } from "@mui/styles";
import { grey } from "@mui/material/colors";
import getLuminance from "../../getluminance";
import theme from "../../Paleta/theme";

const important = " !important";

const useStyle = makeStyles(() => {
  const buttonPrimaryBackground = theme.palette.primary.main; // Store the primary background color
  const buttonPrimaryText =
    getLuminance(buttonPrimaryBackground) > 0.7 ? "#000" : grey[100]; // Calculate text color based on luminance

  return {
    buttonSuccess: {
      backgroundColor: theme.palette.success.main + important,
      color: grey[100] + important,
      borderRadius: "4px" + important,
      opacity: 0.8,
      transition: theme.transitions.create("opacity"),
      "&:hover": {
        opacity: 1,
      },
    },
    buttonError: {
      backgroundColor: theme.palette.error.main + important,
      color: grey[100] + important,
      borderRadius: "4px" + important,
      opacity: 0.8,
      transition: theme.transitions.create("opacity"),
      "&:hover": {
        opacity: 1,
      },
    },
    buttonPrimary: {
      backgroundColor: buttonPrimaryBackground + important,
      color: buttonPrimaryText + important, // Dynamically set the text color based on background luminance
      borderRadius: "4px" + important,
      opacity: 0.9,
      transition: theme.transitions.create("opacity"),
      "&:hover": {
        opacity: 1,
      },
    },

    buttonText: {
      color: theme.palette.primary.main + important,
      "&:hover": {
        backgroundColor: "transparent" + important,
      },
    },
  };
});

export default useStyle;
