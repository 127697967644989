import api from "..";
import { configurationVariables } from "../../environment";

const idHotsite = parseInt(configurationVariables.IdHotsite);

export const getPagadorRecebedorByIDClienteIDpaisIDTipo = async (
  idCliente,
  idPais,
  idTipo
) => {
  try {
    const { status, data: response } = await api.get(
      `PagadorRecebedorEstrangeiro/GetByIDClienteAndIDPaisAndIDTipo?idCliente=${idCliente}&idPais=${idPais}&idTipo=${idTipo}&idHotsite=${idHotsite}`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RKFS");
  }
};

export const getAllTiposPagadorRecebedor = async () => {
  try {
    const { status, data: response } = await api.get(
      `TipoPagadorRecebedorEstrangeiro/GetAll`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R152");
  }
};

export const createPagadorRecebedorByIDClienteIDpaisIDTipo = async (
  idCliente,
  idPais,
  idTipo
) => {
  try {
    const { status, data: response } = await api.get(
      `PagadorRecebedorEstrangeiro/CreateByIDClienteAndIDPaisAndIDTipo?idCliente=${idCliente}&idPais=${idPais}&idTipo=${idTipo}`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " FB9E");
  }
};

export const saveNew = async (pagadorRecebedorResponseDTO) => {
  try {
    const { status, data: response } = await api.post(
      "PagadorRecebedorEstrangeiro/Save",
      pagadorRecebedorResponseDTO
    );
    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " RKFS");
  }
};

export const desativaAtivaByID = async ({
  idCliente,
  idPagadorRecebedor
}) => {
  try {
    const { status, data: response } = await api.post(
      `PagadorRecebedorEstrangeiro/DesativaAtivaByID?idCliente=${idCliente}&idPagadorRecebedor=${idPagadorRecebedor}`
    );

    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " F271");
  }
};

export const getTipoPropriedadePagadorRecebedorByIDPais = async (idPais) => {
  try {
    const { status, data: response } = await api.get(
      `TipoPagadorRecebedorPropriedade/GetByIDPais?idPais=${idPais}`
    );
    return {
      status,
      data: response.data,
      errorMessage: response.errorMessage,
    };
  } catch (error) {
    throw new Error(error + " R21F");
  }
};
