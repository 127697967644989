import { Card, CardContent, Grid, Skeleton, useTheme } from "@mui/material";
import Subtitulo from "../../Textos/Subtitulo";

const Loading = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      sx={{
        mt: theme.spacing(3),
        padding: theme.spacing(7),
        height: "80vh",
        maxWidth: "45rem",
        margin: "auto",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item md={12} sm={12}>
        <Skeleton height={50} width={"100%"} animation="wave" />
        <Skeleton height={50} width={"100%"} animation="wave" />
        <Skeleton height={50} width={"100%"} animation="wave" />
      </Grid>
      <Grid item md={12} sm={12}>
        <Card variant="outlined">
          <CardContent>
            <Skeleton height={50} width={"100%"} animation="wave" />

            <Skeleton height={250} width={"100%"} animation="wave" />
            <Skeleton height={50} width={"100%"} animation="wave" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Loading;
