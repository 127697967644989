import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export default function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="left" {...props}>
      Desenvolvido pela <br />{" "}
      <Link color="inherit" href="http://primecase.com.br/" underline="none">
        <b>PRIMECASE TECNOLOGIA</b>
      </Link>
     
    </Typography>
  );
}
