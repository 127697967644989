
import Turismo from "../../../../../components/Vitrine/Turismo";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { Box, Hidden } from "@mui/material";
import { BreadCrumbsPaginas, MenuDashboard, PaginasMenuDashboard, SubMenusDashBoard } from "../../../../../components";
import TiposPedido from '../../../../../util/typesTipoPedido';
export default function VitrineCompraSaldoCartao() {

  const breadCrumps = [
    {
      label: "Cartão Pré Pago Internacional",
      icon: <CreditCardIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    },
    {
      label: "Vender saldo cartão",
      icon: <AddShoppingCartIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    }
  ]

  return (
    <>
      <MenuDashboard pagina={PaginasMenuDashboard.Cartao} subMenuSelecionado={SubMenusDashBoard.VenderSaldoCartao}>

        <Hidden smUp>
          <Box sx={{ pl: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Hidden smDown>
          <Box sx={{ pt: 2 }}>
            <BreadCrumbsPaginas list={breadCrumps} />
          </Box>
        </Hidden>

        <Turismo idTipoOperacao={TiposPedido.CompraEspecie} isEspecie={false} />

      </MenuDashboard>
    </>
  );
}
