import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  emfaseText: {
    "& span": {
      color: theme.palette.primary.main,
      fontSize: "1.5rem !important",
      fontWeight: "600 !important",
    },
  },

  fontBold: {
    fontWeight: 900,
  },

  Detalhes: {
    
    borderRadius: 4,
    minHeight: "60vh",
  },
  Codigo: {
    color: theme.palette.primary.main,
  },
  Card: {
    display: "flex",
    boxShadow: "none !important",
    width: "100%",
    height: "6em",
    borderRadius: "12px",
    background: "#fff !important",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      boxShadow: "none !important",
      width: "100%",
      height: "3em",

      borderRadius: "12px",
      background: "#fff !important",
    },
  },
  formaPagamento: {
   
  },
  ItensPedido: {
    borderRadius: 5,
  },
  TitleBox:{
    display: "flex",
    justifyContent: "flex-start",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    justifyContent: "center",
    }
  },
  Titulo: {
    fontSize: "20px !important",
    fontWeight: 'bold !important',
    color: "black",
    alignItems: "center",
    display: "flex",
    textAlign: "left",
    paddingTop: 5,
  },
  Item: {
    color: "black",
  },

  Text:{
    color:'black',
    textAlign:"left",
    marginTop:15
  },
  Box:{
    padding:2,
    [theme.breakpoints.up("sm")]: {
    padding:20,
    }
  },
}));

export default useStyle;
