import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  Box: {
    padding: 3,

    [theme.breakpoints.up("sm")]: {},
  },

  head: {
    ml: 1,
    padding: 10,
    alignItems: "center",
    mt: 4,
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      marginLeft: 35,
      alignItems: "center",

      display: "flex",
      maxWidth: "70em !important",
      justifyContent: "space-between",
    },
  },
  seletor: {
    padding: "0 !important",
    [theme.breakpoints.up("sm")]: {
      padding: "0 !important",
    },
  },
  Rodape: {
    display: "flex",
    flexDirection: "column",
    margin: 0,
    position: "fixed",
    bottom: "2rem",
    left: "50%",
    transform: "translateX(-50%)",

    [theme.breakpoints.up("sm")]: {
      position: "relative",
      display: "flex",
      marginTop: "1.5rem",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      "& .MuiStep-horizontal": {
        height: "1.5rem !important",
        padding: 0,
      },
    },
  },
  Topo: {
    padding: "2px !important",
    paddingTop: "9% !important",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "3% !important",
      paddingLeft: "15px !important",
      paddingBottom: "10px",
    },
  },
  ChangeLimit: {
    display: "flex",
    paddingLeft: "5px !important",
    paddingBottom: "5px",

    [theme.breakpoints.up("sm")]: {
      display: "flex",
      paddingLeft: "15px !important",
      paddingBottom: "22px",
    },
  },
  Botoes: {
    marginLeft: "1em",
    marginRight: "1em",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      padding: "8px",
      justifyContent: "space-between",
    },
  },

  Grid: {
   marginTop:15,
   marginBottom:15,
    justifyContent: "start",
    [theme.breakpoints.up("sm")]: {
      marginTop:15,
      marginBottom:15,
      justifyContent: "center",
    },
  },

  Voltar: {
    color: "black !important",
    margin: '15px !important',
    
    position: "absolute",
    left: "0%",
    top: "8%",

    [theme.breakpoints.up("sm")]: {
      color: "black !important",
      margin: 8,
     
      position: "absolute !important",
      left: "270px !important",
      top: "20% !important",
    },
  },
}));

export default useStyle;
