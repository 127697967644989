import PrivateRoute from '../../util/PrivateRoute';
import ChipInternacional from '../../pages/ChipInternacional';
import { Pagamento as ChipPagamento } from '../../pages/ChipInternacional/Pagamento';
import ResumoChip from '../../pages/ChipInternacional/ResumoChip';
import Confirmacao from '../../pages/ChipInternacional/Confirmacao';
import { Routes, Route } from "react-router-dom";

const ChipRoutes = () => {
      return (
            <Routes>
                  <Route
                        path="/ChipInternacional"
                        element={
                              <PrivateRoute>
                                    <ChipInternacional />
                              </PrivateRoute>
                        }
                  />;

                  <Route
                        path="/ChipInternacional/Pagamento"
                        element={
                              <PrivateRoute>
                                    <ChipPagamento />
                              </PrivateRoute>
                        }
                  />

                  <Route
                        path="/ChipInternacional/Resumo"
                        element={
                              <PrivateRoute>
                                    <ResumoChip />
                              </PrivateRoute>
                        }
                  />

                  <Route
                        path="/ChipInternacional/Confirmacao/:idPedido"
                        element={
                              <PrivateRoute>
                                    <Confirmacao />
                              </PrivateRoute>
                        }
                  />
            </Routes>
      )
}

export default ChipRoutes;