import * as React from 'react';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import { ConectorSteps, IconColorStep } from './style'


export default function StepStatus({ steps, indexSteps = 0, isSuccess = 'true' }) {



      function ColorlibStepIcon(props, icon) {
            const { active, completed, className } = props;

            return (
                  <IconColorStep issuccess={isSuccess} stadostep={{ completed, active }} className={className}>
                        {icon}
                  </IconColorStep>
            );
      }


      return (
            <>
                  {steps?.length > 0 &&
                        <Stack sx={{ width: '100%' }} spacing={4}>
                              <Stepper alternativeLabel activeStep={indexSteps} connector={<ConectorSteps issuccess={isSuccess} />}>

                                    {steps?.map((item, index) => (
                                          <Step key={index}>
                                                {item?.icon ?
                                                      <StepLabel StepIconComponent={(props) => ColorlibStepIcon(props, item.icon)}>{item.label}</StepLabel>
                                                      :
                                                      <StepLabel >{item.label}</StepLabel>
                                                }
                                          </Step>
                                    ))}
                              </Stepper>
                        </Stack>
                  }
            </>
      );
}