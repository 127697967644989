import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { ButtonVoltar } from "../../../../components";
import DadosBancarios from '../../../../components/DadosBancarios'

export default function MeusDadosBancarios({ handlerCancelEdit }) {

    return (
        <Grid container style={{ padding: "15px" }}>
            <Grid item xs={12}>
                <ButtonVoltar handleVoltar={handlerCancelEdit} mostraTexto={true} />
            </Grid>
            <Grid item lg={8} xs={12}>
                <Typography
                    variant="h6"
                    color="text"
                    style={{ marginLeft: "1rem", marginBottom: 3 }}
                >
                    Seus dados bancários
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ ml: 2, mb: 3 }}>
                    Mantenha seus dados bancários atualizados, para melhor usabilidade da
                    plataforma.
                </Typography>
            </Grid>
            <Grid container item lg={8} xs={12}>
                <DadosBancarios />
            </Grid>
        </Grid>
    );
}
