import { makeStyles } from "@mui/styles";

const ip = " !important";

export const Style = makeStyles((theme) => ({
  CarrinhoList: {
    maxWidth: "90%",
    margin: "auto",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "400px",
    },
  },
  AlertaTermos: {
    background: "#fcfcfd",

    padding: 6,
    [theme.breakpoints.up("sm")]: {
      background: "#fcfcfd",

      padding: 6,
    },
  },

  OptionsOperacoes: {
    justifyContent: "space-around",
    "& :where(button) ": {
      fontSize: ".9rem" + ip,
      lineHeight: ".9rem" + ip,
      color: theme.palette.text.primary + ip,
    },
  },

  dFlex: {
    display: "flex",
  },

  FormControl: {
    width: "100%",
    border: "none !important",
  },
  Grid: {
    margin: "auto",
    marginTop: "5%",
    width: "100vw",
    padding: 12,
    "& button + button": {
      marginLeft: theme.spacing(2),
    },

    "& button:first-child": {
      marginLeft: theme.spacing(-1.5),
    },
    [theme.breakpoints.up("sm")]: { width: "auto" },
  },

  Option: {
    color: "black",
  },
  Link: {
    textDecoration: "none",
  },

  isAceite: {
    background: "rgba(117,222,75,.1) !important",
  },
  isErrorTermo: {
    background: "rgba(224,49,49,.2) !important",
  },
  itemListMaisMoedas: {
    display: "flex ",
    justifyContent: "space-between !important",
    width: "100%",
  },

  Carrinho: {
    display: "block",
    justifyContent: "space-around",
    width: "100%",
    
    [theme.breakpoints.up("sm")]: {
      display: "block",
      justifyContent: "space-around",
      maxWidth: "100%",
      width: "90%",
      margin: 30,
    },
    [theme.breakpoints.up("lg")]: {
      display: "flex",
      justifyContent: "space-around",
      maxWidth: "100%",
      width: "90%",
      margin: 20,
      alignItems: "center",
    },
  },
  VitrineBase: {
    margin: "auto",
    alignItems: "center",
    maxWidth: "90% !important",
    [theme.breakpoints.up("lg")]: {
      margin: "auto",
      alignItems: "center",
      maxWidth: "400px !important",
    },
  },
}));
