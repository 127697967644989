import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getPedidoByID } from '../../../api/Carrinho/ChipInternacional';
import { LoadingPedidoRealizadoPagamentoEscolhido, MenuDashboard, PaginasMenuDashboard, PedidoConcluidoPagamentoEscolhido } from "../../../components";
import { useAuth } from "../../../hooks/AuthContext";
import useAlert from "../../../hooks/useAlert";
import { GETPEDIDOCONCLUIDO } from "../../../util/typesReactQuery";
import TiposPedido from "../../../util/typesTipoPedido";


export default function Confirmacao() {
  const { user } = useAuth();
  const { showAlertError, showAlertSuccess } = useAlert();
  const { idPedido } = useParams()
  const [pedido, setPedido] = useState(null);


  useQuery({
    queryKey: [GETPEDIDOCONCLUIDO],
    queryFn: () => (getPedidoByID(user.id, idPedido)),
    refetchOnWindowFocus: false,
    retry: 3,
    retryDelay: 3000,
    onSuccess: (data) => {
      if (!data) {
        showAlertError(
          "Ops, ocorreu um erro:Nenhum pedido de chip foi encontrado -  RF13Z "
        );
        return;
      }

      if (!!data.errorMessage) {
        showAlertError(
          `Ops, ocorreu um erro, informe ao suporte tecnico o seguinte erro: ${data.errorMessage} - R5A6G `
        );
        return;
      }

      let dadoPedido = { ...data.data, tipoPedido: { id: TiposPedido.VendaChipInternacional } }

      setPedido(() => ({ ...dadoPedido }))
    },
    onError: (error) => {
      showAlertError(
        `Ops, ocorreu um erro, informe ao suporte tecnico: ${error?.message} -  RNYCO`
      );
    },

  })


  return (
    <>

      <MenuDashboard pagina={PaginasMenuDashboard.ChipInternaciona}>

        {!!pedido ?
          <PedidoConcluidoPagamentoEscolhido pedido={pedido} />
          :
          <LoadingPedidoRealizadoPagamentoEscolhido />
        }
      </MenuDashboard>
    </>
  );
}
