import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxHeight: "40px",
    alignContent: "center",
    width: "auto",
    height: "auto",
    maxWidth: "100px",
    objectFit: "cover",

    [theme.breakpoints.up("sm")]: {
        maxHeight: "78px",
        alignContent: "center",
        width: "auto",
        height: "auto",
        maxWidth: "100px",
        objectFit: "cover",
    }
  },

 
}));

export default useStyles;
