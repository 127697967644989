import { createContext, useCallback, useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { configurationVariables } from "../environment/index";

import api from "../api";

const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [isNotPossuiCamposLimite, setIsNotPossuiCamposLimite] = useState(false);
  const [type, setType] = useState("");

  const [data, setData] = useState(() => {
    const token = localStorage.getItem(configurationVariables.UserToken);
    const user = localStorage.getItem(configurationVariables.UserStorage);

    if (token !== "undefined" && user !== "undefined") {
      api.defaults.headers.Authorization = `Bearer ${token}`;

      api.interceptors.response.use(
        (response) => {
          return response;
        },
        function (error) {
          const originalRequest = error.config;
          if (error.response.status === 401 && !originalRequest._retry) {
            // api.post("usuario/SignOut", user).then((response) => {
            localStorage.removeItem(configurationVariables.UserToken);
            localStorage.removeItem(configurationVariables.UserStorage);
            <Navigate to="/Login" replace />;
            // });
          }
        }
      );

      return { token, user: JSON.parse(user) };
    } else {
      <Navigate to="/Login" replace />;
    }

    return {};
  });

  const signIn = useCallback(async ({ token, user }) => {
    localStorage.setItem(configurationVariables.UserToken, token);
    localStorage.setItem(
      configurationVariables.UserStorage,
      JSON.stringify(user)
    );

    api.defaults.headers.Authorization = `Bearer ${token}`;

    setData({
      token,
      user,
    });
  }, []);

  const updateUser = useCallback((userNew, tokenNew) => {
    setData({
      token: tokenNew,
      user: userNew,
    });
    localStorage.setItem(
      configurationVariables.UserStorage,
      JSON.stringify(userNew)
    );
    localStorage.setItem(configurationVariables.UserToken, tokenNew);
  }, []);

  const updateIdTipoStatus = (idTipoStatusCliente) => {
    let newUser = data.user;
    newUser.idTipoStatusCliente = idTipoStatusCliente;

    localStorage.setItem(configurationVariables.UserStorage, JSON.stringify(newUser));

  }

  const signOut = useCallback(() => {
    localStorage.removeItem(configurationVariables.UserToken);
    localStorage.removeItem(configurationVariables.UserStorage);

    if (isNotPossuiCamposLimite) {
      window.location.replace(`/Login/${type}`);
    } else {
      window.location.replace(`/Login`);
    }
  }, [isNotPossuiCamposLimite, type]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        token: data.token,
        signIn,
        signOut: (isNotPossuiCamposLimiterator = false, type = "") => {
          setIsNotPossuiCamposLimite(
            (prevState) => isNotPossuiCamposLimiterator
          );
          setType((prevState) => type);
          signOut();
        },
        updateIdTipoStatus,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("Usuário não vinculado a pagina.");
  }

  return context;
}

export { AuthProvider, useAuth };
