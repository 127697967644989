import { TextField } from "@mui/material";

import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Skeleton from "@mui/material/Skeleton";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "4px !important",
    },
    "&:hover fieldset": {
      borderRadius: "4px !important",
    },
  },
});

export default function CampoData({
  index,
  label,
  name,
  value,
  onChange,
  isError,
  enable,
  fullWidth,
  size,
  isLoading,
  required,
}) {
  const handlerSetDate = (date) => {
    if (date === "0001-01-01") {
      return "";
    } else {
      const dataSplit = date.split("-");
      if (dataSplit[0].length < 5) return date;
      const ano = dataSplit[0].substring(0, 4);

      return `${ano}-${dataSplit[1]}-${dataSplit[2]}`;
    }
  };

  const handerCustomTagert = (event) => {
    let e = event;
    e.target.value = handlerSetDate(e.target.value);
    return e;
  };

  return (
    <>
      {isLoading && (
        <Skeleton
          variant="wave"
          sx={{ mt: 2 }}
          height={35}
          animation={false}
        />
      )}
      {!isLoading && (
        <FormControl
          index={index}
          fullWidth={fullWidth}
          variant="outlined"
        >
          <CssTextField
            InputLabelProps={{ shrink: true }}
          
            required={required}
            type="date"
            fullWidth={fullWidth}
            id={label}

            size={size ?? "medium"}
            label={label}
            name={name}
            value={handlerSetDate(value)}

            onChange={(e) => {
              onChange(handerCustomTagert(e));
            }}
            //helperText={isError ? `${label} inválido!` : ""}
            error={isError || (required && value === "0001-01-01")}
            disabled={!enable}
          />
        </FormControl>
      )}
    </>
  );
}
