import { useEffect, useState, useRef } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { LOJAFECHADA, MEUSPEDIDOSCOMERCIAL } from '../../util/typesReactQuery'
import { GetFuncionamnentoLoja } from '../../api/Carrinho/LojaFechada'
import { clearLocalStorageTimerCarrinho, getlocalStorageTimerCarrinho } from '../../util/LocalStorage'
import { intervalToDuration, compareAsc, compareDesc, format, hoursToMinutes, minutesToSeconds } from "date-fns";
import { useNavigate } from 'react-router-dom';
import { LimpaCarrinho } from '../../api/PedidoComercial'
import { useAuth } from '../AuthContext';

const useTimerCarrinho = (dataExpiracao, active = false, useLocalStorage = false, isOperacao = true) => {


  const [errors, setErrors] = useState(null)
  const [tempoPorcent, setTempoPorcent] = useState(100);
  const intervalId = useRef(0);
  const [infoText, setInfoText] = useState(null)
  const { user } = useAuth();
  const dataString = getlocalStorageTimerCarrinho();
  const dataLocal = !!dataString ? Date.parse(dataString) : null;
  const [minutosRestantes, setMinutosRestantes] = useState(0);
  const [segundosRestantes, setSegundosRestantes] = useState(0);
  const [tempoTotalTimerEmSegundos, setTempoTotalTimerEmSegundos] = useState(0);


  useEffect(() => {

    if (minutosRestantes == 0 && segundosRestantes == 0) {
      let minutes = 0;
      let seconds = 0;
      let horas = 0;
      if (useLocalStorage) {
        if (dataLocal) {

          horas = parseInt(
            intervalToDuration({
              start: new Date(),
              end: new Date(dataLocal),
            }).hours
          )
          minutes = parseInt(
            intervalToDuration({
              start: new Date(),
              end: new Date(dataLocal),
            }).minutes
          )
          seconds = parseInt(
            intervalToDuration({
              start: new Date(),
              end: new Date(dataLocal),
            }).seconds
          )
        }
        else {
          minutes = 0;
          seconds = 0;
        }
      } else {
        horas = parseInt(
          intervalToDuration({
            start: new Date(),
            end: new Date(dataExpiracao),
          }).hours
        )
        minutes = parseInt(
          intervalToDuration({
            start: new Date(),
            end: new Date(dataExpiracao),
          }).minutes
        )
        seconds = parseInt(
          intervalToDuration({
            start: new Date(),
            end: new Date(dataExpiracao),
          }).seconds
        )
      }


      minutes = minutes + hoursToMinutes(horas)
      let total = minutesToSeconds(minutes) + seconds;

      setMinutosRestantes(minutes);
      setSegundosRestantes(seconds);
      setTempoTotalTimerEmSegundos(total);
    }

  }, [])




  const mutationRemoveItem = useMutation(LimpaCarrinho, {
    //função chamada quando concluido a chamada da api
    onSuccess: (data) => {

      if (!!data.errorMessage)
        throw new Error(data.errorMessage, " - RE490")


    },
    //função chamada quando ocorre um erro
    onError: async (err, variables, context) => {
      setErrors(`Ahh, Algo deu errado, por favor informe ao suporte tecnico o seguinte erro - ${err} - R6OLZ `);
    },
  });





  useEffect(() => {


    if (!isOperacao && intervalId.current == 0 && tempoTotalTimerEmSegundos != 0 && dataLocal) {

      if (!useLocalStorage && !!!dataExpiracao) {
        setErrors("Ops algo deu errado ! Informe ao suporte tecnico: Uma data de expiração precisa ser informada  - RE2B1")
        return;
      }

      intervalId.current = setInterval(() => {
        let verificador = compareAsc(useLocalStorage ? new Date(dataLocal) : new Date(dataExpiracao), new Date());


        if (verificador != 1) {
          setInfoText("O tempo reservado para sua taxa foi excedido");
          setTimeout(() => {
            handlerCLearInterval(intervalId.current);
            mutationRemoveItem.mutate(user.id);
            clearLocalStorageTimerCarrinho();
          }, 1500)
        }
      }, 1000)
      return;
    }


    if ((active && intervalId.current == 0 && dataExpiracao || active && dataLocal && intervalId.current == 0) && tempoTotalTimerEmSegundos != 0 && isOperacao) {
      intervalId.current = setInterval(() => {
        let minRestantes = 0;
        let secondsRestantes = 0;
        let horasRestantes = 0



        if (!useLocalStorage && compareAsc(new Date(dataExpiracao), new Date()) == 1) {
          const duracao = intervalToDuration({
            start: new Date(),
            end: new Date(dataExpiracao),
          });

          horasRestantes = duracao.hours;
          secondsRestantes = duracao.seconds;
          minRestantes = duracao.minutes;
        } else if (useLocalStorage && compareAsc(new Date(dataLocal), new Date()) == 1) {
          const duracao = intervalToDuration({
            start: new Date(),
            end: new Date(dataLocal),
          });

          horasRestantes = duracao.hours;
          secondsRestantes = duracao.seconds;
          minRestantes = duracao.minutes;
        }
        else {
          minRestantes = 0;
        }


        minRestantes = hoursToMinutes(horasRestantes) + minRestantes;

        setMinutosRestantes(minRestantes);
        setSegundosRestantes(secondsRestantes);

        let segundosTotaisRestantes = minutesToSeconds(minRestantes) + secondsRestantes;


        if (segundosTotaisRestantes != 0) {

          let percentualRestante = 100 - ((tempoTotalTimerEmSegundos - segundosTotaisRestantes) * 100) / tempoTotalTimerEmSegundos;

          setTempoPorcent(percentualRestante);
        } else {
          setTempoPorcent(0);
          setInfoText("O tempo reservado para sua taxa foi excedido");
          setTimeout(() => {
            handlerCLearInterval(intervalId.current);
            mutationRemoveItem.mutate(user.id);
            clearLocalStorageTimerCarrinho();
          }, 1500)
        }
      }, 1000);
    }
  }, [tempoTotalTimerEmSegundos]);




  const handlerCLearInterval = (interval) => {
    if (interval > 0) {
      clearInterval(interval)
      intervalId.current = 0;
    }
  }

  // useEffect(() => {
  //   handlerCLearInterval(intervalId.current)
  // }, [])

  return { infoText, minutosRestantes, segundosRestantes, tempoPorcent, errors }
}

export default useTimerCarrinho