import { useState, useEffect, Fragment } from "react";
import Subtitulo from "../../Textos/Subtitulo";
import { Divider, Grid, Skeleton } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getQuestionarioFatca } from "../../../api/Cadastro";
import useStyle from "./styles";
import CustomGrid from "../../GridCadastroCustom";
import FormLabel from '@mui/material/FormLabel';

export default function QuestionarioFatca({
  idCliente,
  setDada,
  idPerfilLimiteOperacional,
  isLoading,
}) {
  const classes = useStyle();

  const [dadosForm, setDadosForm] = useState({});

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const response = await getQuestionarioFatca(
      idCliente,
      idPerfilLimiteOperacional
    );
    setDadosForm(response);
    setDada(response);
  };

  const handleChange = (e) => {
    const newValue = dadosForm.campos.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, ["valor"]: e.target.checked };
      else return c;
    });

    setDadosForm({ ...dadosForm, ["campos"]: newValue });
    setDada({ ...dadosForm, ["campos"]: newValue });
  };

  return (
    <>


      <div className={classes.box}>
        {isLoading ?
          <Skeleton animation="wave" width={"15%"} height={30} />
          :
          <>
            <Subtitulo sx={{ fontSize: 15 }} color="primary" label="Questionário Fatca" />
            <Subtitulo sx={{ fontSize: 16, fontWeight: "bold", mb: 2 }} color="primary" label="- Selecione a caixa de seleção se sua resposta for 'SIM'" />
          </>
        }
        <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 2 }} >
          {dadosForm &&
            dadosForm.campos &&
            dadosForm.campos.map((f, i, array) => (
              <Fragment key={i}>
                <CustomGrid index={i} array={array} md={6}>
                  {isLoading ?
                    <Skeleton animation="wave" width={"100%"} height={55} />
                    :
                    <FormGroup>
                      <FormLabel component="legend">{f.label}</FormLabel>
                      <FormControlLabel
                        key={i}

                        // label={f.label}

                        label="Sim"


                        control={
                          <Checkbox
                            value={f.valor}
                            checked={f.valor}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                            name={`${f.propriedade}`}
                          />
                        }
                      />
                    </FormGroup>
                  }
                </CustomGrid>
              </Fragment>
            ))}
        </Grid>
      </div>
    </>
  );
}
