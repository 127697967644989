import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import useStyle from "./styles";

function getLuminance(hexColor) {
  // Converte o valor hexadecimal em componentes RGB
  const red = parseInt(hexColor.substr(1, 2), 16);
  const green = parseInt(hexColor.substr(3, 2), 16);
  const blue = parseInt(hexColor.substr(5, 2), 16);

  // Calcula a luminosidade com base na fórmula adequada
  return (0.299 * red + 0.587 * green + 0.114 * blue) / 255;
}

export const ButtonCustom = ({
  children,
  variant,
  size,
  onClick,
  linkComponent,
  sx,
  disabled = false,
  loading = false,
  component = null,
  color,
  onChange = null, // Use 'color' directly instead of 'props.color',
  dataTestId,
}) => {
  const cssStyle = useStyle();
  const bgColor = color ? color : "primary"; // Cor de fundo padrão, caso não seja especificada
  const textContrastColor = getLuminance(bgColor) > 0.8 ? "#000" : "#fff"; // Cor de texto com base na luminosidade

  const getClass = () => {
    if (!variant) {
      return cssStyle.buttonPrimary;
    }
    switch (variant.toLowerCase()) {
      case "success":
        return cssStyle.buttonSuccess;
      case "error":
        return cssStyle.buttonError;
      case "text":
        return cssStyle.buttonText;
      default:
        return cssStyle.buttonPrimary;
    }
  };

  return loading ? (
    <Skeleton width={150} sx={{ ml: 5 }} height={80} />
  ) : (
    <Button
      data-testid={dataTestId}
      sx={{
        ...sx,
        color: textContrastColor,
        backgroundColor: bgColor, // Define a cor de fundo usando backgroundColor
      }}
      size={size || "small"}
      onClick={onClick}
      disabled={disabled}
      className={getClass()}
      component={component}
      onChange={onChange}
      linkcomponent={linkComponent ?? Button}
    >
      {children}
    </Button>
  );
};
