const TiposOperacao = {
   turismoCompraEspecie: 5,
   turismoCompraSaldoCartao: 6,
   turismoVendaEspecie: 7,
   turismoVendaCartao: 8,
   turismoRecargaCartao: 9,
   EnvioDinheiro: 18,
   RecebimentoDinheiro: 19,
   TurismoVendaOuro: 20,
   SeguroViagem: 21,
   ChipInternacional: 22,
}


export default TiposOperacao; 