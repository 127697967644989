import { useState } from "react";
import { useQuery } from "react-query";
import { GETPENDENCIASCLIENTE } from "../../util/typesReactQuery";
import { getPendencias } from "../../api/ContaCliente";
import { useAuth } from "../AuthContext";




const useCheckPendencia = () => {
      const [pendencias, setPendencias] = useState([]);
      const [hasPendencia, setHasPendencia] = useState(true);
      const [errorText, setErrorText] = useState("")
      const { user } = useAuth();

      const ordenaPendencias = (a, b) => {
            if (a.impedeOperar && !b.impedeOperar)
                  return -1
            if (!a.impedeOperar && b.impedeOperar)
                  return 1
            else
                  return 0

      }

      useQuery([GETPENDENCIASCLIENTE], () => getPendencias(user.id), {
            onSuccess: (data) => {
                  if (!data) {
                        setErrorText(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhum carrinho - R683C`)
                        return;
                  }
                  if (data.data.errorMessage) {
                        setErrorText(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RB04F`)
                        return;
                  }


                  setPendencias(data.data.data.sort(ordenaPendencias));


            },
            onError: (error) => {
                  setErrorText(`Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RE07D`)
            },
            refetchOnWindowFocus: true,
      })



      return {
            hasPendencia: pendencias.length > 0,
            pendencias,
            quantidadePendencias: pendencias.length,
            messageError: errorText
      }
}

export default useCheckPendencia;