import TiposPedido from "../../../util/typesTipoPedido";
import {  Typography } from "@mui/material";


export default function TituloPedidoConcluido({ pedido }) {
  const defineTitulo = () => {
    switch (pedido.tipoPedido.id) {
      case TiposPedido.Recebimento:
        return (
          <>
            Estamos processando sua <br />
            ordem de pagamento no valor de:
          </>
        );
      case TiposPedido.CompraEspecie:
        return (
          <>
            Estamos processando seu pedido <br />
            Valor final que irá receber é de:
          </>
        );

      default:
        return <Typography sx={{textAlign:'center'}}> Estamos aguardando o pagamento de:</Typography>;
    }
  };

  return defineTitulo();
}
