import { useState, useRef, useEffect, Fragment } from "react";
import { Hidden, InputAdornment, TextField, Typography } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import PersonIcon from "@mui/icons-material/Person";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useStyle from "./styles";
import useAlert from "../../hooks/useAlert";
import CampoTexto from "../Campos/CampoTexto";
import ButtonAcaoGrande from "../Botoes/ButtonAcaoGrande";
import { TooltipInformacao, PositionTooltip } from "../TooltipInformacao";

const initPassageiro = {
  idade: "",
};

function PassageiroList({ onChange }) {
  const paperRef = useRef(null);

  const cssStyle = useStyle();
  const [popUpPassageiros, setPopUpPassageiros] = useState(false);
  const { showAlertInfo } = useAlert();

  const [quantidadePassageiro, setQuantidadePassageiro] = useState(0);
  const [passageiros, setPassageiros] = useState([]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (paperRef.current && !paperRef.current.contains(event.target)) {
        setPopUpPassageiros(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const adicionarPassageiro = () => {
    if (passageiros.length >= 7) {
      showAlertInfo("Ops, não é permitido adicionar mais de 7 passageiros");
      return;
    }

    const p = passageiros;
    p.push(initPassageiro);
    setPassageiros((prevState) => [...p]);
    setQuantidadePassageiro(p.length);
    onChange(p);
  };

  const removePassageiro = () => {
    if (passageiros.length === 1) {
      showAlertInfo("Ops, você precisa indicar no minimo 1 passageiro");
      return;
    }

    const p = passageiros;
    p.pop();
    setPassageiros((prevState) => [...p]);
    setQuantidadePassageiro(p.length);
  };

  const handlerChangePassageiro = (i, event) => {

    if (!isNaN(event.target.value)) {

      const passageirosNew = passageiros.map((p, index) => {
        if (i === index) {
          return {
            idade: event.target.value,
          };
        }
        return p;
      });

      setPassageiros(passageirosNew);
      onChange(passageirosNew);
    }
  };





  return (
    <>
      <div className={cssStyle.containerSelecao}>
        <div className={cssStyle.boxSelecao}>
          <div className={cssStyle.boxInformativo}>
            <TextField
              spellCheck="false"
              value={quantidadePassageiro}
              fullWidth={true}
              id="outlined-start-adornment"
              sx={{ mt: 0.5, cursor: "pointer" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon></PersonIcon>
                  </InputAdornment>
                ),
              }}
              onClick={() => setPopUpPassageiros(true)}
            />
          </div>
        </div>

        {popUpPassageiros && (
          <div ref={paperRef}>
            <Paper className={cssStyle.Paper}>
              <Hidden smUp>
                <IconButton
                  style={{
                    position: "absolute",
                    top: 6,
                    left: 0,
                    marginBottom: 25,
                  }}
                  onClick={() => setPopUpPassageiros(false)}
                >
                  <ArrowBackIcon />
                  <Typography variant="h6" sx={{ pl: 2 }}></Typography>
                </IconButton>
              </Hidden>
              <Box className={cssStyle.Box} sx={{}}>
                <Typography className={cssStyle.Titulo}>
                  Quantidade de passageiros
                </Typography>
                <RemoveCircleRoundedIcon
                  onClick={removePassageiro}
                  sx={{ fontSize: 22 }}
                  className={cssStyle.button}
                />
                <Typography className={cssStyle.literalQuantidade}>
                  {quantidadePassageiro}
                </Typography>
                <AddCircleRoundedIcon
                  sx={{ fontSize: 22 }}
                  onClick={adicionarPassageiro}
                  className={cssStyle.button}
                />
              </Box>

             
              <Grid xs={12} className={cssStyle.Item} item>
                {quantidadePassageiro > 0 && (
                  <Typography sx={{ pl: 1, pb: 1, fontWeight: "bold" }}>
                    Idade dos passageiros*
                  </Typography>
                )}
                {passageiros &&
                  passageiros.map((p, i) => {
                    return (
                      <Fragment key={i}>
                        <TooltipInformacao
                          positionStart={PositionTooltip.left}
                          descricao="Para passageiros com menos de 1 ano, inserir a idade como 0."
                        >
                          <Grid xs={12} lg={12} className={cssStyle.Campo} item>
                            <CampoTexto
                              value={`${p.idade}`}
                              type="numeric"
                              label="Idade do passageiro"
                              size="medium"
                              name={`passageiro-${i}`}
                              enable={true}
                              handlerChange={(value) => {
                                handlerChangePassageiro(i, value);
                              }}
                              key={i}
                              className={cssStyle.campoIdade}
                              fullWidth={true}
                              maximoDigitos={2}
                              inputMode="numeric"
                              pattern="[0-9]*"
                            />
                          </Grid>
                        </TooltipInformacao>
                      </Fragment>
                    );
                  })}
                <Grid xs={12} lg={12} className={cssStyle.Campo} item>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                  ></Typography>
                </Grid>
              </Grid>
              <Hidden smUp>
                <Box sx={{ mt: 2, p: 2 }}>
                  <ButtonAcaoGrande
                    texto="Aplicar"
                    Handle={() => setPopUpPassageiros(false)}
                  ></ButtonAcaoGrande>
                </Box>
              </Hidden>
            </Paper>
          </div>
        )}
      </div>
    </>
  );
}

export default PassageiroList;
