

import { Grid, Skeleton } from '@mui/material';

/**
 * @param {number} quantidade  informa a quantidade de quadrados de loading que vai aparecer
 * @returns Quadrados para demonstrar que esta carregando alguma informação
 */
export function LoadingVitrineChip(quantidade = 6) {

      return (
            <>
                  <Grid container justifyContent={'center'} spacing={1} >
                        {Array.from({ length: 6 }, (_, index) => (
                              <Grid item sm={6} md={4} lg={4} key={index} sx={{ mt: -5 }} >
                                    <Skeleton animation="wave" height={250} />
                              </Grid>
                        ))}
                  </Grid >
            </>
      );
}