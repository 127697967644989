import { useState, useEffect } from "react";
import { getParticipacaoJuridica } from "../../../api/Cadastro";
import Controle from "../ControleCamposMultiplos";

export default function ParticipacaoJuridicaItem({
  idCliente,
  idPerfilLimiteOperacional,
  setDada,
}) {
  const [dadosForm, setDadosForm] = useState([]);
  const [defaultDadosForm, setDefaultDadosForm] = useState({})

  useEffect(() => {
    init(idCliente, idPerfilLimiteOperacional);
  }, [idCliente, idPerfilLimiteOperacional]);

  const init = async (clienteId, perfilId) => {
    const response = await getParticipacaoJuridica(clienteId, perfilId);
    setDefaultDadosForm(() => ({ ...response[0] }))
    setDadosForm(() => [...response]);
    setDada(() => [...response]);
  };

  return (
    <>
      <Controle
        identificaItem="Participação"
        dadosForm={dadosForm}
        setDadosForm={setDadosForm}
        setData={setDada}
        defaultDadosForm={defaultDadosForm}
        title="Participação Jurídica"
        setIdTipoEstadoCivil={null}
        tooltipAdd="Adicione uma nova participação"
        tooltipDelete="Remove a participação selecionada"
        initBuscaCampos={init}
      />

    </>
  );
}
