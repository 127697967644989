import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";

export const variables: IVariable = {
  IdHotsite: 0,
  Loja: "duodigital",
  UserStorage: "sKU9Z5I7w0erpr5pdBPmBg==",
  UserToken: "NfL3TUcYkqXOdNX1sePQg==",
  UrlApi: "https://prd-ecommerce-duo-api.primecase.com.br/api",
  // UrlApi: "https://hlg-ecommerce-par-api.primecase.com.br/api",
  UrlLoja:"https://prd-ecommerce-duo-web.primecase.com.br/",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#8C52FF",
      light: "#B799F2",
      //lighter: "#ece6f7",
      dark: "#362a4c",
    },
    secondary: {
      main: "#7E7296",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    //white: {
    //  main: "#fff",
    //},
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
