import { IVariable } from "../../interfaces/IVariable";
import { createTheme, Theme } from "@mui/material/styles";
import { darkenColor } from "../../util/helpers";


export const variables: IVariable = {
  IdHotsite: 11,
  Loja: "Ouro Minas",
  UserStorage: "8aP102IDeUCm6BHconiIAw==",
  UserToken: "8hRdNh7+BEGrFVhoQBPKsw==",
  UrlApi: "https://prd-ecommerce-duo-api.primecase.com.br/api",
  UrlLoja:"https://www.ourominasremessa.com.br/",
};

export const template: Theme = createTheme({
  palette: {
    primary: {
      main: "#004033",
      light: "#004033",
      //lighter: "#ece6f7",
      dark: darkenColor("#004033", 10), // Escurece em 20%
    },
    secondary: {
      main: "#c6a15e",
    },
    error: {
      main: "#a91c1c",
    },
    text: {
      primary: "#222",
      //light: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
    // white: {
    //   main: "#fff",
    // },
  },

  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontSize: 14,
    },
  },
});
