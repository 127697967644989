import { useQuery } from "react-query";
import { getMoedaByID } from "../../api/Moedas";
import { useEffect, useState } from "react";
import { getlocalStorageCarrinhoTemp } from "../../util/LocalStorage";
import { formatMoneyStringInDecimal, formatMoney } from "../../util/helpers";
import TiposPedido from "../../util/typesTipoPedido";
import { clearLocalStorageCarrinhoTemp } from "../../util/LocalStorage";
import { addItem as addItemTurismo, LimparCarrinho as LimparCarrinhoTurismo } from "../../api/Carrinho/Turismo";
import { useAuth } from "../AuthContext";
import { addItemEnvio } from "../../api/Carrinho/TranferenciaInternacional";
import { addItemOuro, LimparCarrinho as LimpaCarrinhoOuro } from "../../api/Carrinho/Ouro";
import { LimpaCarrinho as LimpaCarrinhoComercial } from "../../api/PedidoComercial";

import { clearLocalStorageTimerCarrinho, saveLocalStorageTimerCarrinho } from '../../util/LocalStorage'
import usePedido from "../usePedido";


const initialValue = {
    IP: "",
    dataViagem: "2023-08-18T13:59:11.267Z",
    emitirCartaoAdicional: "false",
    idItemVitrine: 0,
    idMoeda: 0,
    idPraca: 0,
    idTaxa: 0,
    idTaxaDolar: 0,
    idTipoCartao: 0,
    idTipoItemCarrinho: 0,
    idTipoOperacaoCarrinho: 0,
    idTipoPedido: 0,
    idVitrine: 0,
    isCompra: "false",
    numeroCartao: "",
    idTipoPedido: 0,
    idPraca: 0,
    valorAdicional: 0,
    valorDesconto: 0,
    valorMe: 0,
    valorTaxaAplicada: "undefined",
    valorTaxaAplicadaSemDesconto: "undefined",
    valorTotalMN: 0,
    isEspecie: false,
    moeda: null,
    natureza: null,
    valorVet: 0,
}



export default function useConfigCarrinhoExterno() {
    const [formData, setFormData] = useState(initialValue);
    const [errors, setErrors] = useState("");
    const [info, setInfo] = useState("")
    const { user } = useAuth();
    const { refetchPedido, itensPedido } = usePedido();


    useEffect(() => {
        if (itensPedido?.itens?.length > 0 && itensPedido?.dataExpiracao) {
            clearLocalStorageTimerCarrinho()
            saveLocalStorageTimerCarrinho(itensPedido.dataExpiracao);
        }
    }, [itensPedido])



    const { refetch } = useQuery(
        ["GETMOEDABYID"],
        () => getMoedaByID(parseInt(formData?.idMoeda)),
        {
            onSuccess: (data) => {
                if (!data) {
                    setErrors(
                        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: Não foi encontrado nenhuma vitrine - R8MRE`
                    );
                    return;
                }
                if (data.errorMessage) {
                    setErrors(
                        `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${data.errorMessage} - RXKCB`
                    );
                    return;
                }
                let moeda = { ...data.data, isEspecie: formData.isEspecie }

                setFormData((prev) => ({ ...prev, moeda: { ...moeda } }))

            },
            onError: (error) => {
                setErrors(
                    `Ops, algo deu errado, por favor informe o seguinte erro ao suporte tecnico: ${error} - RTEBE`
                );
            },
            refetchOnWindowFocus: false,
            enabled: false
        }
    );


    async function addItemCarrinho() {


        switch (formData.idTipoPedido) {
            case TiposPedido.Remessa:
            case TiposPedido.Recebimento:

                let responseLimpa = await LimpaCarrinhoComercial({ idCliente: user.id });

                if (!!responseLimpa?.errorMessage) {
                    setErrors(responseLimpa.errorMessage);
                    return;
                }

                const responseEnvio = await addItemEnvio(
                    user.id,
                    formData,
                    formData?.natureza
                );


                if (!!responseEnvio.errorMessage) {
                    setErrors(responseEnvio.errorMessage)
                }

                break;

            case TiposPedido.VendaOuro:

                let responseLimpaOuro = await LimpaCarrinhoOuro({ idCliente: user.id });

                if (!!responseLimpaOuro?.errorMessage) {
                    setErrors(responseLimpaOuro.errorMessage);
                    return;
                }

                const responseOuro = await addItemOuro(
                    user.id,
                    formData.idPraca,
                    formData.idTipoPedido,
                    formData
                );

                if (!!responseOuro.errorMessage) {
                    setErrors(responseOuro.errorMessage)
                }

                break;

            default:
                let responseLimpaTurismo = await LimparCarrinhoTurismo({ idCliente: user.id });

                if (!!responseLimpaTurismo?.errorMessage) {
                    setErrors(responseLimpaTurismo.errorMessage);
                    return;
                }


                const responseTurismo = await addItemTurismo(
                    user.id,
                    formData.idPraca,
                    formData.idTipoPedido,
                    formData
                );

                if (!!responseTurismo.errorMessage) {
                    setErrors(responseTurismo.errorMessage)
                }

                break;
        }

        clearLocalStorageCarrinhoTemp();

        setInfo("Oba, Seu pedido foi registrado no carrinho");
        if (!!refetchPedido) {
            refetchPedido();
        }


    }


    useEffect(() => {
        let form = JSON.parse(getlocalStorageCarrinhoTemp())

        if (!!!form)
            return;


        if (parseInt(form.idTipoPedido) === TiposPedido.Recebimento) {
            setInfo("Ops, Seu pedido não pôde ser registrado porque precisamos que você selecione uma ordem de pagamento.")
            clearLocalStorageCarrinhoTemp();
            return;
        }

        let regexInt = /^\d+$/,
            regexFloat = /^\d+(\.\d+)?$/;


        for (let key of Object.keys(form)) {
            if (regexInt.test(form[key])) {
                form[key] = parseInt(form[key])
            }

            if (key === "valorTotalMN") {
                form[key] = formatMoneyStringInDecimal(formatMoney(form[key], 2).replace(".", ","))
            }

            if (form[key] === "false")
                form[key] = false
            if (form[key] === "true")
                form[key] = true

        }

        form.natureza.id = parseInt(form.natureza.id ?? 0)

        setFormData((prev) => ({ ...form }))

    }, [])

    useEffect(() => {

        if (formData?.idMoeda > 0 && !!!formData.moeda)
            refetch();

        if (formData?.moeda?.id > 0)
            addItemCarrinho();

    }, [formData, formData.moeda])

    return {
        info,
        errors,
    };
}