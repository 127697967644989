import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

/*dados recebidos do passageiro
nome
sobrenome
numeroDocumento,
dataNascimento
idSexo
idade
*/

const initalState = {
  passageiros: [],
  pacotes: [],
  dataDe: "",
  dataAte: "",
  idDestino: 0,
  idCliente: 0,
  pacoteSelecionado: {},
  formaPagamentoSelecionada: {},
};

//Persiste os dados inseridos no seguro, no localstorage
export const useSeguro = create(
  persist(
    (set, get) => ({
      infoSeguro: initalState,
      updateSelectFormaPagamento: (formaPagamento) =>
        set((state) => {
          return ({ infoSeguro: ({ ...get().infoSeguro, formaPagamentoSelecionada: formaPagamento }) })
        }),

      addInfoSeguro: (item) =>
        set((state) =>
          ({ infoSeguro: ({ ...item }) })),

      updatePassageiro: (passageiro, index) =>
        set((state) => {

          let newArray = state.infoSeguro.passageiros.map((item, i) => {
            if (i === index) {
              return passageiro;
            }
            return item;
          })

          return ({ infoSeguro: ({ ...get().infoSeguro, passageiros: newArray }) })

        }),

      updatePacotes: (pacotesSeguros) =>
        set((state) =>
          ({ infoSeguro: ({ ...get().infoSeguro, pacotes: pacotesSeguros }) })),

      restorePacoteDefault: () =>
        set(state => ({ infoSeguro: initalState })),

      updateSelectPacote: (pacote) =>
        set((state) => ({
          infoSeguro: { ...get().infoSeguro, pacoteSelecionado: pacote },
        })),
    }),
    {
      name: "INFOSEGURO-STORAGE", //por padrão, ele vai salvar no localstorage com esse nome
    }
  )
);
