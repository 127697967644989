import { styled } from '@mui/material/styles';
import { StepConnector, stepConnectorClasses } from "@mui/material";


const ConectorSteps = styled(StepConnector)(({ theme, issuccess }) => ({
      [`&.${stepConnectorClasses.alternativeLabel}`]: {
            top: 22,
      },
      [`&.${stepConnectorClasses.active}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                  backgroundColor:
                        issuccess === 'true' ? theme.palette.success.main : theme.palette.error.main,
            },
      },
      [`&.${stepConnectorClasses.completed}`]: {
            [`& .${stepConnectorClasses.line}`]: {
                  backgroundColor:
                        issuccess === 'true' ? theme.palette.success.main : theme.palette.error.main,
            },
      },
      [`& .${stepConnectorClasses.line}`]: {
            height: 3,
            border: 0,
            backgroundColor:
                  theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
            borderRadius: 1,
      },
}));


const IconColorStep = styled('div')(({ theme, stadostep, issuccess }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
      ...(stadostep.active && {
            backgroundColor:
                  issuccess === 'true' ? theme.palette.success.main : theme.palette.error.main,
            boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      }),
      ...(stadostep.completed && {
            backgroundColor:
                  issuccess === 'true' ? theme.palette.success.main : theme.palette.error.main,
      }),
}));

export { ConectorSteps, IconColorStep }