import styles from "./styles";
import { Box, Typography, Grid } from "@mui/material";
import { formatMoneyInString } from "../../util/helpers";
import { Subtitulo, Titulo } from "../";
import Avatar from "@mui/material/Avatar";
import { stringAvatar } from "../../util/helpers";
import { Fragment } from "react";

export default function BeneficiarioResumoCarrinho({ beneficiario }) {
  const classes = styles();

  return (
    <>
      <>
        <Box className={classes.Valor}>
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Titulo sx={{ fontSize: "20px", mt: 0, mb: 1 }} align="left">
              Você está enviando para:
            </Titulo>

            <Typography sx={{ display: "flex", alignItems: "center", p: 1 }}>
              <Avatar {...stringAvatar(beneficiario.nome, "medium")} />
              {beneficiario.nome}
            </Typography>
            {/* <Grid xs={12}>
              <Box
                className={classes.Taxa}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography color="text.secondary">Nome</Typography>
                <Typography>{beneficiario.nome}</Typography>
              </Box>
            </Grid> */}

            {beneficiario.propriedades &&
              beneficiario.propriedades.map(
                (i, index) =>
                  i.value !== null && (
                    <Fragment key={index}>
                      <Grid item xs={12}>
                        <Box
                          className={classes.Taxa}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography color="text.secondary">
                            {i.label === null ? i.descricao : i.label}
                          </Typography>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {i.value}
                          </Typography>
                        </Box>
                      </Grid>
                    </Fragment>
                  )
              )}
          </Grid>
        </Box>
      </>
    </>
  );
}
